import React from 'react'
import { backend_url } from "../../module/DevInformation";
import * as SCM from "../../style/StyleComponentMenu";

function InstructorBanner() {
  return (
    <SCM.InstructorBannerContainer>
    <div>
        <img className='center logo' src={`${backend_url}/public/image_folder/homepage_images/1a46107a6bfd85b5bdc246e9a10d1890b986078ceeae2b188c38fa49776185b0264c325870df7ce38c0a9745233e00e40b5e95f7dc62edf49f9bcb91048a4132.png`} alt="logo" />
        <img className='center line' src={`${backend_url}/public/image_folder/homepage_images/brand/e1ca71f13feab2902d69ea1e2f88c67ff0353816d7357584617d220bbbdba604fa61e4a27726998e0148eb2b508cdaf2830470f278564851081baa9c19efc361.png`} alt="line" />
        <div className="text-box center">
            <h2>솔레노어에서는</h2>
            <h2><b>MMT(근력 평가)로 근육 상태를 진단</b>하고,</h2>
            <h2>RFM Specialist 인스트럭터가 제공하는<br/>
                <b>회원별 맞춤형 재활 및 운동 프로그램(RFM)</b><br/>
                을 체험할 수 있습니다.
            </h2>
        </div>
    </div>
    </SCM.InstructorBannerContainer>
  )
}

export default InstructorBanner