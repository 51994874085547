import React from "react";
import { useNavigate } from "react-router-dom";
import { backend_url } from "../../module/DevInformation";
import * as SCL from "../../style/StyleComponentLayout";
import { useState } from "react";
import { useEffect } from "react";

const Main: React.FC = () => {
  const navigate = useNavigate();
  const [showSection, setShowSection] = useState<boolean>(false);

  
  useEffect(() => {
    setTimeout(() => {
      // window.document.addEventListener('mousemove', handleShowSection);
      setShowSection(true);
    }, 4000);

    return () => window.document.removeEventListener('mousemove', handleShowSection);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowSection = () => {
    setShowSection(true);
    window.document.removeEventListener('mousemove', handleShowSection);
  };

  return (
    <SCL.MainPageContainer className="md:block">
      <div>
        <div className="line"></div>
        <div className="main-container">
          <div
          className="section brand-section"
          onClick={() => navigate("/brand")}
          >
          <div className="circle brand-circle">
            <h2>브랜드 안내</h2>
          </div>
          <h2 className="bottom-text">삶</h2>
          </div> {/* 브랜드 안내 */}
          <div className='center-section section'>
            <div className='circle center-circle'>
              <div className="text-box">
                <p>
                  인간의 수명은 길어졌고 21세기인 지금,<br/>
                  운동이 가진 의미는 복잡해졌습니다.
                </p>
                <p className="mobile-none">
                  고령화 인구 천만<br/>
                  그리고 초 개인화 시대,<br/>
                </p>
                <p className="mobile-none">
                  이제는<br/>
                  <span>새로운 피트니스 패러다임</span>이 필요합니다.
                </p>
                <p>
                단순히 운동을 위한 운동,<br/>
                <span>보여주기 위한 운동의 시대는 끝났습니다.</span>
                </p>
              </div>
              <div className="slogan">
                <img src={`${backend_url}/public/image_folder/homepage_images/1a46107a6bfd85b5bdc246e9a10d1890b986078ceeae2b188c38fa49776185b0264c325870df7ce38c0a9745233e00e40b5e95f7dc62edf49f9bcb91048a4132.png`} alt="logo" />
                <h2>건강과 삶의 완벽한 균형</h2>
              </div>
            </div>
          </div> {/* 가운데 섹션 */}
          <div
          className="section franchise-section"
          onClick={() => navigate("/franchise")}
          >
            <div className="circle franchise-circle">
              <h2>가맹 안내</h2>
            </div>
            <h2 className="bottom-text">건강</h2>
          </div> {/* 가맹 안내 */}
        </div> {/* end main-container */}
      </div>

    </SCL.MainPageContainer>
  )
};

export default Main;