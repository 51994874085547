import React from "react";
import * as SCF from "../../style/StyleComponentFranchise";
import Request from "./Request";

const Inquiry: React.FC = () => {

  return (
    <>
        <Request />
    </>
  )
};

export default Inquiry;