import React from "react";
import * as SCM from "../../style/StyleComponentMenu";
import Lineup from "../brand/Lineup";
import ChiefManager from "./ChiefManager";
import InstructorBanner from "./InstructorBanner";
import InstructorEffect from "./instructorEffect";


const Instructor: React.FC = () => {

  return (
    <>
      <SCM.MenuContainer>
        <Lineup />
        <InstructorBanner />
        <InstructorEffect />
        <ChiefManager />
      </SCM.MenuContainer>
    </>
  )
};

export default Instructor;