import React from "react";
import * as SCM from "../../style/StyleComponentMenu";
import { backend_url } from "../../module/DevInformation";

const Lineup: React.FC = () => {
  
  return (
    <>
      <SCM.instructorMain>
        <div>
          <div className="container">
            <h1>RFM Specialist Instructor</h1>
            <img src={`${backend_url}/public/image_folder/homepage_images/brand/d96ee4372d01f2c21bfe4c34446e3e84ac227fa7ba539e5ffb0b29163f97187f65366b24496d3fb1dd56ca4f5ba8476f60f34b69c5253fb27239c95fc1cb7e87.png`} alt="" />
            <div className="text-box">
              <div>
                <h4 className="title"><b>MMT</b> 란?</h4>
                <p className="subTitle">
                  Manual Muscle Test<br/>
                  도수 근력 검사
                </p>
                <p className="desc">
                  검사자가 맨손으로 저항을 주고 대상자는 저항을 이겨내면서 
                  <b> 근력 및 근기능 상태를 평가하는 Tool</b> 입니다.<br/>
                  검사 시 저항을 이겨내지 못하는 약화(Weak)가 발생하게 되면 보조 운동을 통해 
                  <b> 문제가 발생한 동작에서 원래 역할을 해야 할 근육이 사용될 수 있게 재교육</b> 시켜줍니다.
                </p>
              </div> {/* text-box--mmt */}
              <div>
                <h4 className="title"><b>RFM</b> 이란?</h4>
                <p className="subTitle">
                  Re-education for Functional Mobility<br/>
                  기능적인 움직임을 재교육
                </p>
                <p className="desc">
                  기능적 움직임을 재교육한다는 건, 우리가 원래 알고 있던 움직임이나 사용해 오던 움직임이 근골격계의 손상 혹은 신경계의 손상 등으로 제한되어 
                  <b> 제 기능을 못하고 있는 부분을 찾아내어 재교육 시키는 것 </b>입니다.
                </p>
              </div> {/* text-box--rfm */}
            </div>
          </div>
        </div>
      </SCM.instructorMain>
    </>
  )
};

export default Lineup;