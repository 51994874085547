import React from "react";
import * as SCF from "../../style/StyleComponentFranchise";
import { process_data } from "../../module/Variable";
import { ScrollAnimationContainer } from "../util/ScrollAnimationContainer";

const Process: React.FC = () => {

  return (
    <>
      <SCF.ProcessContainer>
        <h2 className="title">계약절차</h2>
        <div className="process-container">
          {process_data.map((tmp, idx) =>
            <div
              key={idx}
              className="process-container"
            >
              <ScrollAnimationContainer
                direction="L"
                duration="0.8s"
                delay={`${idx * 0.15}s`}
                threshold={0.5}
                onlyOnce
              >
                <div className="process-step">
                  <h1 className="text-index">
                    {tmp.index}
                  </h1>
                  <div className="text-kor">
                    <div>
                      <h2>{tmp.text_kor_1}</h2>
                      <h2>{tmp.text_kor_2}</h2>
                    </div>
                  </div>
                  <div className="text-eng font-CormorantGaramond">
                    <div>
                      <h3>{tmp.text_eng_1}</h3>
                      <h3>{tmp.text_eng_2}</h3>
                      <h3>{tmp.text_eng_3}</h3>
                    </div>
                  </div>
                </div>
              </ScrollAnimationContainer>
            </div>
          )}
        </div>
      </SCF.ProcessContainer>
    </>
  )
};

export default Process;