import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import {
  createGlobalStyle,
  ThemeProvider
} from 'styled-components';
import reset from 'styled-reset';
import theme from './style/theme';
const GlobalStyle = createGlobalStyle`
  ${reset}
`

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <App />
    </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
