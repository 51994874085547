import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import * as SCL from "../../style/StyleComponentLayout";
import { useState } from "react";
import { screen_width_data } from "../../module/DevInformation";

const Identity: React.FC = () => {
  const sunRef: React.RefObject<HTMLDivElement> = useRef(null);
  const moonRef: React.RefObject<HTMLDivElement> = useRef(null);
  const earthRef: React.RefObject<HTMLDivElement> = useRef(null);

  const [circleCX, setCircleCX] = useState<number>(0);
  const [circleCY, setCircleCY] = useState<number>(0);
  const [circleR, setCircleR] = useState<number>(0);

  useEffect(() => {
    window.addEventListener("resize", handleReSize);
    handleReSize();

    return () => {
      window.removeEventListener("resize", handleReSize);
    }
  }, []);

  const handleReSize = () => {
    if ( screen_width_data.sm >= window.innerWidth) {
      setCircleCX(75);
      setCircleCY(65);
      setCircleR(60);
    } else {
      setCircleCX(125);
      setCircleCY(115);
      setCircleR(110);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      sunRef.current?.classList.add("active-text");
      moonRef.current?.classList.add("active-text");
      earthRef.current?.classList.add("active-text");
    }, 1500);
  }, []);

  return (
    <>
      <SCL.SoleilnoirIntro
        // style={{
        //   marginTop : "130px",
        // }}
      >
        <div
          ref={sunRef}
          className="circle-box sun-box"
        >
          <svg className="sun">
            <circle cx={circleCX} cy={circleCY} r={circleR} />
          </svg>
          <h5 className="font-CormorantGaramond">The Sun</h5>
          <h6>&nbsp;삶</h6>
        </div>
        <div
          ref={moonRef}
          className="circle-box moon-box"
        >
          <svg className="moon">
            <circle cx={circleCX} cy={circleCY} r={circleR} />
          </svg>
          <h5 className="font-CormorantGaramond">The Moon</h5>
          <h6>건강</h6>
        </div>
        <div
          ref={earthRef}
          className="circle-box earth-box"
        >
          <svg className="earth">
            <circle cx={circleCX} cy={circleCY} r={circleR} />
          </svg>
          <h5 className="font-CormorantGaramond">The Earth</h5>
          <h6>&nbsp;나</h6>
        </div>
        <div className="infinite-horizontal-line" />
      </SCL.SoleilnoirIntro>
    </>
  )
};

export default Identity