import styled from "styled-components";
import theme from "./theme";
import { backend_url } from "../module/DevInformation";

export const MenuContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media ${theme.screen.md} {
    padding-top: 1px;
  }

  & .background-box {
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  & .menu-title-box {
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: 'Cormorant Garamond', serif;
    font-size: 28px;
    @media ${theme.screen.md} {
      margin-top: 100px;
    }
    @media ${theme.screen.sm} {
      margin-top: 50px;
    }
  }

  &.application-container {
    padding-top: 100px;

    @media ${theme.screen.sm} {
      padding-top: 0;
    }
  }
`;

export const ScrollAnimationFlexBox = styled.div`
  /* position: fixed; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100vh;

  &.membership-enterance {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 101%;
      background: linear-gradient(150deg, transparent, black 65%);
    }
  }

  & .image-box {
    width: 900px;
    height: auto;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: 
        linear-gradient(to top, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.6) 85%, rgba(0, 0, 0, 1) 100%),
        linear-gradient(to bottom, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.5) 85%, rgba(0, 0, 0, 1) 100%),
        radial-gradient(circle at center, transparent, black);
    }

    & img {
      width: 900px;
    }
  }

  & .text-box {
    text-align: center;
    cursor: default;

    h1 {
      font-size: 90px;
      font-weight: 300;

      @media ${theme.screen.md} {
        font-size: 32px;
      }
    }
  }

  & .text-membership-main {
    width: 100%;
    padding-right: 7%;
    text-align: right;
    font-family: "Cormorant Garamond", "Pretendard";
  }
`;

export const IPhoneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;

  @media ${theme.screen.md} {
    position: absolute;
    width: 100%;
  }

  @media ${theme.screen.sm} {
    position: absolute;
    align-items: start;
    margin-top: 140px;
    right: 0;
    z-index: 1;
    height: 490px;
    overflow-y: hidden;
  }

  & .iphone-x {
    position: relative;
    margin: 40px auto;
    width: ${theme.utility.iPhoneWidth};
    height: ${theme.utility.iPhoneHeight};
    background-color: ${theme.color.mainBlack};
    border-radius: 40px;
    box-shadow: 0px 0px 0px 10px #1f1f1f, 0px 0px 0px 12px #191919, 0px 0px 0px 14px #cecece;
    overflow: hidden;
    opacity: 0;

    @media ${theme.screen.md} {
      transform: translateX(200px);
      width: ${theme.utility.iPhoneSmallWidth};
      height: ${theme.utility.iPhoneSmallHeight};
      box-shadow: 0px 0px 0px 5px #1f1f1f, 0px 0px 0px 7px #191919, 0px 0px 0px 9px #cecece;
    }

    @media ${theme.screen.sm} {
      position: relative;
      transform: translateX(0);

      &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, transparent, #000);
      }
    }

    & .carousel-container {
      display: flex;
      width: 100%;
      height: 100%;
    }

    &.slide-animation {
      animation: slideUp 1.8s ease-in-out;
      opacity: 1;

      @media ${theme.screen.md} {
        animation: mdSlideUp 0.9s ease-in-out;
      }

      @media ${theme.screen.sm} {
        animation: smSlideUp 0.9s ease-in-out;
      }

      @keyframes slideUp {
        0% {
          opacity: 0;
          transform: translate(125%, 150px);
        }
        50%, 65% {
          opacity: 1;
          transform: translate(125%, 0);
        }
        100% {
          opacity: 1;
          transform: translate(0, 0);
        }
      }
      @keyframes mdSlideUp {
        0% {
          opacity: 0;
          transform: translate(200px, 150px);
        }
        100% {
          opacity: 1;
          transform: translate(200px, 0);
        }
      }
      @keyframes smSlideUp {
        0% {
          opacity: 0;
          transform: translateY(150px);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    &:before, &:after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 21;
    }

    // 하단 홈 바
    /* &:after {
      bottom: 7px;
      width: 140px;
      height: 4px;
      background-color: #f2f2f2;
      border-radius: 10px;
      z-index: 21;
    } */
    
    // frontal camera/speaker frame
    &:before {
      top: 0px;
      width: 45%;
      height: 25px;
      background-color: #000;
      border-radius: 0px 0px 40px 40px;
      box-shadow: 0px 0px 0px 1px #1f1f1f, 0px 0px 0px 2px #191919, 0px 0px 0px 3px #777777;
      z-index: 21;
    }
    
    i,
    b,
    s,
    span {
      position: absolute;
      display: block;
      color: transparent;
      cursor: pointer;
      z-index: 21;
    }
    
    // speaker
    i {
      top: 0px;
      left: 50%;
      transform: translate(-50%, 6px);
      height: 8px;
      width: 12%;
      background-color: #101010;
      border-radius: 8px;
      box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
      z-index: 21;
    }
    
    // camera
    b {
      left: -5px;
      top: 0px;
      transform: translate(180px, 4px);
      width: 12px;
      height: 12px;
      background-color: #101010;
      border-radius: 12px;
      box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);
      z-index: 21;

      @media ${theme.screen.sm} {
        left: -60px;
      }
      
      &:after {
        content: '';
        position: absolute;
        background-color: #2d4d76;
        width: 6px;
        height: 6px;
        top: 2px;
        left: 2px;
        top: 3px;
        left: 3px;
        display: block;
        border-radius: 4px;
        box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
        z-index: 21;
      }
    }
    
    s {
      top: 50px;
      color: #fff;
      text-align: center;
      text-decoration: none;
      width: 100%;
      font-size: 70px;
      font-weight: 100;
      padding-top: 60px;
      z-index: 21;
    }
    
    & .swipe-icon-box {
      position: absolute;
      bottom: 20%;
      left: 50%;
      transform: translateX(-52%);
      opacity: 0;
      animation: swipeLeft 2s;
      animation-iteration-count: 2;

      @keyframes swipeLeft {
        0% {
          opacity: 0;
          transform: translateX(-52%);
        }
        40% {
          opacity: 1;
          transform: translateX(-52%);
        }
        75% {
          opacity: 1;
          transform: translateX(-200%);
        }
        100% {
          opacity: 0;
          transform: translateX(-200%);
        }
      }
    }
  }
`;

export const ApplicationDescriptionContainer = styled.div`
  position: relative;
  margin-top: 80px;
  width: 50%;

  @media ${theme.screen.md} {
    margin-top: 0;
    width: 100%;
    padding-left: 20px;
  }

  & .button-box {
    transition: all 1s;

    & button {
      margin-right: 20px;
      padding: 10px;
      border-radius: 4px;
      font-size: 15px;

      @media ${theme.screen.sm} {
        padding: 8px;
        margin-right: 10px;
        font-size: 13px;
        margin-top: 50px;
      }

      &:hover {
        opacity: 1;
      }
    }

    & .active {
      background-color: ${theme.color.pointGold};
      opacity: 1;
    }

    & .inactive {
      background-color: ${theme.color.subGray01};
      opacity: 0.8;
    }
  }

  & .title-box {
    margin-top: 40px;

    @media ${theme.screen.sm} {
      margin-top: 20px;
      z-index: 0;
    }
  }

  & .content-box {
    margin-top: 20px;

    @media ${theme.screen.sm} {
      margin-top: 300px;
      display: none;
    }
  }

  & h1 {
    line-height: 48px;
    font-size: 32px;

    @media ${theme.screen.sm} {
      line-height: 28px;
      font-size: 22px;
    }
  }

  & h5 {
    color: ${theme.color.subGray02};
    line-height: 28px;
    font-weight: 300;

    @media ${theme.screen.sm} {
      line-height: 22px;
      font-size: 14px;
    }
  }

  & .download-box {
    position: absolute;
    display: flex;
    margin-top: 60px;

    @media ${theme.screen.sm} {
      display: none;
    }

    /* @media ${theme.screen.md} {
      position: static;
      margin: 60px 0 30px;
    } */

    & img {
      margin-right: 20px;
      width: 36px;
      height: 36px;
    }
  }
`;

export const ProfileStudioTitleContainer = styled.div`
  position: relative;
  width: 100%;
  text-align: center;

  & .profile-title {
    width: 100%;
    font-size: 72px;
    margin-top: 80px;
  }

  & .profile-tagline {
    width: 100%;
  }

  @media ${theme.screen.sm} {
    & .profile-title {
      font-size: 40px;
      margin-top: 0;
    }

    & .profile-tagline {
      margin-top: 5px;
      font-size: 14px;
    } 
  }
`;

export const ProfileStudioContainer = styled.div`
  position: relative;
  display: grid;
  margin: 60px 0;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  width: 800px;
  height: 600px;
  background-color: #000;
  box-sizing: border-box;
  transition: 0.25s;
  animation: fadeInUp 0.8s;
  
  @keyframes fadeInUp {
    from {
      transform: translateY(100px);
    }
    to {
      transform: translateY((0px));
    }
  }

  @media ${theme.screen.sm} {
    height: 400px;
    grid-gap: 10px;
  }

  &:hover .item-box {
    filter: grayscale(1);
  }

  & .item-box {
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: 0.25s;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: grayscale(0);

    &:hover {
      filter: grayscale(0);
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(#000, transparent, transparent, transparent, #000);
    }

    &:nth-child(even) {
      transform: translateY(30px);
    }
    &:nth-child(odd) {
      transform: translateY(-30px);
    }

    @media ${theme.screen.sm} {
      &:nth-child(even) {
        transform: translateY(20px);
      }
      &:nth-child(odd) {
        transform: translateY(-20px);
      } 
    }

  }
  &:has(.one:hover) {
    grid-template-columns: 2fr 0.5fr 0.5fr 0.5fr;
  }
  &:has(.two:hover) {
    grid-template-columns: 0.5fr 2fr 0.5fr 0.5fr;
  }
  &:has(.three:hover) {
    grid-template-columns: 0.5fr 0.5fr 2fr 0.5fr;
  }
  &:has(.four:hover) {
    grid-template-columns: 0.5fr 0.5fr 0.5fr 2fr;
  }

  @media ${theme.screen.sm} {
    &:has(.one:hover) {
    grid-template-columns: 1.5fr 0.5fr 0.5fr 0.5fr;
  }
  &:has(.two:hover) {
    grid-template-columns: 0.5fr 1.5fr 0.5fr 0.5fr;
  }
  &:has(.three:hover) {
    grid-template-columns: 0.5fr 0.5fr 1.5fr 0.5fr;
  }
  &:has(.four:hover) {
    grid-template-columns: 0.5fr 0.5fr 0.5fr 1.5fr;
  }
  }
`;

export const SubBrandContainer = styled.div<{$currentIndex: number}>`
  position: relative;
  display: grid;
  margin: 60px 0;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  width: 800px;
  height: 600px;
  background-color: #000;
  box-sizing: border-box;
  transition: 0.25s;
  animation: fadeInUp 0.8s;
  
  @keyframes fadeInUp {
    from {
      transform: translateY(100px);
    }
    to {
      transform: translateY((0px));
    }
  }

  &:hover .item-box {
    filter: grayscale(1);
  }

  & .item-box {
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: 0.25s;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: grayscale(0);
    cursor: pointer;

    &:nth-child(3) {
      background-position: 40% 50%;
    }
    &:nth-child(4) {
      background-position: 10% 50%;
    }

    &:hover {
      filter: grayscale(0);
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(#000, transparent, transparent, transparent, #000);
    }

    &:nth-child(even) {
      transform: translateY(30px);
    }
    &:nth-child(odd) {
      transform: translateY(-30px);
    }

    &:after {
      content: attr(data-menu);
      position: absolute;
      bottom: -100px;
      font-size: 20px;
      font-weight: 600;
      transition: 0.5s;
      text-transform: uppercase;
      letter-spacing: 0.25em;
      opacity: 0;
      transition-delay: 0;
      font-family: "Cormorant Garamond";
      -webkit-box-reflect: below 1px linear-gradient(transparent, #0003, #0003);
    }
    &:hover::after {
      opacity: 1;
      bottom: 0px;
      transition-delay: 0.25s;
    }
  }
  &:has(.one:hover) {
    grid-template-columns: 2fr 0.5fr 0.5fr 0.5fr;
  }
  &:has(.two:hover) {
    grid-template-columns: 0.5fr 2fr 0.5fr 0.5fr;
  }
  &:has(.three:hover) {
    grid-template-columns: 0.5fr 0.5fr 2fr 0.5fr;
  }
  &:has(.four:hover) {
    grid-template-columns: 0.5fr 0.5fr 0.5fr 2fr;
  }
`;

export const SelectedSubBrandContainer = styled.div`
  position: relative;
  min-height: 600px;
`;

export const ProfileStudioGridContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;

  & .profile-card {
    width: 144px;
    height: 180px;
    cursor: pointer;
    opacity: 0;

    &:hover {
      z-index: 1;
    }
  }
  
  & .active {
    opacity: 1;
    animation: fadeInUp 0.8s;

    @keyframes fadeInUp {
      from {
        transform: translateY(100px);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  & .profile-modal {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: darker 0.9s;

    @keyframes darker {
      from {
        background-color: transparent;
      }
      to {
        background-color: black;
      }
    }

    & .profile-image {
      width: auto;
      height: 100%;
      z-index: 101;
      animation: scaleUp 0.7s;
    }

    @keyframes scaleUp {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1);
      }
    }
  }
`;

export const BenefitContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media ${theme.screen.md} {
    background-size: 150%;
  }

  & .menu-box {
    position: relative;
    width: 800px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${theme.screen.sm} {
      width: 500px;
    }

    & .benefit-title-box {
      position: relative;
      padding: 20px 10px;
      cursor: pointer;
      transition: 1.25s;
      text-align: center;

      & h2 {
        font-size: 32px;
        line-height: 48px;

        @media ${theme.screen.sm} {
          font-size: 16px;
          line-height: 20px;
        }
      }

      & button {
        padding: 8px 15px;
        margin-top: 10px;
        font-size: 18px;
        color: ${theme.color.subGray01};
        border: 1px solid ${theme.color.subGray01};
        border-radius: 4px;
        transition: 0.3s;
        z-index: 30;

        @media ${theme.screen.sm} {
          font-size: 14px;
          color: ${theme.color.mainWhite};
          border: 1px solid ${theme.color.mainWhite};
        }
      }

      &:hover button {
        color: #fff;
        border-color: #fff;
      }
    }
  }
`;

export const BenefitItems = styled.li<{$index: number, $countItems: number}>`
  position: absolute;
  left: 0;
  list-style: none;
  transition: 0.8s;
  transform: rotate(calc(360deg/${props => props.$countItems} * ${props => props.$index} + 60deg));
  transform-origin: 400px;
  scale: 0;
  opacity: 0;
  z-index: 0;

  @media ${theme.screen.sm} {
    transform-origin: 250px;
  }

  &.active {
    scale: 1;
    opacity: 1;
    transition-delay: calc(0.1s * ${props => props.$index});
  }

  & .menu-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    color: ${theme.color.subGray01};
    transform: rotate(calc(360deg/-${props => props.$countItems} * ${props => props.$index} - 60deg));
    
    & .image-box {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 10px;

      & img {
        width: 48px;
        height: 48px;

      }
      @media ${theme.screen.sm} {
        padding-bottom: 0;

        & img {
          width: 36px;
          height: 36px;
        }
      }
    }
    
    & .text-box {
      text-align: center;
      
      & h3 {
        font-size: 24px;
        line-height: 28px;
        color: ${theme.color.subGray02};
        padding-bottom: 10px;
      }

      & p {
        font-size: 15px;
        line-height: 22px;
      }

      @media ${theme.screen.sm} {
        
        & h3 {
          font-size: 16px;
          line-height: 20px;
          padding-bottom: 5px;
        }

        & p {
          font-size: 13px;
          line-height: 16px;
        }
      }
    }

  }
`;

export const ParallaxContainer = styled.div`
  position: relative;
  width: 100%;
  height: 150vh;
  scroll-behavior: smooth;
  overflow: auto;
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & .main-slogan {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    transform: translateY(100vh);
  }

  & section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-position: center;
    scroll-snap-stop: always;
    background-size: cover;
    scroll-snap-align: start;
  }

  & .active-parallax {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: -1000;
    /* height: 100%;
    transform: scale(1, 1); */
    @media ${theme.screen.md} {
      margin-top: 60px;
    }

    @media ${theme.screen.sm} {
      margin-top: 40px;
    }

    & video {
      position: absolute;
      right: 0;
      top: 0;
      min-width: 100%;
      width: 100%;
      height: 100vh;
      object-fit: cover;
      z-index: -1000;
    }
    & video.absolute {
      top: -80px;
    }

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 104%;
      background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 1));
    }
  }

  & .mouse-icon {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: end;
    width: 100%;
    height: 100vh;
    padding-bottom: 2%;
    text-align: center;
  }
  & .mouse-icon.absolute, .main-slogan.absolute {
    top: 0;
  }

  & .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  & .main-text {
    text-align: center;
    font-size: 54px;
    line-height: 72px;
    font-weight: 300;

    @media ${theme.screen.sm} {
      font-size: 24px;
      line-height: 32px;
    }
  }

  & .last-slogan {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &.eng {
      padding-bottom: 40px;
      font-size: 72px;
      line-height: 80px;

      @media ${theme.screen.sm} {
        padding-bottom: 20px;
        font-size: 28px;
        line-height: 32px;
      }
    }

    &.kor {
      font-size: 24px;

      @media ${theme.screen.sm} {
        font-size: 16px;
      }
    }
  }
`;

export const BrandIdentitySoleilnoir = styled.div`
  display: flex;
  justify-content: center;
  width: 100%; height: 100vh;
  padding-left: 10px;
  background-color: black;

  @media ${theme.screen.sm} {
    display: block;
    padding-left: 0;
  }

  & .description-section {
    display: flex;
    justify-content: center;
    width: 400px;

    @media ${theme.screen.sm} {
      display: block;
      width: 100%;
    }

    & .text-box {
      font-size: 18px;
      line-height: 30px;
      font-weight: 300;

      @media ${theme.screen.sm} {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
      }

      & h4 {
        color: ${theme.color.pointGold};
        font-size: 24px;
        font-weight: 400;
      }
    }
  }
`;

export const GalleryContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0rem;
  overflow-x: hidden;
  /* transform: translateY(5%); */

  @media ${theme.screen.sm} {
    height: 60vh;
  }
  
  & .image-track {
    position: absolute;
    display: flex;
    width: max-content;
    gap: 4vmin;
    left: 50%;
    top: 0%;
    transform: translate(0, 0%);
    opacity: 0.9;
    user-select: none;

    & .image {
      width: 32vmin;
      height: 48vmin;
      object-fit: cover;
      object-position: 100% 50%;
    }
  }
  & .image-track-2 {
    position: absolute;
    display: flex;
    width: max-content;
    gap: 4vmin;
    right: 50%;
    bottom: 0%;
    transform: translate(0, 0%);
    opacity: 0.9;
    user-select: none;

    & .image {
      width: 32vmin;
      height: 48vmin;
      object-fit: cover;
      object-position: 0% 50%;
    }
  }
`;

export const instructorMain = styled.div`
  width: 100%;
  padding-top: 50px;
  & .container {
    margin: 5vh auto;
    width: 90%;
    max-width: 1200px;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @media ${theme.screen.sm} {
      margin-bottom: 15vh;
    } 
    & h1 {
      font-size: 3em;
      text-align: center;
      color: ${theme.color.mainWhite};
      text-shadow: 0 0 5px ${theme.color.pointGold},
	  							 0 0 10px ${theme.color.pointGold},
	  							 0 0 20px ${theme.color.pointGold},
	  		           0 0 30px ${theme.color.pointGold},
	  							 0 0 40px ${theme.color.pointGold};
    }
    & img {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    }
    & .text-box {
      display: flex;
      justify-content: space-between;
      @media ${theme.screen.sm} {
      flex-direction: column;
      }
      & div {
        width: 45%;
        @media ${theme.screen.sm} {
          width: 100%;
          padding-block: 10px;
          border-bottom: 1px solid ${theme.color.mainWhite};
        }
        & .title {
          font-size: 15px;
          & b {
            font-size: 40px;
            font-weight: bold;
          }
        }
        & .subTitle {
          color:${theme.color.pointGold};
          font-size: 20px;
          line-height: 25px;
          margin-bottom: 10px;
        }
        & .desc {
          font-weight: lighter;
          line-height: 23px;
          font-size: 15px;
          & b {
            font-weight: initial;
            color: ${theme.color.pointGold};
          }
        }
      }
    }
  }
`;

export const MobileAppContainer = styled.div`
  margin-top: 10vh;
  width: 100%;
  height: 80vh;
  position: relative;
  @media ${theme.screen.sm} {
    height:auto;
    margin-block: 10vh;
  }
  & .container {
    margin: 0 auto;
    width: 80%;
    max-width: 1200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media ${theme.screen.md} {
     width: 100%;
    }
    & img {
      width: 90%;
      max-width: 1300px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: -1;
      opacity: 30%;
      @media ${theme.screen.md} {
        width: 130%;
      }
    }
    & .title {
      font-size: 3em;
      text-align: center;
      & b {
        color: ${theme.color.mainWhite};
        text-shadow: 0 0 5px ${theme.color.pointGold},
                   0 0 10px ${theme.color.pointGold},
                   0 0 20px ${theme.color.pointGold},
                   0 0 30px ${theme.color.pointGold},
                   0 0 40px ${theme.color.pointGold};
      }
    }
    & .sub-title {
      text-align: center;
      margin: 10px 0 50px 0;
    }
    & .text-container {
      display:flex;
      flex-direction: column;
      gap: 20px;
      & .text-box {
        background-color: ${theme.color.opacityGold};
        padding: 12.5px;
        width: 80%;
        border-radius: 10px;
        text-align: center;
        margin: 0 auto;
        @media ${theme.screen.md} {
          width: 100%;
          background: none;
          border-radius: initial;
          border-top: 1px solid ${theme.color.mainWhite};
          border-bottom: 1px solid ${theme.color.mainWhite};
        }
        & .text-box-title {
          font-size: 30px;
          margin-bottom: 10px;
          font-weight: bold;
          @media ${theme.screen.sm} {
            font-size: 25px;
          }
        }
        & .text-box-desc {
          font-size: 16px;
          color: ${theme.color.subGray02};
          line-height: 23px;
          @media ${theme.screen.md} {
            font-size: 15px;
          }
          & span {
            @media ${theme.screen.md} {
            display: block;
            }
          }
        }
      }
    }
  }
`;

export const PtpbContainer = styled.div`
  width: 100%; position: relative;
  overflow: hidden; padding-block: 10vh;
  & .bg {
    position:absolute; top:0; left:0; 
    width: 100%; height: 100vh; 
    filter:brightness(20%);
    @media ${theme.screen.sm} {height:130vh;}
  }
  & .container {
    width: 100%;
    max-width: 1200px;
    margin: 5vh auto 0;
    @media ${theme.screen.sm} {margin: 5vh;}
    & .brown {color:#d8a777; border-color:#d8a777;}
    &>div {
      display:flex; justify-content:space-around;
      & .tit-box {
        text-align: center; margin-bottom: 15%;
        & h2 {font-size:40px; margin-bottom:1vh;}
        & p {font-size:25px;}
        @media ${theme.screen.sm} {
        text-align: initial; 
        margin-bottom: 10%;
        }
      }
    }
    & .content-box {
        display: flex; justify-content: space-between;
        @media ${theme.screen.sm} {display:block}
        &> div {
          width:50%; padding-inline:7.5%;
          @media ${theme.screen.sm} {
            width: 100%; padding: initial;
          }
        }
        & .content ul {
          display:flex; flex-direction: column; 
          & li {
            display: flex; gap:3vw; align-items: center; margin-top: -5%;
            @media ${theme.screen.im} {
              margin: initial; margin-bottom: 3%;
            }
            @media ${theme.screen.sm} {
              margin-bottom: 5%;
            }
            & .tit {
              width: 150px; height: 150px; border:1px solid; border-radius: 50%; position: relative;
              @media ${theme.screen.im} {
              width: 11vw; height: 11vw; border: none;
            }
              @media ${theme.screen.sm} {
              width: 30vw;
            }
              & div {
                width: 100%; 
                position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);
                & img {width: 35%; margin:0 auto 1.5vh;
                  @media ${theme.screen.sm} {width: 40%;  margin:0 auto;}
                }
                & img.phone {width: 20%;
                  @media ${theme.screen.sm} {width: 25%;}}
                & p {text-align:center; font-size:13px;
                  @media ${theme.screen.sm} {display:none;}
                }
              }
            }
            & .desc {
              display:flex; flex-direction: column; gap: 1vh;
              height: 100%; font-size: 0.8em;
              border-left: 1px solid; padding-left: 1vw;
              & .desc-sub {font-size:0.5em;}
            }
          }
        }
        & .content-left {
          border-right: 1px solid rgba(216,167,119,0.3);
          @media ${theme.screen.sm} {margin-bottom:5vh;}
        }
      }
  }
`;

export const Ptprice = styled.div`
  width: 100%; height: 100vh;
  padding-top: 15vh;
  @media ${theme.screen.sm} {height: 250vh;}
  & .head-title {
    text-align: center;
    font-weight: lighter;
  }
  & .flex-box {
      width: 1200px; height:60vh; display:flex; justify-content:center; gap:5vw; margin:0 auto;
      @media ${theme.screen.lg} {width: 100%; padding-inline:2.5%;}
      @media ${theme.screen.sm} {flex-direction:column; height:225vh; gap:initial;}
    & .Price {
      padding-top:5vh;
      text-align: center; position: relative; width: 100%; perspective: 600px;
      & .Price-title {
        border:1px solid rgba(255, 255, 255, 0.5); width: 100%; height: 460px; border-radius: 15px;
        position: relative; padding: 50px 1vw 20px; font-size: 14px; line-height: 18px; font-weight: lighter; 
        transition: 0.5s; transform-style: preserve-3d;
        position: relative; z-index: 2;
        & .Price-title_icon {
          width: 80px; 
          margin:5vh auto; 
        }
        & .Price-title_subTitle {
          font-size:35px; margin-bottom: 2vh; font-weight:initial;
        }
        & .Price-title_subTitle-desc {font-size: 18px; margin-top: 5vh; line-height: 30px;}
      }
      & .Price-container {
        position: absolute; left: 0; top: 5vh; z-index: 1;
        border:1px solid rgba(255, 255, 255, 0.5); border-radius:15px; 
        width: 100%; height:460px;
        transform: rotateY(-180deg);
        transition: 0.5s;
        transform-style: preserve-3d;
        & ul {
          height: 100%;
          padding-inline:1vw;
          display: flex; flex-direction: column; justify-content: center;
          & li {
            display:flex; justify-content: space-around;
            border-bottom: 1px solid;
            padding-block:1vh;
            & .Price-container_count {font-size:20px; line-height:40px;}
            & .Price-container_price-box {
              & .Price-container_price-box_origin-price {font-size:12px; text-align:right; position:relative; margin-bottom:1vh;}
              & .Price-container_price-box_origin-price::before {
                content:""; position:absolute; top:45%; right:0; transform:translateY(-50%); 
                width: 85%; height: 1px; background-color: #fff;
              }
              & p {font-size:18px;
                & span {font-size:15px; font-weight:lighter;} 
              }
            }
          }
          & .Price-container_li-title {
            padding-block:2vh;
            & p {font-size:15px;}
          }
          & li:last-child {border:none;}
        }
      }
      /* & .count-badge {
        width: 70px; height: 70px; 
        position:absolute; bottom:10px; right:-35px; 
        border-radius:50%; 
        font-size:22px; line-height:65px; 
        transform:rotateZ(18deg);
        @media ${theme.screen.sm} {display:none;}
        } */
    }

    & .Price:nth-child(1) {
      & .Price-title {
        background-image:linear-gradient(140deg, ${theme.color.GradientMiddleColor}, ${theme.color.EarthGradientColor});
      }
      & .Price-container {background-image:linear-gradient(140deg, ${theme.color.GradientMiddleColor}, ${theme.color.EarthGradientColor}, ${theme.color.GradientMiddleColor});}
      & .count-badge {background-color: ${theme.color.EarthColor};}
      @media ${theme.screen.sm} {margin-bottom:5vh;}
    } 
    & .Price:nth-child(2) {
      & .Price-title {
        background-image:linear-gradient(140deg, ${theme.color.GradientMiddleColor}, ${theme.color.MoonGradientColor});
      }
      & .Price-container {background-image:linear-gradient(140deg, ${theme.color.GradientMiddleColor}, ${theme.color.MoonGradientColor}, ${theme.color.GradientMiddleColor});}
      & .count-badge {background-color: ${theme.color.MoonColor};}
      @media ${theme.screen.sm} {margin-bottom:5vh;}
    } 
    & .Price:nth-child(3) {
      & .Price-title {
        background-image:linear-gradient(140deg, ${theme.color.GradientMiddleColor}, ${theme.color.darkGray01});
      }
      & .Price-container {background-image:linear-gradient(140deg, ${theme.color.GradientMiddleColor}, ${theme.color.darkGray01}, ${theme.color.GradientMiddleColor});}
      & .count-badge {background-color: ${theme.color.SunColor};}
    }
    & .Price.active .Price-title {transform: rotateY(180deg);}
    & .Price.active .Price-container {transform: rotateY(0deg); z-index: 2;}
  }
  & .bottom-desc {
    font-size:15px; text-align:center; 
    opacity:50%; 
    margin-top:5vh; 
    padding-inline:5vw;
    line-height:25px;
    & span {font-size:14px;}
    }
    
`;

export const JourneyContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: url(${backend_url}/public/image_folder/homepage_images/brand/4799a573fa258426f870afdd14fa3f961dd11a0d4380c9159ae0caf3fdf6f19460c775f6017e1dc6156b3aca03cf89a714a7fa7a1dff5196349a579bf9e5a836.jpg) center/cover;
  @media ${theme.screen.md} {
    height: auto;
  }
  @media ${theme.screen.sm} {
    margin-bottom: 120px;
  }
  & .container {
    width: 80%;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${theme.screen.md} {
      width: 90%;
      flex-direction: column;
      justify-content: flex-start;
    }
    & .ticket {
      width: 350px;
      height: 460px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      @media ${theme.screen.md} {
        width: 310px;
      }
      & .title-box {
        width: 100%;
        height: 8%;
        padding: 10px 5px;
        background-image: linear-gradient(to right, rgb(206, 165, 126), rgb(216, 167, 119), rgb(206, 149, 128));
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        & .ticket-logo {
          width: 25%;
        }
        & p {
          color: ${theme.color.OpacityWhite07};
        }
      }
      & .subTitle-box {
        height: 35%;
        padding-top: 12px;
        background-color: ${theme.color.OpacityWhite07};
        color: ${theme.color.mainBlack};
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        & .subTitle-arrow {
          margin: 7px auto;
        }
        & .subTitle-text--2{
          margin: 0 auto;
          width: 80%;
          font-weight: bold;
          font-size: 1.5em;
          padding-bottom: 5px;
          border-bottom: 1px solid ${theme.color.mainBlack};
        }
      }
      & .desc-box {
        height: 50%;
        background-color: ${theme.color.OpacityWhite07};
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        color: ${theme.color. mainBlack};
        text-align: center;
        }
        & h2 {
          padding: 15px 0 30px 0;
        }
        & ul {
          & li {
            position: relative;
            margin: 0 auto;
            width: 90%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 1;
            margin-bottom: 5px;
            & .name {
              width: 20%;;
              font-size: 1.3em;
              font-weight: 500;
              text-align: center;
            }
            & .price {
              font-weight: 800;
              font-size: 1.7em;
              & .krw {
                font-size: 13px;
                font-weight: lighter;
                margin-left: 5px;
              }
            }
          }
          & .earth:after {
            content:"";
            width: 100%;
            height: 15px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: ${theme.color.EarthColor};
            opacity: 45%;
            z-index: -1;
          }
          & .moon:after {
            content:"";
            width: 100%;
            height: 15px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: ${theme.color.subBrown};
            opacity: 45%;
            z-index: -1;
          }
          & .sun:after {
            content:"";
            width: 100%;
            height: 15px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: ${theme.color.subGray01};
            opacity: 45%;
            z-index: -1;
          }
        }
      }
  }
  & .desc-container {
    & .desc-text1 {
      width: 80%;
      text-align: center;
      font-size: 1.4em;
      line-height: 38px;
      @media ${theme.screen.md} {
      font-size: 1.3em;
      }
    }
    & .line {
      margin-top: 10px;
      width: 100%;
      height: 1px;
      background-color: ${theme.color.mainWhite};
      position: relative;
      &:after {
        content: "";
        position: absolute;
        bottom: 10px;
        right: -25px;
        width: 50px;
        height: 50px;
        border-bottom: 1px solid ${theme.color.mainWhite};
        transform: rotateZ(45deg);
      }
    }
    & .desc-text2 {
      text-align: right;
      font-weight: lighter;
      margin-top: 10px;
      line-height: 23px;
      @media ${theme.screen.md} {
        font-weight: initial;
      }
    } 
  }
`;

export const PointContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  & .container {
    width: 80%;
    max-width: 1200px;
    height: 100vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media ${theme.screen.sm} {
      width: 100%;
    }
    & .iphone {
      height: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: -1;
    }
    & .logo {
      width: 80%;
      max-width: 200px;
      margin: 0 auto 20px;
    }
    & .title {
      font-weight: lighter;
      font-size: 1.3em;
    }
    & .line {
      margin-top: 20px;
      width: 1px;
      height: 80px;
      background-color: ${theme.color.OpacityWhite07};
      position: relative;
      &::after{
        content: "";
        position: absolute;
        top:0;
        left: 50%;
        transform: translateX(-50%);
        width: 20px;
        height: 20px;
        background-color: ${theme.color.mainWhite};
        border-radius: 50%;
      }
    }
    & .text-box {
      background-color: ${theme.color.OpacityWhite07};
      padding: 45px 50px 25px ;
      color: ${theme.color.mainBlack};
      border-radius: 20px;
      text-align: center;
      @media ${theme.screen.sm} {
        padding: 40px 0 25px;
        width: 90%;
      }
      & .sub-title {
        font-size: 1.3em;
        & b {
          display: block;
          font-weight: bold;
          font-size: 1.5em;
          padding-block: 10px;
        }
      }
      & ul {
        display: flex;
        justify-content: center;
        gap: 30px;
        margin-block: 15px;
        @media ${theme.screen.sm} {
          flex-wrap: wrap;
          gap: initial;
          padding-block: 20px;
        }
        & li {
          position: relative;
          padding: 10px 0 40px;
          &:not(:last-child)::after{
            content: "";
            position: absolute;
            top: 16px;
            right: -15px;
            transform: translateY(-50%);
            width: 1px;
            height: 15px;
            background-color: ${theme.color.mainBlack};
            @media ${theme.screen.sm} {
              display: none;
            }
          }
          @media ${theme.screen.sm} {
            width: 40%;
            padding: 5px 0;
          }
        }
      }
      & .desc {
        display: inline;
        padding: 10px 30px;
        border: 1px solid ${theme.color.mainBlack};
        border-radius: 50px;
      }
    }
  }
`;

export const MembershipIntroContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media ${theme.screen.sm} {
    background-size: 150%;
  }

  & video {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -100;

    @media ${theme.screen.sm} {
      object-fit: contain;
      object-position: 50% 40px;
    }
  }

  & .membership-video {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 100%;
    width: 100%;
    height: 100%;

    &::before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      max-width: 100%;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: -99;
    }
  }

  & .into-text-wrapper {
    opacity: 0;

    @media ${theme.screen.sm} {
      opacity: 1;
      padding-top: 33%;
    }

    & .intro-text {
      font-size: 44px;
  
      @media ${theme.screen.sm} {
        font-size: 20px;
        line-height: 32px;
      }
    }
  
    & .membership-title {
      color: ${theme.color.pointGold};
      font-size: 160px;
      line-height: 240px;
  
      @media ${theme.screen.sm} {
        font-size: 48px;
        line-height: 60px;
      }
    }
  
    & .membership-category-box {
      
      & span {
        position: relative;
        padding: 0 20px;
        color: ${theme.color.subGray01};
        font-size: 30px;
        line-height: 30px;
  
        @media ${theme.screen.sm} {
          padding: 0 7px;
          font-size: 14px;
          line-height: 30px;
        }
  
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-55%);
          width: 2px;
          height: 80%;
          border-left: 2px solid ${theme.color.subGray01};
  
          @media ${theme.screen.sm} {
            border-left-width: 1px;
          }
        }
  
        &:nth-child(1)::before {
          border-left: 0;
        }
      }
    }
  }
`;

export const SubSloganContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: end;
  width: 100%;
  height: 50vh;
  
  & .main-text {
    width: 100%;
    text-align: center;
    font-size: 54px;
    line-height: 72px;
    font-weight: 300;

    @media ${theme.screen.sm} {
      padding: 20px 0;
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export const KitchenPageContainer = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 10vh;
  & .container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto 10vh;
    display: flex;
    justify-content: center;
    @media ${theme.screen.md} {
      width: 100%;
      align-items: center;
    }
    & img {
      width: 40%;
      @media ${theme.screen.md} {
      position: absolute;
      top: 5vh;
      width: 90vw;
      max-width: 380px;
      z-index: -1;
      filter: brightness(40%);
      }
    }
    & .text-box {
      width: 50%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      @media ${theme.screen.md} {
      width: 90%;
      gap: 20px;
      }
      & .title {
        font-weight: bold;
        font-size: 3em;
        color: ${theme.color.salad_green};
      }
      & .sub-title {
        font-size: 1.3em;
        & span {
          display: block;
          margin-top: 10px;
          font-size: 16px;
        }
      }
      & ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
        & li {
          background-color: ${theme.color.salad_green};
          border: 1px solid ${theme.color.salad_green};
          color: ${theme.color.mainWhite};
          padding-block: 25px;
          margin: 0 auto;
          width: 80%;
          border-radius: 50px;
          @media ${theme.screen.md} {
            opacity: 70%;
            padding-block: 15px;
          }
        }
        & li.border-green {
          background-color: transparent;
          border: 1px solid ${theme.color.salad_green};
          color: ${theme.color.mainWhite};
        }
      }
    }
  }
`;

export const BrandITContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;

  & .it-slogan {
    width: 50%;

    @media ${theme.screen.lg} {
      display: none;
    }

    & .text-flex-wrapper {
      width: 100%;
      background-color: red;
    }
  }

  & .it-image {
    width: 50%;

    @media ${theme.screen.lg} {
      width: 100%;
    }

    & .image-flex-wrapper {
      width: 100%;
      background-color: blue;
    }
  }

`;

export const ClubComingSoon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  font-size: 32px;
`;

export const FranchisebuttonContainer = styled.div `
  background: url(${backend_url}/public/image_folder/homepage_images/brand/755986f37193a6d8c1ef1f254ba68213f3477a48dc1acd78b551f82a54cf0e617b4aee2e85c575d6dc0159396b9ee95bfb9bfc81ff52258db13734959d92bb79.jpg) center/cover;
  width:100%; height:100vh;
  position:relative;
  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: ${theme.color.mainBlack};
    opacity: 60%;
  }
  & .text-container {
    padding-block:1vh;
    position:absolute; top:50%; left:50%; transform:translate(-50%,-50%); z-index: 10;
    width:80%; max-width:1200px;
    text-align:center;
    cursor: pointer;
    transition: 0.3s;
    padding: 1vw;
    & .title-box {
      border-bottom:1px solid ${theme.color.mainWhite};
      padding-bottom:30px;
      margin-bottom:80px;
      & h2 {
        font-size:2.5em; font-weight:bold; margin-bottom:25px; line-height:1.3em;
        @media ${theme.screen.lg} {font-size:1em;}
      }
      & .sub-title {
        display:inline;
        font-size:15px; font-weight:lighter;
        line-height:23px;
        opacity:70%;
        @media ${theme.screen.lg} {font-size:12px;}
      }
    }
    & .desc{
      font-size:2.5em;
      font-weight:lighter;
      opacity:70%;
      line-height:1.5em;
      margin-bottom:30px;
      @media ${theme.screen.lg} {font-size:1.3em;}
      & span {color:${theme.color.pointGold}; font-weight:bold;}
    }
  }
  & .text-container:hover {background-color:${theme.color.OpacityBlack};}
`;

export const InstructorBannerContainer = styled.div `
  width: 100%;
  height: 50vh;
  position: relative;
  @media ${theme.screen.sm} {
    padding-block: 15vh;
    height: auto;
    background-color: ${theme.color.mainBlack};
  }
    & .center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    & .logo {
      width: 50vw;
      max-width: 400px;
      opacity: 15%;
    }
    & .line {
      z-index: -1;
    }
    & .text-box {
      width: 100%;
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      & h2 {
        text-align: center;
        font-size: 20px;
        line-height: 1.35em;
        font-weight: lighter;
        & b {
          font-weight: initial;
          color: ${theme.color.pointGold};
        }
      }
    }
`;

export const InstructorEffect = styled.div `
  width: 100%;
  height: 100vh;
  position: relative;
  & .text-container {
    width: 90%;
    max-width: 1200px;
    height: 100vh;
    margin: 0 auto;
    position: relative;
    @media ${theme.screen.md} {
      width: 100%;
    }
    & img {
      height: 100vh;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -10;
      @media ${theme.screen.md} {
        width: 100%;
        object-fit: cover;
        filter: brightness(50%);
      }
    }
    & .text-box {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 3vh;
      @media ${theme.screen.md} {
        width: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      & .title {
        font-size: 1.3em;
        line-height: 1.5em;
        @media ${theme.screen.md} {
          font-size: 1.1em;
        }
        & b {
          color: ${theme.color.pointGold};
          font-weight: bold;
        } 
      }
      & .line {
        width: 1px;
        height: 10vh;
        background: ${theme.color.pointGold};
      }
      & .text-box--effect {
        padding-block: 2vh;
        border-top:1px solid;
        border-bottom:1px solid;
        border-image: linear-gradient(
                      90deg,
                      transparent 0%,
                      ${theme.color.PotintGoldGradientEndColor} 20%,
                      ${theme.color.pointGold} 50%,
                      ${theme.color.PotintGoldGradientEndColor} 80%,
                      transparent 100%
        )1;
        & ul {
          display: flex;
          flex-direction: column;
          gap: 10px;
          li {
            font-weight: lighter;
            font-size: 15px;
            font-weight: lighter;
            display: flex;
            align-items: center;
            justify-content: center;
            & svg {
              margin-right:5px;
            }
            & b {
              font-weight: initial;
              margin-inline: 5px;
            }
          }
        }
      }
      & .desc {
        color: ${theme.color.pointGold};
        line-height: 23px;
        & b {
          font-weight: bold;
          font-size: 1.5em;
        }
      }
    }
  }
`;

export const PlaceContainer = styled.div `
  width: 100%;
  height: auto;
  padding-block: 100px;
  background-color: ${theme.color.mainBlack};
  @media ${theme.screen.md} {
    padding-block: 50px;
  }
  & .container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    @media ${theme.screen.md} {
      width: 90%;
    }
    & .title {
      font-size: 2em;
      text-align: center;
      line-height: 1.3em;
      @media ${theme.screen.md} {
        font-size: 1.2em;
        & span {
          display:block;
        }
      }
      & b {
        color: ${theme.color.mainWhite};
        text-shadow: 0 0 5px ${theme.color.pointGold},
	  							 0 0 10px ${theme.color.pointGold},
	  							 0 0 20px ${theme.color.pointGold},
	  		           0 0 30px ${theme.color.pointGold},
	  							 0 0 40px ${theme.color.pointGold};
      }
    }
    & .img-container {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      & .img-box {
        width: 30%;
        margin-top: 20px;
        & img {
          width: 100%;
          border-radius: 10px;
          @media ${theme.screen.md} {
          border-radius: initial;
          width: 40vw;
          height: 40vw;
          object-fit: cover;
          }
        }
        & p {
          margin-top: 20px;
          text-align: center;
          font-weight: lighter;
          color: ${theme.color.subGray02};
          @media ${theme.screen.md} {
            display:none;
          }
        }
      }
    }
  }
`;

export const AppBannerContainer = styled.div `
  width: 100%;
  height: 30vh;
  position: relative;
  @media ${theme.screen.sm} {
    padding-block: 15vh;
    height: auto;
    background-color: ${theme.color.mainBlack};
  }
    & .center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    & .text-box {
      width: 100%;
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      & h2 {
        text-align: center;
        font-size: 1.3em;
        line-height: 1.7em;
        font-weight: lighter;
        & b {
          font-size: 1.5em;
          font-weight: bold;
          color: ${theme.color.pointGold};
          @media ${theme.screen.sm} {
            font-size: 1em;
          }
        }
      }
    }
`;

export const AppFunctionContainer = styled.div `
  width: 100%;
  height: 200vh;
  @media ${theme.screen.sm} {
    margin-top: 50px;
  }
  & .container--1 {
    width: 70%;
    max-width: 1200px;
    height: 40%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    @media ${theme.screen.md} {
      height: 20%;
    }
    @media ${theme.screen.sm} {
      width: 90%;
      margin-bottom: 10vh;
    }
    & .mobile {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 33%;
      z-index: -1;
      @media ${theme.screen.sm} {
        width: 53%;
        filter: brightness(30%);
      }
    }
    & .title-box {
      & h2 {
        font-size: 3em;
        margin-bottom: 10px;
        @media ${theme.screen.md} {
          font-size: 1.5em;
          line-height: 35px;
        }
      }
      & .download-box {
        display: flex;
        gap: 20px;
        & img {
          width: 36px;
          height: 36px;
        }
      }
    }
    & .desc-box {
      & p {
        font-size: 15px;
        line-height: 23px;
        font-weight: lighter;
        color: ${theme.color.subGray02};
        text-align: right;
        @media ${theme.screen.sm} {
          color: ${theme.color.mainWhite};
        }
      }
    }
  }
  & .line {
    margin: 2% auto;
    width: 1px;
    height: 8%;
    background-color: ${theme.color.pointGold};
    position: relative;
    @media ${theme.screen.md} {
      height: 5%;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 12px;
      height: 12px;
      background-color: ${theme.color.pointGold};
      border-radius: 50%;
    }
  }
  & .container--2 {
    width: 90%;
    max-width: 1200px;
    height: 45%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    @media ${theme.screen.md} {
      justify-content: space-between;
      flex-direction: column;
      height: 70%;
      text-align: initial;
    }
    & .fade-in-slide-l {
      @media ${theme.screen.md} {
      width: 100%;
      height: 25%;
      }
      & .img-box {
        @media ${theme.screen.md} {
          display: flex;
          justify-content: initial;
          gap: 20px;
          height: 350px;
          align-items: center;
        }
        & .img {
          height: 90%;
          @media ${theme.screen.md} {
            height: 63%;
          }
        }
        & .text-container {
            & .title-box {
            margin: 30px 0 20px 0;
            @media ${theme.screen.sm} {
              margin: 10px 0;
            }
            & .title {
              font-size: 1em;
              line-height: 25px;
            }
          }
          & .desc {
            font-size: 15px;
            font-weight: lighter;
            color: ${theme.color.subGray02};
            line-height: 23px;
          }
          & .br {
            margin-bottom: 20px;
            @media ${theme.screen.sm} {
              margin: 0;
            }
          }
        }
      }
    }
  }
`;

export const MembershipContainer = styled.div `
  padding-top: 100px;
  width: 100%;
  height: 300vh;
  @media ${theme.screen.md} {
    height: auto;
  }
  & .membership-container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    @media ${theme.screen.md} {
      width: 100%;
      height: auto;
    }
    & div {
      text-align: center;
      & .title {
        font-size: 3em;
        color: ${theme.color.mainWhite};
        text-shadow: 0 0 5px ${theme.color.pointGold},
                   0 0 10px ${theme.color.pointGold},
                   0 0 20px ${theme.color.pointGold},
                   0 0 30px ${theme.color.pointGold},
                   0 0 40px ${theme.color.pointGold};
        line-height: 60px;
        margin-bottom: 20px;
      }
      & .sub-title {
        font-size: 1.8em;
        margin-bottom: 50px;
      }
    }
    & .member-container {
      & .member-box {
        padding-block: 50px;
        width: 90%;
        border-radius: 50px;
        box-shadow: 0px 1px 20px ${theme.color.OpacityWhite};
        margin: 0 auto 50px;
        @media ${theme.screen.md} {
          padding-block: 30px;
        }
        & .member-title {
          font-size: 1.5em;
          line-height: 40px;
        }
        & .member-title-eng {
          font-size: 1.2em;
          font-weight: lighter;
        }
        & ul {
          display: flex;
          justify-content: center;
          gap: 20px;
          @media ${theme.screen.md} {
            gap: 10px;
          }
          & li {
            width: 20%;
            margin-top: 30px;
            @media ${theme.screen.md} {
              width: 33%;
            }
            & .img-box {
              width: 5vw;
              height: 5vw;
              min-width: 68px;
              min-height: 68px;
              padding: 20px;
              position: relative;
              margin: 0 auto;
              & img {
                height: 45px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
              }
            }
            & p {
              margin-top: 5px;
              font-size: 15px;
              line-height: 23px;
              font-weight: lighter;
              @media ${theme.screen.md} {
                line-height: 23px;
              }
              & span {
                @media ${theme.screen.md} {
                  display: block;
                }
              }
            }
          }
          & li.gold {
            color: ${theme.color.pointGold};
            border-color: ${theme.color.pointGold};
            @media ${theme.screen.sm} {
              color: ${theme.color.mainWhite};
            }
          }
        }
      }
      & .member-box--2 {
        & ul {
          @media ${theme.screen.md} {
            flex-wrap: wrap;
          }
          & li {
            @media ${theme.screen.md} {
            width: 45%;
            & p span.gray-text {
              color: ${theme.color.subGray02};
              font-size: 13px;
            }
            }
          }
        }
      }
    }

  }
  & .background-box {
    width: 100%;
    height: 170vh;
    background: url(${backend_url}/public/image_folder/homepage_images/brand/c35d4e28db39e83b3942a90164d3b0d96e0c5c04dbfe0c4328097eab8267905c64f93b546003adaca4d43d80b709d4d5c609a51013b5222543fafe6ab20dddae.jpg) center/cover;
    position: relative;
    padding-bottom: 10vh;
    @media ${theme.screen.md} {
      margin-top: 10vh;
      height: 100vh;
    }
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(
                  ${theme.color.realBlack} 10%,
                  ${theme.color.OpacityBlack} 50%,
                  ${theme.color.RankGradientBrown2} 80%,
                  ${theme.color.realBlack} 100%
      );
      @media ${theme.screen.md} {
        background: linear-gradient(
                  ${theme.color.realBlack} 10%,
                  ${theme.color.RankGradientBrown2} 50%,
                  ${theme.color.realBlack} 100%
        );
      }
    }
    & .rank-container {
      width: 80%;
      max-width: 1200px;
      height: 100%;
      position: relative;
      bottom: 0;
      margin: 0 auto;
      z-index: 1;
      overflow: hidden;
      @media ${theme.screen.md} {
        width: 100%;
      }
      & .rank {
        position: absolute;
        bottom: 0;
        text-align: center;
        & img {
          filter: brightness(90%);
          @media ${theme.screen.md} {
            width: 50%;
            margin: 0 auto;
          }
        }
        & .rank-title {
          font-weight: bold;
          font-size: 1.8em;
          margin-block: 20px;
          color: ${theme.color.mainWhite};
          text-shadow: 0 0 5px ${theme.color.pointGold},
                   0 0 10px ${theme.color.pointGold},
                   0 0 20px ${theme.color.pointGold},
                   0 0 30px ${theme.color.pointGold},
                   0 0 40px ${theme.color.pointGold};
          @media ${theme.screen.md} {
            margin-block: 20px;
            font-size: 1.3em;
          }
        }
        & .rank-desc {
          font-size: 15px;
          font-weight: lighter;
          line-height: 20px;
          @media ${theme.screen.md} {
            display: none;
          }
        }
        & .box {                       
          margin: 50px auto 0;
          width: 80%;
          height: 100%;
          opacity: 20%;
          border-radius: 10px;
          background: linear-gradient(
            ${theme.color.pointGold} 0%,
            transparent 45%
          );
          @media ${theme.screen.md} {
            margin: 10px auto;
          }
        }
        & .box.sun-box {
          background: linear-gradient(
            ${theme.color.pointGold} 0%,
            transparent 70%
          );
        }
      }
      & .moon {
        width: 30%;
        height: 70%;
        left: 0;
        @media ${theme.screen.md} {
          width: 45%;
          height: 75%;
        }
      }
      & .sun {
        width: 35%;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
        @media ${theme.screen.md} {
          width: 50%;
          z-index: -1;
        }
      }
      & .earth {
        width: 20%;
        height: 50%;
        right: 0;
        @media ${theme.screen.md} {
          width: 40%;
          height: 60%;
        }
      }
      & .text {
        width: 100%;
        position: absolute;
        bottom: 7%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 2.5em;
        line-height: 1.3em;
        text-align: center;
        @media ${theme.screen.md} {
          font-size: 1.6em;
        }
        & span {
          display: block;
          color: ${theme.color.pointGold};
          color: ${theme.color.mainWhite};
          text-shadow: 0 0 5px ${theme.color.pointGold},
                   0 0 10px ${theme.color.pointGold},
                   0 0 20px ${theme.color.pointGold},
                   0 0 30px ${theme.color.pointGold},
                   0 0 40px ${theme.color.pointGold};
        }
      }
    }
  }
`;

export const SubBrandTitle = styled.div`
  width: 100%;
  padding-top: 100px;
  margin-top: 100px;
  & .title {
    font-size: 1.5em;
    text-align: center;
    @media ${theme.screen.md} {
      font-size: 1.2em;
    }
    & span {
      display: block;
      margin-top: 10px;
      font-size: 1.8em;
      color: ${theme.color.mainWhite};
      text-shadow: 0 0 5px ${theme.color.pointGold},
                   0 0 10px ${theme.color.pointGold},
                   0 0 20px ${theme.color.pointGold},
                   0 0 30px ${theme.color.pointGold},
                   0 0 40px ${theme.color.pointGold};
    }
  }
  & .icon-container {
    margin: 0 auto;
    width: 80%;
    display: flex;
    justify-content: center;
    gap: 50px;
    padding-bottom: 5vh;
    border-bottom: 1px solid ${theme.color.OpacityWhite07};
    & .icon-box {
      margin-top: 20px;
      & .icon {
      width: 5vw;
      height: 5vw;
      min-width: 68px;
      min-height: 68px;
      border-radius: 50%;
      position: relative;
      margin: 0 auto;
      & img {
        padding: 5px;
        height: 45px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      }
      & p {
        color: ${theme.color.mainWhite};
        font-size: 15px;
        margin-top: 10px;
        line-height: 23px;
        font-weight: lighter;
        text-align: center;
      }
    }

  }
`;

export const ApparelContainer = styled.div`
  width: 100%;
  height: auto;
  margin-block: 10vh;
  & .container {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & .text-box {
      text-align: center;
      & .title {
        font-size: 3em;
        font-weight: bold;
        color: ${theme.color.pointGold};
      }
      & .sub-title {
        font-size: 1.3em;
        padding: 10px 0 30px 0;
      }
      & .desc {
        line-height: 23px;
        font-weight: lighter;
        @media ${theme.screen.md} {
        font-size: 15px;
        }
      }
    }
    & .product-box {
      width: 100%;
      margin-top: 50px;
      display: flex;
      justify-content: center;
      gap: 100px;
      @media ${theme.screen.md} {
        filter: brightness(80%);
        gap: 10px;
        margin-top: 20px;
      }
      & img {
        width: 35%;
        @media ${theme.screen.md} {
        width: 40%;
        }
      }
    }
  }
`;

export const FranchiseNaviContainer = styled.div`
  margin-top: 5vh;
  width: 100%;
  height: 100vh;
  background: url(${backend_url}/public/image_folder/homepage_images/brand/755986f37193a6d8c1ef1f254ba68213f3477a48dc1acd78b551f82a54cf0e617b4aee2e85c575d6dc0159396b9ee95bfb9bfc81ff52258db13734959d92bb79.jpg) center/cover;
  position: relative;
  @media ${theme.screen.sm} {
    height: 50vh;
  }
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${theme.color.OpacityBlack};
  }
  & .text-box {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: 50px;

    @media ${theme.screen.sm} {
      gap: 10px;
    }
    & .text {
      font-size: 1.8em;
      line-height: 40px;
      font-weight: lighter;
      & span {
        font-weight: bold;
      }
      @media ${theme.screen.sm} {
        font-size: 1.4em;
        line-height: 30px;
      }
    }
    & .click-button {
      padding: 10px;
      font-weight: lighter;
      border-radius: 50px;
      border: 1px solid ${theme.color.mainWhite};
      @media ${theme.screen.sm} {
        font-size: 15px;
      }
      & svg {
        display: inline;
      }
    }
  }
`;