import React from "react";
import * as SCM from "../../style/StyleComponentMenu";
import { backend_url } from "../../module/DevInformation";

const Kitchen: React.FC = () => {

  return (
    <div>
      <SCM.KitchenPageContainer>
        <div className="container">
          <img src={`${backend_url}/public/image_folder/homepage_images/brand/9b28c816cf8c9a14457389b70f98c1fa068939e15c89d0fded12bac8149a8b83bead3ce2838eee84f8efc71653c0232f536f7a95d2836984779b902c85c5a330.png`} alt='salad' />
          <div className="text-box">
            <p className="title">Soleilnoir Kitchen</p>
            <p className="sub-title">
              솔레노어 키친<br/>
              <span>솔레노어 샐러드 정기 배송 서비스</span>
            </p>
            <ul>
              <li>전문 영양사가 구성 및 제조</li>
              <li className="border-green">매일 다른 샐러드 식단 제공</li>
              <li>24가지 모든 드레싱 수제 제조</li>
              <li className="border-green">당일 제조, 당일 배송 신선함 유지</li>
              <li>부산 전지역 집으로 새벽 배송</li>
            </ul>
          </div>
        </div>
      </SCM.KitchenPageContainer>
    </div>
  )
};

export default Kitchen;