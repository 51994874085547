import React from 'react'
import * as SCF from "../../style/StyleComponentFranchise";
import { backend_url } from "../../module/DevInformation";

function FranchiseInstructor() {
  return (
    <div>
        <SCF.FranchiseInstructorContainer>
            <div className="container">
                <div className="img-box">
                    <img className="background" src={`${backend_url}/public/image_folder/homepage_images/instructor_images/87f834655e3682933c843efc6b9a3bbbd1aedaefb838ce57df22c530c997155bbf5d8cde814213f764dec62e8ee308cd99c5b778ea79e5e11b8537963a3f365c.jpg`} alt="place" />
                    <img className="background" src={`${backend_url}/public/image_folder/homepage_images/instructor_images/50fb6d1ec1a622b73fafc097b8297f02bb06b24db8814906ac763366c73772278be63bf87e36554b66ca2978f69f200ff2040042ab266fa8d962a16bcbf71c89.jpg`} alt="place" />
                    <img className="background" src={`${backend_url}/public/image_folder/homepage_images/instructor_images/b886ba397ab9e1a155d1b512bf6dc58aa5732f150a0bb46f50840cb769ff3092e49b1dcd03f68414afa15c9198154d093ff4e1a4c8234359df118e8ff2a4e643.jpg`} alt="place" />
                    <img className="background" src={`${backend_url}/public/image_folder/homepage_images/instructor_images/d86c340d2ae45c789e31979310a04e8dea43f83c0450cbf451da6fe286b074074a940dca5be90a22c3613559d83024d929d8255da7b21208a10e647c8ef17fae.jpg`} alt="place" />
                    <img className="background" src={`${backend_url}/public/image_folder/homepage_images/instructor_images/2fcb2c57098e9936bbc89714b366fbdf7c44abf8cd0eea9080719471176eae2eb12fa29f23b325980827c9a5d509303477a42dade7a33d62eb7ac8b3ded1649b.jpg`} alt="place" />
                    <img className="background" src={`${backend_url}/public/image_folder/homepage_images/instructor_images/fb5fad8b93ea9f87783d8d9785e4bd6f44b07fe1af3d5c4c88db7feba6caa5177b4f83d893432eeeaa1443935f40efca25284a92d99ee934ef75e61a8f51c780.jpg`} alt="place" />
                </div>
                <div className="text-box">
                    <div className="line"></div>
                    <ul>
                        <li>나 자신만의 건강한 습관을 만드는 피트니스</li>
                        <li>통증을 가진 분들을 위한 진화된 피트니스</li>
                        <li>나이를 구분하지 않는 모두를 위한 피트니스</li>
                        <li>평생을 지속할 수 있는 나만의 피트니스</li>
                    </ul>
                </div>
                <div className="logo-text-box">
                    <img
                        src={require("../../assets/logo_images/soleilnoir_logo_w.png")}
                        alt="logo"
                    />
                    <p>
                        양적인 트레이너 양성과<br/>
                        회원을 훈련 시키는 단일적 패러다임에서 벗어나
                    </p>
                    <p>
                        고객을 <b>근기능적으로 이해</b> 시키고<br/>
                        교육하는 <b>RFM Specialist</b>
                    </p>
                </div>
            </div>
        </SCF.FranchiseInstructorContainer>
        <SCF.FranchiseInstructorQA>
            <div className="QAcontainer">
                <div className="qa-box">
                    <div className="box">
                        <p className='background-text'>Q</p>
                        <p className='text q-text'>
                            인스트럭터들이<br/>
                            달성하는 <b className='bold'>월 매출</b>은<br/>
                            어느 정도인가요?
                        </p>
                    </div>
                    <div className="box">
                        <p className='background-text'>A</p>
                        <p className='text a-text'>
                            솔레노어의 인스트럭터들은<br/>
                            <b className='color-bold'>하이엔드 부티크 피트니스라는 명성</b>에<br/>
                            걸맞은 뛰어난 실력을 자랑합니다.<br/>
                            <br/>
                            <b className='color-bold'>한 명의 인스트럭터가 평균 1,600만 원의<br/>
                            월 PT 매출을 달성</b>하며,<br/>
                            놀라운 성과를 보여주고 있습니다.
                        </p>
                    </div>
                </div>
                <div className="qa-box">
                    <div className="box">
                        <p className='background-text'>Q</p>
                        <p className='text q-text'>
                            <b className='bold'>본사에서 지점 운영을<br/> 
                            지원하는 방법</b>은<br/>
                            무엇인가요?
                        </p>
                    </div>
                    <div className="box">
                        <p className='background-text'>A</p>
                        <p className='text a-text'>
                            <b className='color-bold'>본사 소속의 전문 마케팅팀과<br/>
                            자체 인하우스 개발 조직</b>과 <b>마케팅팀</b>이 있어,<br/>
                            매장 관리가 수월합니다. <br/>
                            <br/>
                            <b className='color-bold'>각 지점에는 평균 4.6명의 인스트럭터만을<br/>
                            전문적으로 배치하여<br/>
                            직원 관리의 용이함을 극대화</b>하는<br/>
                            큰 장점도 가지고 있습니다.
                        </p>
                    </div>
                </div>
                <div className="qa-box">
                    <div className="box">
                        <p className='background-text'>Q</p>
                        <p className='text q-text'>
                            평균적으로 회원들이<br/>
                            몇 회의 PT 다회권을<br/>
                            계약하나요?
                        </p>
                    </div>
                    <div className="box">
                        <p className='background-text'>A</p>
                        <p className='text a-text'>
                            솔레노어는 <b className='color-bold'>압도적인 재등록율과<br/>
                            다회권 계약을 통해 고객의 신뢰를 확보하며<br/>
                            지속 가능한 성장</b>을 이루어 가고 있습니다.<br/>
                            <br/>
                            <b className='color-bold'>평균 30회 이상 PT 다회권 계약을 체결</b>하고,<br/>
                            <b className='color-bold'>최대 200회에 달하는 PT 계약</b>이 이루어져<br/>
                            회원님들의 높은 만족도와 신뢰를 얻고 있습니다.
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-box">
                <p>
                    솔레노어는 <b className='color-bold'>철저한 시장 조사와<br/>
                    전략적 계획을 바탕으로, 모든 요소를 고려</b>하였습니다.<br/>
                    <br/>
                    솔레노어는 “건강과 삶의 완벽한 균형"이라는<br/>
                    슬로건을 바탕으로,<br/>
                    <b className='color-bold'>미래를 내다보며 시작된 혁신적인 사업</b> 입니다.
                </p>
            </div>

        </SCF.FranchiseInstructorQA>
    </div>
  )
}

export default FranchiseInstructor