import React from "react";
import * as SCF from "../../style/StyleComponentFranchise";
import { useNavigate } from "react-router-dom";
import { backend_url } from "../../module/DevInformation";

interface Props {
    handleChapter: (chapter_index: number) => void;
    mobileSize: boolean;
}

const HeaderBrand: React.FC<Props> = ({handleChapter, mobileSize}) => {
    const navigate = useNavigate();

    return (
        <>
        {mobileSize ?
          <SCF.MobileHeaderContainer>
            <div
              className="logo-box"
              onClick={() => navigate("/")}
            >
              <img src={`${backend_url}/public/image_folder/homepage_images/brand/e2229cef0e30453ca7ae37eed7ddd3bfe159b3413e24ba2c6e3f5954876feeb9ce3a9c39f9f67c50fc5bd5886a7b01215a73266c81306b98f9e6e5d65ef27ad6.png`} alt="logo" />
            </div>
          </SCF.MobileHeaderContainer>
          :
          <SCF.HeaderContainer>
            <div
              className="logo-box"
              onClick={() => navigate("/")}
            >
              <img src={require("../../assets/logo_images/soleilnoir_logo_w.png")} alt="logo_white" />
            </div>
            <div className="chapter-box">
              <div
                className="chapter"
                onClick={() => {
                  handleChapter(0);
                }}
              >
                브랜드
              </div>
              <div
                className="chapter"
                onClick={() => {
                  handleChapter(1);
                }}
              >
                인스트럭터
              </div>
              <div
                className="chapter"
                onClick={() => {
                  handleChapter(2);
                }}
              >
                브랜드 강점
              </div>
              <div
                className="chapter"
                onClick={() => {
                  handleChapter(3);
                }}
              >
                멤버십
              </div>
              <div
                className="chapter"
                onClick={() => {
                  handleChapter(4);
                }}
              >
                부가 서비스
              </div>
            </div>
            <div className="franchise-menu" 
                  onClick={() => {navigate("/franchise");}}>
                  가맹 안내
            </div>
          </SCF.HeaderContainer>
        }
      </>
    )
}

export default HeaderBrand