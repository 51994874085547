import React from "react";
import * as SCM from "../../style/StyleComponentMenu";
import { backend_url } from "../../module/DevInformation";

const Apparel: React.FC = () => {

  const naverStoreURL = "https://smartstore.naver.com/soleilnoir";

  return (
    <div>
      <SCM.ApparelContainer>
        <div className="container">
            <div className="text-box">
              <p className="title">Soleilnoir Apparel</p>
              <p className="sub-title">솔레노어 어페럴</p>
              <p className="desc">
                일상과 스포츠 그 사이, 언제 어디서나 편하고 자유롭게<br/>
                솔레노어 어패럴은 오랫동안 지속되는 편안함을 선사하고<br/>
                당신의 일상 속 모든 움직임을 서포트 합니다.
              </p>
            </div>
            <div className="product-box">
              <img src={`${backend_url}/public/image_folder/homepage_images/brand/f07594c272c1774ca4f7842f56a3781429b254851656ff8d1505d77aacaa0a7964de5675b72ed5d2f42aba0799ca061450bdd19ac219a8c832da11b99b1bc3f3.jpg`} alt="코튼 티셔츠" />
              <img src={`${backend_url}/public/image_folder/homepage_images/brand/44a9d81c67951482ba885160b2fb31b3d94d04c1fdc0633193aca2f524e014e455ca287fec1be45f66df7f2cd86ebdd19ee83fd89d372b3a439a94749c8dd109.jpg`} alt="베이직 크롭탑" />
            </div>
        </div>
      </SCM.ApparelContainer>
    </div>
  )
};

export default Apparel;