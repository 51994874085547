import styled from "styled-components";
import theme from "./theme";

export const CoreValueParallaxContainer2 = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  @media ${theme.screen.sm} {
    height: 100vw;
    align-items: flex-start;
  }

  & .flex-wrapper {
    position: relative;
    margin-top: 200px;

    & .circle {
      position: absolute;
      display: flex;
      align-items: center;
      width: 400px;
      height: 400px;
      border-radius: 50%;
      justify-content: center;
      text-align: center;
      transition: all 1s;

      @media ${theme.screen.sm} {
        width: 190px;
        height: 190px;
      }

      &.section-1 {
        right: 50%;
        bottom: 50%;
        background-color: rgba(${theme.color.pointGoldRGB}, 0.4);
        border: 1.5px solid ${theme.color.pointGold};
      }
      &.section-2 {
        left: 50%;
        bottom: 50%;
        background-color: rgba(${theme.color.darkGray02RGB}, 0.5);
        border: 1.5px solid ${theme.color.subGray01};
      }
      &.section-3 {
        right: 50%;
        top: 50%;
        background-color: rgba(${theme.color.darkGray02RGB}, 0.5);
        border: 1.5px solid ${theme.color.subGray01};
      }
      &.section-4 {
        left: 50%;
        top: 50%;
        background-color: rgba(${theme.color.pointGoldRGB}, 0.4);
        border: 1.5px solid ${theme.color.pointGold};
      }
    }

    & .core-value-text-box {
      word-break: keep-all;
      transition: all 1s;

      & .core-value-eng {
        font-size: 16px;
      }

      & .core-value-kor {
        margin-top: 10px;
        font-size: 18px;
      }

      @media ${theme.screen.sm} {
        font-size: 10px;
        line-height: 18px;
        white-space: nowrap;

        & .core-value-eng {
          font-size: 12px;
          transition: all 1s;

          & span {
            display: block;
          }
        }

        & .core-value-kor {
          font-size: 14px;
          margin-top: 5px;
          transition: all 1s;

          & span {
            display: block;
          }
        }
      }
    }

    & .doughnut {
      position: absolute;
      display: flex;
      left: 50%;
      top: 50%;
      justify-content: center;
      align-items: center;
      width: 340px;
      height: 340px;
      border: 45px solid;
      border-color: rgba(255, 255, 255, 0.4);
      border-radius: 50%;
      background-color: transparent;
      text-align: center;
      line-height: 30px;
      transform: translate(-50%, -50%);
      opacity: 0;
      animation: showDoughnut 1s forwards;
      animation-delay: 1s;

      @keyframes showDoughnut {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      & h4 {
        font-size: 24px;
      }

      & h5 {
        font-size: 20px;
      }

      @media ${theme.screen.sm} {
        width: 140px;
        height: 140px;
        border-width: 18px;
        line-height: 13px;
        
        & h4 {
          font-size: 10px;
        }

        & h5 {
          font-size: 10px;
        }
      }
    }
  }

  & .animate-center {

    & .circle {

      & .core-value-text-box {
        width: 50%;
        line-height: 20px;

        & .core-value-eng {
          padding: 0 20%;
          font-size: 16px;

          & span {
            display: block;
          }
        }

        @media ${theme.screen.sm} {
          line-height: 13px;
          text-align: center;

          & .core-value-eng {
            font-size: 10px;
          }

          & .core-value-kor {
            font-size: 9px;
          }
        }
      }
    }

    & .section-1 {
      transform: translate(20%, 20%);

      & .core-value-text-box {
        transform: translate(-30%, -75%);

        @media ${theme.screen.sm} {
          transform: translate(-30%, -50%);
        }
      }
    }
    & .section-2 {
      transform: translate(-20%, 20%);

      & .core-value-text-box {
        transform: translate(30%, -75%);

        @media ${theme.screen.sm} {
          transform: translate(30%, -50%);
        }
      }
    }
    & .section-3 {
      transform: translate(20%, -20%);

      & .core-value-text-box {
        transform: translate(-30%, 65%);

        @media ${theme.screen.sm} {
          transform: translate(-30%, 50%);
        }
      }
    }
    & .section-4 {
      transform: translate(-20%, -20%);

      & .core-value-text-box {
        transform: translate(30%, 65%);

        @media ${theme.screen.sm} {
          transform: translate(30%, 50%);
        }
      }
    }
  }
`;

export const CoreValueParallaxContainer = styled.div<{$centerWidth: number; $centerHeight: number}>`
  position: relative;
  width: 100%;
  height: 250vh;
  scroll-behavior: smooth;
  background-color: transparent;
  
  & .core-value-parallax {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 360px;
    height: 360px;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    opacity: 0;

    @media ${theme.screen.sm} {
      width: 190px;
      height: 190px;
    }

    &.section-1 {
      right: 50%;
      bottom: 50%;
      background-color: rgba(${theme.color.pointGoldRGB}, 0.4);
      border: 1.5px solid ${theme.color.pointGold};
    }
    &.section-2 {
      left: 50%;
      bottom: 50%;
      background-color: rgba(${theme.color.darkGray02RGB}, 0.5);
      border: 1.5px solid ${theme.color.subGray01};
    }
    &.section-3 {
      right: 50%;
      top: 50%;
      background-color: rgba(${theme.color.darkGray02RGB}, 0.5);
      border: 1.5px solid ${theme.color.subGray01};
    }
    &.section-4 {
      left: 50%;
      top: 50%;
      background-color: rgba(${theme.color.pointGoldRGB}, 0.4);
      border: 1.5px solid ${theme.color.pointGold};
    }

    & .core-value-text-box {
      font-size: 20px;
      line-height: 40px;

      @media ${theme.screen.sm} {
        font-size: 10px;
        line-height: 18px;
        white-space: nowrap;
      }
    }
  }

  & .center-core-value-parallax {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    opacity: 0;
    left: 0;
    top: 0;
    
    & .doughnut {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 320px;
      height: 320px;
      border: 40px solid;
      border-color: rgba(255, 255, 255, 0.4);
      border-radius: 50%;
      background-color: transparent;
      text-align: center;
      line-height: 30px;
      /* transform: translateY(-2.5vh); */

      & h4 {
        font-size: 24px;
      }

      & h5 {
        font-size: 20px;
      }

      @media ${theme.screen.sm} {
        width: 140px;
        height: 140px;
        border-width: 18px;
        
        & h4 {
          font-size: 12px;
        }

        & h5 {
          font-size: 10px;
        }
      }
    }
  }
`;

// Blur is not performant
// Used just as an example for CSS filters
// {
//   startValue: 0,
//   endValue: 2,
//   property: "blur"
// }

export const MembershipParallaxContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  scroll-behavior: smooth;
  overflow: auto;
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-position: center;
    background-size: cover;
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
`;