import React from "react";
import Kitchen from "./Kitchen";
import Apparel from "./Apparel";
import * as SCM from "../../style/StyleComponentMenu";
import { backend_url } from "../../module/DevInformation";

const SubBrand: React.FC = () => {
  return (
    <div>
      <SCM.SubBrandTitle>
      <h2 className="title">
        건강한 라이프 스타일을 위한<br/>
        <span>솔레노어 부가 서비스</span>
      </h2>
      <div className="icon-container">
        <div className="icon-box">
          <div className="icon">
            <img src={`${backend_url}/public/image_folder/homepage_images/brand/004a70536737280ddaf9c2d6c13b4b9449197f18fc973b056a94c85b4ae2adfd1501ce077af5dde37d9541b7671051292bd21c96715c4f328d5877d0a5f904fa.png`} alt='icon' />
          </div>
          <p>솔레노어 어페럴</p>
        </div>
        <div className="icon-box">
          <div className="icon">
            <img src={`${backend_url}/public/image_folder/homepage_images/brand/bb0563efbd53ccb5810fbf0cdc4b12a4e9c0582fe38a33a70f107ab6c6a663887576f093006b6c8200fcf4f254f9c1b80d73744b565f41aa850d90f1c4c1b90b.png`} alt='icon' />
          </div>
          <p>솔레노어 키친</p>
        </div>
      </div>
      </SCM.SubBrandTitle>
      <Apparel />
      <Kitchen />
    </div>
  )
};

export default SubBrand;