import React from "react";
import * as SCM from "../../style/StyleComponentMenu";
import { MembershipBenefitData } from "../../module/Variable";
import { useState } from "react";
import { backend_url } from "../../module/DevInformation";

const Benefit: React.FC = () => {
  const [active, setActive] = useState<boolean>(false);

  return (
    <>
      <SCM.BenefitContainer
        style={{
          backgroundImage: `url(${backend_url}/public/image_folder/homepage_images/00877b40268fb34a010a35280881c403a3b7f44d72024765852bfc26cf7c7d60abef1d7b447219a05c74e8e3b3d662dcebbfcdf2ba66b6859bbf652c46e3c17d.png)`
        }}
      >
        <div>
          <div className="menu-box">
            <div
              className={`benefit-title-box `}
              // ${active ? "sm:translate-y-[-100px]" : ""}
              onClick={() => setActive(!active)}
            >
              <h2>건강과 삶의 완벽한 균형을 위한</h2>
              <h2>솔레노어만의 특별한 혜택</h2>
              <button>
                {active ? "Hide Me" : "Show More"}
              </button>
            </div>
            {MembershipBenefitData.map((tmp, idx) =>
              <SCM.BenefitItems
                $index={idx}
                $countItems={MembershipBenefitData.length}
                key={idx}
                className={`${active ? "active" : ""}`}
              >
                <div className="menu-item">
                  <div>
                    <div className="image-box">
                      <img src={tmp.icon} alt="" />
                    </div>
                    <div className="text-box">
                      <h3 className="font-CormorantGaramond">
                        {tmp.title}
                      </h3>
                      <p>{tmp.content1}</p>
                      <p>{tmp.content2}</p>
                    </div>
                  </div>
                </div>
              </SCM.BenefitItems>
            )}
          </div>
        </div>
      </SCM.BenefitContainer>
    </>
  )
};

export default Benefit;