import React from 'react'
import { backend_url } from "../../module/DevInformation";
import * as SCM from "../../style/StyleComponentMenu";
import { ScrollAnimationContainer } from "../util/ScrollAnimationContainer";

function PtJourney() {
  return (
    <SCM.JourneyContainer>
    <>
        <div className="container">
            <ScrollAnimationContainer
                direction="L"
                duration="0.5s"
                delay="0.1s"
                threshold={0.8}
                onlyOnce
            >
            <div className="ticket">
                <div className='title-box'>
                    <img className='ticket-logo' src={`${backend_url}/public/image_folder/homepage_images/brand/e2229cef0e30453ca7ae37eed7ddd3bfe159b3413e24ba2c6e3f5954876feeb9ce3a9c39f9f67c50fc5bd5886a7b01215a73266c81306b98f9e6e5d65ef27ad6.png`} alt="logo" />
                    <p>Boarding Pass to <b>New Life</b></p>
                </div>
                <div className="subTitle-box">
                    <p>Perfect Balance<br />Between Health And Life.</p>
                    <img className='subTitle-arrow' src={`${backend_url}/public/image_folder/homepage_images/brand/5b2c1c87ceeac4ed0a144c1444e2be2c022abf6682375b60cf051107eb22ae8fe126eb388c58e4e24fd0249c6cb12757ed78846202a772d605ccae42f87d70db.png`} alt="arrow" />
                    <p className='subTitle-text--2'>Journey to New Life</p>
                </div>
                <div className="desc-box">
                    <h2 className='desc-title'>3times Boarding Pass</h2>
                    <ul>
                        <li className='earth'>
                            <p className='name'>The<br />Earth</p>
                            <p className='price'>180,000<span className='krw'>KRW</span></p>
                        </li>
                        <li className='moon'>
                            <p className='name'>The<br />Moon</p>
                            <p className='price'>210,000<span className='krw'>KRW</span></p>
                        </li>
                        <li className='sun'>
                            <p className='name'>The<br />Sun</p>
                            <p className='price'>240,000<span className='krw'>KRW</span></p>
                        </li>
                    </ul>
                </div>
            </div>
            </ScrollAnimationContainer>
            <ScrollAnimationContainer
                direction="L"
                duration="0.5s"
                delay="0.3s"
                threshold={0.8}
                onlyOnce
            >
            <div className="desc-container">
                <p className='desc-text1'>
                    차별화된 고객경험을 위한 1회권,<br/>
                    “Journey to New Life<br/>
                    건강한 삶을 향한 여정"
                </p>
                <div className="line"></div>
                <p className='desc-text2'>건강한 삶을 향한 여정,  그리고 그 여정을 위한 티켓<br />솔레노어와 함께 삶을 위한 건강한 여정을 떠나보세요</p>
            </div>
            </ScrollAnimationContainer>
        </div>
    </>
    </SCM.JourneyContainer>
  )
}

export default PtJourney