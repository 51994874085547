/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import * as SCM from "../../style/StyleComponentMenu";
import { backend_url } from "../../module/DevInformation";

const MobileApp: React.FC = () => {
    return (
        <div>
        <SCM.MobileAppContainer>
            <div className="container">
                <img src={`${backend_url}/public/image_folder/homepage_images/brand/0e718f3daf50ba553c96ddbc2bee8dc18ace8fc0f959c8ba922c8fceb5ec3cb42664f91ba2495da727b78b81d729d2ef1f6e26e31e723a049a3784157443847e.png`} />
                <p className='title'>
                    <b>Boutique Fitness</b><br/>
                    With App
                </p>
                <p className='sub-title'>어플리케이션 기반의 부티크 피트니스</p>
                <div className="text-container">
                    <div className="text-box">
                        <p className='text-box-title'>Smart Fitness Life</p>
                        <p className='text-box-desc'>솔레노어 멤버십에 가입하면 앱을 통해 <span>스마트한 피트니스 라이프 스타일을 누릴 수 있습니다.</span></p>
                    </div>
                    <div className="text-box">
                        <p className='text-box-title'>Purpose Driven Fitness</p>
                        <p className='text-box-desc'>솔레노어 앱과 지식을 갖춘 인스트럭터의 조합은 지속가능한 <span>피트니스 목적 기반의 시너지를 낼 수 있습니다.</span></p>
                    </div>
                </div>
            </div>
        </SCM.MobileAppContainer>
        </div>
    )
}

export default MobileApp