import React from "react";
import * as SCI from "../../style/StyleComponentInstructor";
import { backend_url } from "../../module/DevInformation";

const ChiefManager: React.FC = () => {

  return (
    <div>
    <SCI.ChiefManagerContainer>
        <div className="container">
          <div className="text-box">
            <p className="name">강 한 별</p>
            <p className="rank">대표</p>
            <h2 className="title">
              “단순한 운동이 아닌<br/>
              평생을 위한 건강을 지지합니다.”
            </h2>
            <p className="desc">
              솔레노어 인스트럭터는 끊임없는 도전과 노력을 통해 성장합니다.<br/>
              RFM을 통해 구조적인 통증을 근본적으로 개선하고,<br/>
              솔레노어는 인스트럭터가 최고의 역량을 발휘할 수 있도록 지지합니다.<br/>
              <br/>
              <b>인스트럭터의 성장이 곧 솔레노어의 발전입니다.</b>
            </p>
          </div>
          <img
            className="picture"
            src={`${backend_url}/public/image_folder/homepage_images/brand/ece5eeaf13fc6d6e377b0d12dc52c72b44fba3550b0635220f380a2766f2fd8ac463771d5abc64ebf4edd7e9b165b4f21ba2646a21fa76e7ebb0344c45640bb9.png`}
            alt="ChiefManager"
          />
          <div className="slogan">
            <p className="font-CormorantGaramond">
              Perfect Balance<br/>
              Between<br/>
              Health And Life
            </p>
            <img
              className="slogan-img"
              src={`${backend_url}/public/image_folder/homepage_images/brand/9a9e4d5432cc8bd657b1227836472561ad72412ad3401c6638e0441e46cea9996e1e115806f8b0843f892317f1ffc4facfb733db33d27442338f98c934867ba4.png`}
              alt="circle"
            />
          </div>
        </div>
    </SCI.ChiefManagerContainer>
    </div>
  )
};

export default ChiefManager;