import { useEffect } from "react";
import { Container } from "../../style/StyleComponentUtility";
import { useScrollAnimation } from "./useScrollAnimation";
import { useState } from "react";

type PropsType = {
  children: React.ReactNode;  // 내용
  direction: string;
  duration: string; // 애니메이션 시간
  delay: string; // 딜레이
  threshold: number; // viewport에 얼마나 들어와야 애니메이션 시작하는지
  onlyOnce? : boolean; // 한번만 할것인지
  emit?: (isInViewport: boolean) => void;
};

export const ScrollAnimationContainer = ({
  children,
  direction,
  duration,
  delay,
  threshold,
  onlyOnce = false,
  emit,
}: PropsType) => {
  const { ref, isInViewport } = useScrollAnimation(threshold, onlyOnce);
  const [animation, setAnimation] = useState<string>("fade-in");

  useEffect(() => {
    if (emit) {
      emit(isInViewport);
    }
  }, [isInViewport, emit]);

  useEffect(() => {
    switch (direction) {
      case "L" : setAnimation("fade-in-slide-l"); break;
      case "B" : setAnimation("fade-in-slide-b"); break;
      case "T" : setAnimation("fade-in-slide-t"); break;
      case "R" : setAnimation("fade-in-slide-r"); break;
      default : break;
    }
  }, [direction]);
  
  return (
    <Container
      ref={ref}
      // className={isInViewport ? `${direction === "L" ? "fade-in-slide-l" : `${direction === "B" ? "fade-in-slide-b" : "fade-in-slide" }` }` : ""}
      className={isInViewport ? animation : "initial"}
      $duration={duration}
      $delay={delay}
    >
      {children}
    </Container>
  );
};