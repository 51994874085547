import React from "react";
import HeaderFranchise from "./HeaderFranchise";
import Introduction from "./Introduction";
import Strength from "./Strength";
import Cost from "./Cost";
import Inquiry from "./Inquiry";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import theme from "../../style/theme";

const Franchise: React.FC = () => {
  const [mobileSize, setMobileSize] = useState<boolean>(false);
  const [browserWidth, setBrowserWidth] = useState<number>(0);
  const chapterRef = useRef<any>([]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, []);

  const handleResize = () => {
    setBrowserWidth(window.innerWidth);
    if (window.innerWidth < theme.inner_width.sm) {
      setMobileSize(true);
    } else {
      setMobileSize(false);
    }
  }

  const handleChapter = (chapter_index: number) => {
    if (chapterRef.current) {
      chapterRef.current[chapter_index].scrollIntoView({ behavior: 'smooth'});
    }
  };

  return (
    <>
      <HeaderFranchise handleChapter={handleChapter} mobileSize={mobileSize} />
      <div
        ref={(el) => {chapterRef.current[0] = el;}}
      >
        <Introduction mobileSize={mobileSize} browserWidth={browserWidth} />
      </div>
      <div
        ref={(el) => {chapterRef.current[1] = el;}}
      >
        <Strength mobileSize={mobileSize} browserWidth={browserWidth} />
      </div>
      <div
        ref={(el) => {chapterRef.current[2] = el;}}
      >
        <Cost />
      </div>
      <div
        ref={(el) => {chapterRef.current[3] = el;}}
      >
        <Inquiry />
      </div>
    </>
  )
};

export default Franchise;