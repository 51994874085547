import React from 'react'
import * as SCM from "../../style/StyleComponentMenu";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";

function FranchiseNavi() {
    const navigate = useNavigate();
  return (
    <div  onClick={() => navigate("/franchise")}>
        <SCM.FranchiseNaviContainer>
            <div className="text-box">
                <p className='text'>
                    남들과 다른 당신에게 맞는,<br/>
                    당신의 건강과 삶의 완벽한 균형<br/>
                    그것을 위해 <span>솔레노어를 제안</span> 합니다.
                </p>
                <p className='click-button'>
                    해당 화면 클릭시 가맹 안내 페이지로 넘어갑니다<IoIosArrowForward />
                </p>
            </div>
        </SCM.FranchiseNaviContainer>
    </div>
  )
}

export default FranchiseNavi