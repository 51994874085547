import React from 'react'
import * as SCM from "../../style/StyleComponentMenu";
import { backend_url } from "../../module/DevInformation";
import { place } from '../../module/Variable';

function Place() {
  return (
    <div>
        <SCM.PlaceContainer>
        <div>
            <div className="container">
                <p className='title'>
                    <span>솔레노어는 프라이빗한 공간에서 </span>
                    <span>전문 인스트럭터와</span><br/>
                    <b>’높은 수준의 새로운 피트니스 라이프’</b>를<br/>
                    느낄 수 있습니다.
                </p>
                <div className="img-container">
                    {place.map((value, index) =>
                        <div className="img-box" key={index}>
                            <img src={`${backend_url}/public/image_folder/homepage_images/brand/${value.image}.png`} alt={value.desc} />
                            <p>{value.desc}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
        </SCM.PlaceContainer>
    </div>
  )
}

export default Place