import React from 'react'
import * as SCF from "../../style/StyleComponentFranchise";
import { backend_url } from "../../module/DevInformation";

function Model() {
  return (
    <div>
      <SCF.ModelContainer>
        <div className='product-container'>
          <div className="product-item">
            <img className="background" src={`${backend_url}/public/image_folder/homepage_images/franchise/b9876847d45aea1355b3a0a870f08ebee42ffffd463138f33c3ba1137df175d14f28943a9f5fb24936a80118e524aca530c052623c9f90e569fa4ed47faf058c.jpg`} alt="place" />
            <div className="text-box">
              <p className='product-name'>PT Studio</p>
              <p className='product-desc'>
                <b>Private High-end PT Studio</b><br/>
                50평 미만의 횟수 당 6-9만원대<br/>
                고가 리햅 (재활 전문) 피트니스 1:1 PT 전문 샵
              </p>
              <p className='product-desc'>
                - 프라이빗한 1:1 PT 룸 및 1인 샤워실 사용<br/>
                - 내부 인테리어 또한 High-end Service만을 제공<br/>
                <br/>
                - 물리치료사 / 국제 자격증/ RFM 등 전문 인스트럭터<br/>
                - 피지컬 운동과 재활 케어가 동시에 가능한 체계화된 프로그램<br/>
                - 모바일 앱을 통한 체계적인 수업 관리 및 프로그램 운영
              </p>
            </div>
          </div>
          <div className="product-item">
            <div className="text-box">
              <p className='product-name'>PT Field</p>
              <p className='product-desc'>
                <b>PT + Public PT Field</b><br/>
                80평 이상의 횟수 당 6-9만원대<br/>
                고가 리햅(재활 전문) 피트니스 1:1 PT 전문 샵 + PB 센터
              </p>
              <p className='product-desc'>
                - 담당 인스트럭터와의 1:1 PT<br/>
                - 피트니스 공간을 사용할 수 있는 퍼블릭 회원권 판매<br/>
                <br/>
                - 물리치료사 / 국제 자격증/ RFM 등 전문 인스트럭터<br/>
                - 피지컬 운동과 재활 케어가 동시에 가능한 체계화된 프로그램<br/>
                - 모바일 앱을 통한 체계적인 수업 관리 및 프로그램 운영
              </p>
            </div>
            <img className="background" src={`${backend_url}/public/image_folder/homepage_images/franchise/1400e20a78f95a1df4cd8445f39efe699d000066ac66dbe0434496f4778f176e822d5960e0bd52cb5c04faaaa0e0d00f9e023edf4a965c6c1ef8e019ef2465f0.jpg`} alt="place" />
          </div>
        </div>
      </SCF.ModelContainer>
    </div>
  )
}

export default Model