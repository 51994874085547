import { backend_url } from "./DevInformation";
import app_icon from "../assets/icon_images/membership_icons/icon_app_gold.svg";
import apparel_icon from "../assets/icon_images/membership_icons/icon_apparel_gold.svg";
import kitchen_icon from "../assets/icon_images/membership_icons/icon_kitchen_gold.svg";
import profile_icon from "../assets/icon_images/membership_icons/icon_profile_gold.svg";
import schedule_icon from "../assets/icon_images/membership_icons/icon_schdule_gold.svg";
import point_icon from "../assets/icon_images/point.svg";


export const ApplicationDescriptionData = [
  {
    index: 0,
    title: ["솔레노어 앱,", "피트니스의 새로운 방향성"],
    content1: ["솔레노어 모바일 앱에서는 일정관리는 물론,", "인스트럭터와 당신의 바디 데이터를 공유하고", "그에 맞는 식단 솔루션까지 제안합니다."],
    content2: ["지금 당신의 건강 상태를 분석하고,", "건강한 라이프 스타일로 바꿔보세요."],
  },
  {
    index: 1,
    title: ["언제, 어디서나", "쉽고 빠르게 예약하실 수 있습니다."],
    content1: ["바쁘다는 이유로 시간이 남으면 운동하거나, 빼먹지는 않으셨나요?", "솔레노어 모바일 앱에서는 내가 원하는 시간에 PT를 예약하고", "일정을 변경할 수 있습니다."],
    content2: ["일상에서 남는 시간에 운동하셨다면,", "솔레노어에서는 운동 스케줄을 먼저 설정해보세요."],
  },
  {
    index: 2,
    title: ["매일의 식단을 기록하고,", "피드백을 받아보세요."],
    content1: ["솔레노어 앱에서 오늘 섭취한 칼로리들을 자동으로 계산하고,", "목표 칼로리에 맞춰서 식단을 할 수 있도록 도와줍니다.", "식단을 등록하면 담당인스트럭터에게 피드백을 받을 수 있습니다."],
    content2: ["매일의 식단을 기록하고,", "피드백을 받아보세요."],
    content4: "변화되는 나의 바디데이터를 지속적으로 체킹하고,",
    content5: "평생의 건강한 삶을 위한 새로운 라이프스타일을 경험해보세요.",
  },
  {
    index: 3,
    title: ["개인 번호로 연락할", "필요 없는 채팅 기능"],
    content1: ["개인 번호로 연락할 필요 없이", "채팅 기능을 이용해 인스트럭터와 소통하세요."],
    content2: ["나의 일상을 인스트럭터와 공유하고,", "올바른 생활습관으로 변화 할 수 있도록 합니다.", "평생의 건강한 삶을 위한 새로운 라이프스타일을 경험해보세요."],
  },
];

export const MembershipHeaderCarouselData = [
  {
    background_url: `${backend_url}/public/image_folder/homepage_images/3d4ea7072b3d6b1d7814ba689731d8f989147415856c663b0ebacc266a7f82cabb92ea75b68cb9c19a0137a91ff29517266e27669436403f56694cb0939f7458.jpg`,
    title: "The Journey to a New Life",
    content: "건강한 매일의 일상, 솔레노어",
  },
  {
    background_url: `${backend_url}/public/image_folder/homepage_images/b5ddda94176ec32493d769c45c1e5b6c8c0d06391c2218bbe025999500dfd2fa89e83b2968aa30bf65db16b4b7e7b80f253399b4160c5579cb5607dfedeb48ae.png`,
    title: "Soleilnoir App",
    content: "PT 구매부터 예약, 식단관리까지",
  },
  {
    background_url: `${backend_url}/public/image_folder/homepage_images/0c991b95be8eaf6552965c4fd7b8cbf399ff9aa6d0bba4b4fa6280290c590adce5fdbf6f7cca3d9f252d635c2278c45224333dd66fdfd0f2127f5a55b5a98e23.jpg`,
    title: "타이틀 2",
    content: "컨텐츠2",
  },
  {
    background_url: `${backend_url}/public/image_folder/homepage_images/05d9acbf0d9d5c94d2c709ec7c05a5f481e94ca538760f3e79fd8625e8de60b90886aad999cbf678cbd826b292a88f4efdffc104a6b23fedbe237979eae92e38.jpg`,
    title: "타이틀 3",
    content: "컨텐츠3",
  },
];

export const ProfileStudioSampleData = [
  `${backend_url}/public/image_folder/homepage_images/profile_studio_images/1f7f215f5fec4ea74e6d4c26f895faa5cdd5b890f55f9fe219708ac28422396bfb0cc16b6f692d5adcd1898b92e3af7071bc46bc20401350e9959566f242b99b.jpg`,
  `${backend_url}/public/image_folder/homepage_images/profile_studio_images/0c777eb982e7148e6ba32a1c897faa373f73b2f49f6dd2f1c262e84a091a038f07f9cbdbcb87f0570f61b61f9e71c2849afe3182c2219b960fd9efc51d71d3dc.jpg`,
  `${backend_url}/public/image_folder/homepage_images/profile_studio_images/2fa83c6d6f424bad26a3edc9c72a4fbdef9ba0e448837e21dbb411356164f9879d611b00c7ba27344c4ca1b463d8f66bf3a967df1763937822f100ba8c87e629.jpg`,
  `${backend_url}/public/image_folder/homepage_images/profile_studio_images/7dd7786f8e6e8e8935f914fd0428f072b2d9c01c0ffc91e42b84fd2c5f8d16cf4806346ef4e051cc088a0f6753519cda26b32a935c0509dff2ee3d009c2b0111.jpg`,
  `${backend_url}/public/image_folder/homepage_images/profile_studio_images/8a315ed95507f9dc4f0e5204d728a65423e6324268a0222d6e0d42bddef82120231376a8e79f249ed13bd3c1167ae931e481af1c2938c2c81d0dc1df3298fda2.jpg`,
  `${backend_url}/public/image_folder/homepage_images/profile_studio_images/666f79aaf76c878233b343154f13c03d0aa5a12363302e40577dd1a2753c5dfe8abaa1d7459509e989dc26e6f7597ef839c80fd08c95000fad7f02de1a478128.jpg`,
  `${backend_url}/public/image_folder/homepage_images/profile_studio_images/e98ba9b9015537dc1e77697eb0a2e457216c58cd8a26163de45587ae8a51ba3817641463495124cd26b92ebb56d99ce1bcc1e6b4db2622b82eb7c032bac4dcdb.jpg`,
  `${backend_url}/public/image_folder/homepage_images/profile_studio_images/7608b0ed06401c2ad01421b693073e3344fe8822d97b373383e06aae41272c0d1709856b2fabcbd4c81fcfdc1e7a3cbbb01db469dfaefaafe22e6a8452318944.jpg`,
  `${backend_url}/public/image_folder/homepage_images/profile_studio_images/aac18a51509f67fe1d33ffe2927d33e24585f2bbce3a3f683ad329bbe7f88d36c17c82ee4f675435a5db49c8d5838cb8b1de4735b5a9013e5c3b930e54cf21df.jpg`,
  `${backend_url}/public/image_folder/homepage_images/profile_studio_images/e7a979d4eb5609bc8e401d0a9f15305ac6b992210ae91a9e9a23570559163a486d3e937ee9d7350c4e66a30d4a2697ac708233a703eab7e186f32a0bda026735.jpg`,
  `${backend_url}/public/image_folder/homepage_images/profile_studio_images/c95e790f3b3cc7ba4ddf9e293ff55ea8e0fbede9e6cf186203354d66bac10381bd2f88c9552dc05ff068794b7db8a5e7c730c87cb3292ce2be5c19ecdfafb558.jpg`,
  `${backend_url}/public/image_folder/homepage_images/profile_studio_images/e3fd9f29c5595b2f5533b29c1e5ff672c3b09727c7be4380098ac1f35f2d7d1cf2fde19c797368d2112b93f429c6d4c6a682a25de593205d1a8e29b5eaa598b6.jpg`,
];

export const MembershipBenefitData = [
  {
    icon: point_icon,
    title: "Mileage System",
    content1: "PT/PB 결제 시 누적포인트로",
    content2: "서브-브랜드 상품 구매",
  },
  {
    icon: app_icon,
    title: "Online Classes",
    content1: "인스트럭터와 데이터 공유로",
    content2: "지속적인 관리 가능",
  },
  {
    icon: schedule_icon,
    title: "Scheduling",
    content1: "언제, 어디서든 ",
    content2: "원할 때 예약 가능",
  },
  {
    icon: kitchen_icon,
    title: "Kitchen",
    content1: "식단관리를 위한",
    content2: "샐러드 정기구매 및 구독 가능",
  },
  {
    icon: profile_icon,
    title: "Profile",
    content1: "라이프 스타일에 맞는",
    content2: "프로필 촬영",
  },
  {
    icon: apparel_icon,
    title: "Apparel",
    content1: "몸에 더 편안한",
    content2: "솔레노어 어페럴",
  },
];

export const brand_gallery_image_data_1 = [
  `${backend_url}/public/image_folder/homepage_images/gallery_images/gallery_test_03.jpg`,
  `${backend_url}/public/image_folder/homepage_images/gallery_images/gallery_test_01.jpg`,
  `${backend_url}/public/image_folder/homepage_images/gallery_images/gallery_test_04.jpg`,
  `${backend_url}/public/image_folder/homepage_images/gallery_images/gallery_test_05.jpg`,
  `${backend_url}/public/image_folder/homepage_images/gallery_images/gallery_test_02.jpg`,
];

export const brand_gallery_image_data_2 = [
  `${backend_url}/public/image_folder/homepage_images/gallery_images/gallery_test_06.jpg`,
  `${backend_url}/public/image_folder/homepage_images/gallery_images/gallery_test_08.jpg`,
  `${backend_url}/public/image_folder/homepage_images/gallery_images/gallery_test_07.jpg`,
  `${backend_url}/public/image_folder/homepage_images/gallery_images/gallery_test_09.jpg`,
  `${backend_url}/public/image_folder/homepage_images/gallery_images/gallery_test_10.jpg`,
]

export const why_soleilnoir_data = [
  {
    title: "국내 최대 IT대기업 출신",
    summary_1: "CTO가 직접 리드하는",
    summary_2: "자체 인하우스 개발조직",
  },
  {
    title: "본사 소속 마케팅팀",
    summary_1: "직영점 30점 PT샵에서 3명의 인스트럭터로",
    summary_2: "1년간 월 평균 매출 3,000만원 유지하는 마케팅 A to Z",
  },
  {
    title: "브랜딩을 이해하는 조직",
    summary_1: "자체 촬영 스튜디오를 보유 한",
    summary_2: "영상기반의 자체 콘텐츠 기획조직 보유",
  },
  {
    title: "인플루언서 브랜드 대표",
    summary_1: "팔로워 5만명의",
    summary_2: "솔레노어 대표",
  },
  {
    title: "연 매출 50억대 본사",
    summary_1: "리빙 업계 백화점채널",
    summary_2: "수입조명 카테고리 업계 1위",
  },
];

export const franchise_intro_marker_data = [
  {
    coordinate: [50, 12],
  },
  {
    coordinate: [-130, 14],
  }, 
  {
    coordinate: [-42, 31],
  },
  {
    coordinate: [-100, 22],
  },
  {
    coordinate: [110, 36],
  },
  {
    coordinate: [-170, 39],
  },
  {
    coordinate: [82, 49],
  },
  {
    coordinate: [-120, 51],
  },
  {
    coordinate: [184, 54.5],
  },
  {
    coordinate: [-10, 55],
  },
  {
    coordinate: [178, 60],
  },
  {
    coordinate: [-205, 62],
  },
  {
    coordinate: [154, 62],
  },
  {
    coordinate: [-125, 68],
  },
];

export const process_data = [
  {
    index: "01",
    text_kor_1: "비즈니스",
    text_kor_2: "목표설정",
    text_eng_1: "Business Goal Set Up",
    text_eng_2: "",
    text_eng_3: "",
  },
  {
    index: "02",
    text_kor_1: "상권분석",
    text_kor_2: "및 가견적",
    text_eng_1: "Business District",
    text_eng_2: "Analysis",
    text_eng_3: "and Pre_Quotation",
  },
  {
    index: "03",
    text_kor_1: "인테리어",
    text_kor_2: "",
    text_eng_1: "Interior",
    text_eng_2: "",
    text_eng_3: "",
  },
  {
    index: "04",
    text_kor_1: "마케팅",
    text_kor_2: "",
    text_eng_1: "Marketing",
    text_eng_2: "",
    text_eng_3: "",
  },
  {
    index: "05",
    text_kor_1: "교육",
    text_kor_2: "",
    text_eng_1: "Education",
    text_eng_2: "",
    text_eng_3: "",
  },
];

export const instructor_intro_image_url_data = [
  `${backend_url}/public/image_folder/homepage_images/instructor_images/2fcb2c57098e9936bbc89714b366fbdf7c44abf8cd0eea9080719471176eae2eb12fa29f23b325980827c9a5d509303477a42dade7a33d62eb7ac8b3ded1649b.jpg`,
  `${backend_url}/public/image_folder/homepage_images/instructor_images/3d14ba909cfc6ccbce2fbcd45a65da028cc5756a5243c8f3be7d2e561dc34cd4332b001e76455c57b13ab30ec143447cad1a9aa478984f276a741d9c8c0dbb82.jpg`,
  `${backend_url}/public/image_folder/homepage_images/instructor_images/3d76d758374b9c4847182cfa4f40a9bf1741f1b46b8385a74abba5ea15412c10d2b766d97d8c00e360ad6bf6c9e6c03251916ee44128efd20dbc76da325863ef.png`,
  `${backend_url}/public/image_folder/homepage_images/instructor_images/6b8da070809a717d93628c36bdee36d5da414fe278ae000c0590b49f9916051ff3d98c4d78df7babaa06613f386387dc3ef573a8c0a794b187d5a73cfa9c0da9.jpg`,
  `${backend_url}/public/image_folder/homepage_images/instructor_images/50fb6d1ec1a622b73fafc097b8297f02bb06b24db8814906ac763366c73772278be63bf87e36554b66ca2978f69f200ff2040042ab266fa8d962a16bcbf71c89.jpg`,
  `${backend_url}/public/image_folder/homepage_images/instructor_images/87f834655e3682933c843efc6b9a3bbbd1aedaefb838ce57df22c530c997155bbf5d8cde814213f764dec62e8ee308cd99c5b778ea79e5e11b8537963a3f365c.jpg`,
  `${backend_url}/public/image_folder/homepage_images/instructor_images/303b5f63e756de7b050d804234258a9440c31e4ef3889b4012e174167c7bb095779090053196af2ea8f7f5c2f0129db0eea0fc28f4a265d84e1f6ffcabdf4f31.png`,
  `${backend_url}/public/image_folder/homepage_images/instructor_images/489aae96551c355e539e6897caa1268a15aaee9d72f62ad8b8985b5ecc6fdf551d68dc3881a5c9d967b8878cfdfa69c7909aad5b7f455e30a7d9bee26df282b7.jpg`,
  `${backend_url}/public/image_folder/homepage_images/instructor_images/37043343b1c711be008b5d5a143e6748a96f7412767abbe8f2c4f4b9d2ddbf54cb6ac26b30ab89eced89246dd19137a475fa04ae04cf8509f99694f1e05f40b2.jpg`,
  `${backend_url}/public/image_folder/homepage_images/instructor_images/a08b857ef50d0abe5b20eb24712d8a55549b0b7401af406ad054f5f97e81f3a5a3ab33d1d16805e549194c683d05e266b4f77bce47fce5ad9cca1114b9e9c288.png`,
  `${backend_url}/public/image_folder/homepage_images/instructor_images/a74e31e3d46f2a73f92bee5555831ae3ce60415383e3a69004320ca37487778a4b6714a0807a24ac7fc323cb9d2a5b48b71415b5dcc945c901d30ccd3f3e8f4b.jpg`,
  `${backend_url}/public/image_folder/homepage_images/instructor_images/a41171c51e056020c4554007a3cda873dbc4c7ebc05f1a3dd372bf199c5761fd2ad54ec2e2c67d63cf97cb3d1299d04593f69434bb179df2734f896a27fca70a.jpg`,
  `${backend_url}/public/image_folder/homepage_images/instructor_images/b72c839276f5cf051155037426aad8f61402ff9d0432bc97b5e824ed4bb49f10622235176158463371c8d61f995a730cf75c4c4bc08901596fe5582130139178.jpg`,
  `${backend_url}/public/image_folder/homepage_images/instructor_images/b886ba397ab9e1a155d1b512bf6dc58aa5732f150a0bb46f50840cb769ff3092e49b1dcd03f68414afa15c9198154d093ff4e1a4c8234359df118e8ff2a4e643.jpg`,
  `${backend_url}/public/image_folder/homepage_images/instructor_images/d9fb9b6c2e0f7f4bccef9d0a33d6216ecf8876450fa70667d6239938646ec4318e89f4a12fbe31fd66caf5762848798966d367653379201b74ba74808e6eb81d.png`,
  `${backend_url}/public/image_folder/homepage_images/instructor_images/d86c340d2ae45c789e31979310a04e8dea43f83c0450cbf451da6fe286b074074a940dca5be90a22c3613559d83024d929d8255da7b21208a10e647c8ef17fae.jpg`,
  `${backend_url}/public/image_folder/homepage_images/instructor_images/f4c6d3c757db6a3174134e348f5e663396c360e6fafe0c4a84d569b7203ee0f4c27aa776a585ace566b00a6ff2b8dfef9706de918419dd2ce05298a9a709b85c.jpg`,
  `${backend_url}/public/image_folder/homepage_images/instructor_images/fb5fad8b93ea9f87783d8d9785e4bd6f44b07fe1af3d5c4c88db7feba6caa5177b4f83d893432eeeaa1443935f40efca25284a92d99ee934ef75e61a8f51c780.jpg`,
  `${backend_url}/public/image_folder/homepage_images/instructor_images/fc70572ee9ec5c76e5226c0c711086d92be818710f08cfb27f5b7b563d8aac665646bde57535e0800fca6804e290f8c58b598fcb9a8cc69f6db9444c35a48205.jpeg`,
  `${backend_url}/public/image_folder/homepage_images/instructor_images/ff204c8ffb62aa0ef532e7d39a06f5d9418a90a762e08cf3a9c92f9614b7c23a287b9a0a321de45c79721ce903281371e2882cabeb0d634077d2c94a56dadb33.png`,
];

export const instructor_general_data = [
  // {
  //   instructor_ID: 1,
  //   store_ID: 1,
  //   instructor_name: "강한별",
  //   instructor_position: "총괄지점장",
  //   instructor_career: [
  //     "동의대학교 체육학과 학사(교육학)",
  //     "국가공인 스포츠 지도사 2급 자격증(보디빌딩)",
  //     "국가공인 중고등 체육 정교사 교원자격증",
  //     "FMS 체형 기능 평가사 수료",
  //     "CES KOREA 교정 운동학 전문가",
  //     "미국 국제 KT TAPE 스포츠 테이핑 자격증",
  //     "KFCA 근막이완 교육 수료",
  //     "체형관리 운동지도자 자격증",
  //     "등 자격 보유 다수",
  //   ],
  //   instructor_awards: [
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "등 다수 입상",
  //   ],
  //   instagram_url: "soleilnoir_han",
  //   instructor_portrait_url: `${backend_url}/public/image_folder/homepage_images/instructor_profile/2ef36734a9b2710f951f4ba826cc1cbd131fa402964e7f5b406a2096bf3a4725b3b500667bd45046337799cd6a22fc188da1b50bd422c1330d31bf8704fdcc4c.jpg`,
  //   instructor_profile_url: `${backend_url}/public/image_folder/homepage_images/instructor_profile/ef5b07151170cac4e4891d7dec45616a3402ddc1756cdd5397a98bbba3cb5873a28d2b8f9684fab86ac97b2f87b610befbbc0b44ebd712e5e0b6189098dfc235.png`,
  // },
  {
    instructor_ID: 2,
    store_ID: 1,
    instructor_name: "박호준",
    instructor_position: "마린시티점 부지점장",
    instructor_career: [
      "부산 카톨릭대학교 물리치료학과 졸업",
      "KFETSA RFM Specialist",
      "국가공인 물리치료사 면허증 보유",
      "국가공인 생활체육 스포츠 지도사 2급 자격증(보디빌딩)",
      "대한 정형 도수 물리치료학회 OMPT 교육 수료",
      "CES-PTC 퍼스널 트레이너 자격과정 이수",
      "한국노인체육평가협회 시니어 피트니스 엑스퍼트 Lv.1 수료",
      "블랙롤 근막이완 국제자격 수료",
      "등 다수 자격 보유",
    ],
    instructor_awards: [
      "22' WNGP 클래식피지크 비기너 1st",
      "22' WNGP 클래식피지크 그랑프리전 2nd",
      "22' WNGP 클래식피지크 오픈 3rd",
      "23' 케이클래식 내추럴 울산 클래식피지크 SHORT - CLASS 체급 1st",
      "23' NPCA 경남 클래식 피지크 SHORT - CLASS 체급 3rd",
      "23' 케이클래식 내추럴 부산 클래식 보디빌딩 SHORT - CLASS 체급3rd",
    ],
    instagram_url: "soleilnoir_jun",
    instructor_portrait_url: `${backend_url}/public/image_folder/homepage_images/instructor_profile/8f13e2c990bc16a47841fb295028b381a4f53ea96dc84003d3c7f26a4f40ec66486bb134097d1e49dc3e53b977fb5efd357ced7199b0442791eae07c4d045cfe.jpeg`,
    instructor_profile_url: `${backend_url}/public/image_folder/homepage_images/instructor_profile/f8be6b380711b938875c1aff73c623c6fd6dff95bd5649f519a1b1fc3d85f4bc2d2a17abcb9dcbcd5fb38b752e29b01864a81732df24b10fb3b3b9762ded79b4.png`,
  },
  {
    instructor_ID: 3,
    store_ID: 1,
    instructor_name: "최준혁",
    instructor_position: "마스터 인스트럭터",
    instructor_career: [
      "부경대학교 식품공학과 졸업",
      "KFETSA 협회 RFM Specialist",
      "KFETSA RFM Assist Instructo",
      "미국 스포츠 의학회 NASM-CPT 국제자격증",
      "프리포먼스 ATPT(선수 트레이너 현장 테크닉) 과정 수료",
      "Inouterfoot 교육 수료",
      "WTS(Weight Training Solution) 교육 수료",
      "Joint mobilization 교육 수료",
      "신경 스트레칭 과정 수료",
    ],
    instructor_awards: [
      "피트니스 스타 스포츠모델 1st",
      "WNC 스포츠모델 2nd",
    ],
    instagram_url: "soleilnoir_hyuk",
    instructor_portrait_url: `${backend_url}/public/image_folder/homepage_images/instructor_profile/fc11e1bfa8a46f1e57571bd4cf04782a54a7df856769734584bc09e670fa46069c0e7eb6a6b057dbd094658dc7a0bdc89de1610fa8dd6b56b3fb00b30cf129d4.jpg`,
    instructor_profile_url: `${backend_url}/public/image_folder/homepage_images/instructor_profile/808c3e92abe5995fb9d965d01de936d586ea4abeceda03d59998115c32f5711994b84b4406f1b7fb91cec284309e18b94442dc30dc086ad43081a01b0078e225.png`,
  },
  {
    instructor_ID: 5,
    store_ID: 1,
    instructor_name: "박석훈",
    instructor_position: "마스터 인스트럭터",
    instructor_career: [
      "신라대학교 경찰행정 졸업",
      "KFETSA RFM Specialist",
      "국가공인 생활스포츠지도사 보디빌딩 2급",
      "FISAF 국제트레이너 자격증",
      "운동손상 처방 교육 이수",
      "노인체육 지도 교육 이수",
      "RFM Basic Course 수료",
      "RFM Spine Course Part 1 수료",
      "Doctor Body 주관 어깨 재활파트 수료",
    ],
    instructor_awards: [
      "22' ICN 부산 스포츠 모델 오픈 3rd",
      "22' ICN 부산 피지크 오픈 2nd",
      "22' NPCA 창원 스포츠 모델 노비스 2nd",
      "22' NPCA 창원 피지크 노비스 2nd",
      "22' NPC 네츄럴 노비스 피지크 2nd",
      "22' NABBA KOREA 스포츠 모델 출전",
      "23' WNGP 피지크 에이지 2nd",
      "23' WNGP 피지크 오픈 4th",
    ],
    instagram_url: "soleilnoir_huun",
    instructor_portrait_url: `${backend_url}/public/image_folder/homepage_images/instructor_profile/30475c6f8911ef11c14c9055a387dcc53ed140a47cd7f3a66e4b2343f76a07f5cf32a41964c7cdf56afadfe27f3d597e576284ff0db960cae9bdf035463fa0cc.jpg`,
    instructor_profile_url: `${backend_url}/public/image_folder/homepage_images/instructor_profile/03f9a4d64fdd57af970db5ddaa5a6725abb1409112e2e629d84d61982104c2837b8b0f465ad5e0bf2c5f98efad0e8c275cf5ab5b0b2db12fc6eac3da02b90b79.png`,
  },
  {
    instructor_ID: 11,
    store_ID: 2,
    instructor_name: "오혜승",
    instructor_position: "해운대점 부지점장",
    instructor_career: [
      "동의대학교 체육학과 학사",
      "국가공인 스포츠지도사2급 자격증(보디빌딩)",
      "CES KOREA 교정 운동전문가 Level 1 수료",
      "미국 국제 KT TAPE 스포츠테이핑 자격증",
      "Heart rate trainer",
      "독일블랙롤트레이너 국제자격증",
    ],
    instructor_awards: [
      "ICN KOREA 클래식피지크 1st",
      "ICN KOREA 보디빌딩 1st",
      "ICN KOREA 클래식보디빌딩2위 등 입상 다수",
    ],
    instagram_url: "soleilnoir_nbo",
    instructor_portrait_url: `${backend_url}/public/image_folder/homepage_images/instructor_profile/df41f514eed7873f1511173a050ad07bfc6060a8c575cb656b65fe18cd65e54312b0b10f6b1b4585120b2e292202ec80cefe9774bc70ebc708fb0b92ddd6b119.jpg`,
    instructor_profile_url: `${backend_url}/public/image_folder/homepage_images/instructor_profile/db12da6f68f2666a463c5850412b092a59cfb60e9d2b7cb46c4950e0a73b4bce066f232708c1cecbede14a1c582f737b9ea19ab90ba4729f9466eb31ae679451.png`,
  },
  {
    instructor_ID: 6,
    store_ID: 2,
    instructor_name: "김기원",
    instructor_position: "마스터 인스트럭터",
    instructor_career: [
      "동의대학교 체육학과 학사",
      "국가공인 생활스포츠지도사 2급 보디빌딩(피트니스)",
      "국가공인 생활스포츠지도사 2급 수영",
      "CES KOREA 교정 운동전문가 Level 1 수료",
      "CES KOREA (Kinesiology Course Completion) 수료",
      "CES KOREA KT TAPE 교정 테이핑 자격 수료",
      "KRLEE 한국노인체육평가협회 시니어 피트니스 지도자 자격증",
      "KFETSA 대한 펑셔널트레이닝전문지도사 협회 Basic Course 수료",
      "MPSW 체형 관리 운동 지도자 (라인핏)",
      "KFCA 근막이완 과정 수료",
    ],
    instructor_awards: [
      "10' G20성공개최기념 듀애슬론대회 주니어 2nd",
      "11' 제 17회 전국 듀애슬론대회 고등부 2nd",
      "22' NPCA 부산 피지크 TOP 7th",
    ],
    instagram_url: "soleilnoir_kiwonboy",
    instructor_portrait_url: `${backend_url}/public/image_folder/homepage_images/instructor_profile/3da26b7e4a67e374d46220ec32b7ad2fec60ecc69f129a0e2bbe26b458a0df9ff2639ca7adfad9338d70e33ea3b2d5204962cbf2d7ff1213a655afb77e93a43d.jpg`,
    instructor_profile_url: `${backend_url}/public/image_folder/homepage_images/instructor_profile/4390a3b28fae24c800571691869f20a926740d3aa74392c357dd3f119c94757500c50a6ae4da2d15665c1645379c2773138bf5462be993a4af524078ccf7a34f.png`,
  },
  {
    instructor_ID: 9,
    store_ID: 2,
    instructor_name: "나수환",
    instructor_position: "마스터 인스트럭터",
    instructor_career: [
      "동명대학교 스포츠재활학과 전공",
      "국가공인 스포츠 지도사 2급 (보디빌딩)",
      "CES-PTC 퍼스널 트레이너",
      "독일 블랙롤 트레이너 국제자격증",
      "Heart Rate Trainer 수료",
    ],
    instructor_awards: [
      "NPCA 모던 스포츠 4th",
      "NPCA 노비스 스포츠모델 4th",
      "PCA 노비스 스포츠모델 5th",
    ],
    instagram_url: "soleilnoir_uk",
    instructor_portrait_url: `${backend_url}/public/image_folder/homepage_images/instructor_profile/2842b5f5083787517be7c3ea18effa446a0e1e278eef3aa2b27727216b79c338ecc817f8d4c117bd19b8e625336e3ee0f6fb02b9a7e6eadd40a51bd85d0625e7.jpg`,
    instructor_profile_url: `${backend_url}/public/image_folder/homepage_images/instructor_profile/c5027e161c2715c03f933737c72d384d52c44fc202a608afc76ff6cfdcd5a560ae4ae2b87aaf1a79648bf6398f72adc1ff5f00a1099e3aefb1d7d36c3bd25aa1.png`,
  },
  {
    instructor_ID: 7,
    store_ID: 2,
    instructor_name: "김민욱",
    instructor_position: "마스터 인스트럭터",
    instructor_career: [
      "CES-PTC 퍼스널트레이너 자격증",
      "KFCA 대한교정운동전문가 자격증",
      "KFETSA RFM Basic 수료",
      "독일 블렉롤 트레이너 국제자격증 수료",
      "Heart Rate Trainer 수료",
    ],
    instructor_awards: [
      "22' PCA 부산 피지크 주니어 1st",
      "22' PCA 부산 피지크 노비스 3rd",
      "22' WNGP 부산 피지크 루키 2nd",
      "22' WNGP 부산 피지크 오픈 3rd",
      "22' WNGP 부산 피지크 노비스 3rd",
      "23' 나바코리아 ACB 주니어 2nd",
      "23' 케이클래식 내추럴 부산 클래식 피지크 그랑프리",
      "23' 케이클래식 내추럴 부산 클래식 보디빌딩 주니어 2nd",
      "23' 케이클래식 내추럴 울산 보디빌딩 주니어 1st",
      "23' 케이클래식 내추럴 울산 클래식 피지크 1st",
    ],
    instagram_url: "soleilnoir_uk",
    instructor_portrait_url: `${backend_url}/public/image_folder/homepage_images/instructor_profile/277bae18e058edd69e16311380680f8d8be2ff82f2d172dd2e87aec12999f9a7e3f72180d38a7e061ed44be1c471efac56a9f3bffd2be04423b9ffeededa7fcd.jpg`,
    instructor_profile_url: `${backend_url}/public/image_folder/homepage_images/instructor_profile/b55191d4059f45603211eb487fcf088c27a9c30eb13b5816cffcb7c392f8ae0b9278154f8a5db62a47f1dd5506aee20da17879990e1aab850553af05119cbd26.png`,
  },
];

export const profile_studio_image_url = [
  `${backend_url}/public/image_folder/homepage_images/profile_studio_images/2a607a08fd192190ede9fecbdc1c40016dce91c75644c18739ccdeab5f7deee841744f48e34f917429f52c560b4749fc200acb15694eb94b44d4b9ca04702324.jpg`,
  `${backend_url}/public/image_folder/homepage_images/profile_studio_images/1f7f215f5fec4ea74e6d4c26f895faa5cdd5b890f55f9fe219708ac28422396bfb0cc16b6f692d5adcd1898b92e3af7071bc46bc20401350e9959566f242b99b.jpg`,
  `${backend_url}/public/image_folder/homepage_images/profile_studio_images/0b399722beda860f11932944ba235967456bde98e74a262e631426b479d6f30f6fa2143191535937f20d068fdba4272b9e273b92ebf749668a1e64b5c0c40167.jpg`,
  `${backend_url}/public/image_folder/homepage_images/profile_studio_images/a672a0c2d8a595bfefd5155be38cfd6b4bc1797bfa8cced1649edd18c27bf65caecccf9885f31ee1ff2debe95c7d86734b0ee54d2442768221186b1fc71ba46c.jpeg`,
];

export const kitchen_feature_list = [
  "전문 영양사가 구성 및 제조",
  "매일 다른 샐러드 식단 제공",
  "24가지 모든 드레싱 수제 제조",
  "당일 제조, 당일 배송 신선함 유지",
  "부산 전지역 집으로 새벽 배송",
];

export const ptPriceData = [        
{
  title: "The Earth",
  subTitle: '블루 레벨 피지컬 및 재활 프로그램',
  price: 700000,
  url: '8c6e9d7dd9b24adf8b0a512b1616f865aaf604678ed44bedb4b6f7fcfff9116390491da0842e091bdfe5b89bf0682b1094e506d7f3c96fffea941dbe39fce6fc',
  background_color: 'rgba(36,100,131,0.5)',
  badge_color: '#6e8eab',
},
{
  title: 'The Moon',
  subTitle: '골드 레벨 피지컬 및 재활 프로그램',
  price: 800000,
  url: '6ab10af8ba889a65796d58fce59dc5f02ea78744255ccee70e555ff18932a876fa1f02bd81478224af8540e752cc5deb83d5d445c198d04065d6d05be8b4f4e7',
  background_color: 'rgba(36,100,131,0.5)',
  badge_color: '#6e8eab',
},
{
  title: 'The Sun',
  subTitle: '최고 수준인 블랙 레벨 피지컬 및 재활 프로그램',
  price: 900000,
  url: '4a1867e137617eae12bbd38ca661f6b79053f1feb6e4148956e66afb4d873e583b593023c05ac37ed972efcd23398fba226abd14dfc69685fc98531ec45ee86c',
  background_color: 'rgba(36,100,131,0.5)',
  badge_color: '#6e8eab',
},
];

export const youtubeURL = [
  {
    thumb_nail: 'ca6aa18399761cc72f253339f845d3563192120dc8eec92b3062536230d61e21c1448a7ec8d676a95b026ee51170979fd638c0d61c5d9ca3d631ce4242f7de77',
    url: `YWXNxuD0wII`,
  },
  {
    thumb_nail: '260b94d61529df91a823fcd695e1797b110588cd18fa596ea3bb81ca2234f64e4085701d4fe6dca704e1cad3c396dc93ef6fcf453713ce9d251aba72b36c5afe',
    url: `6o36CDlT1dA`,
  },
  {
    thumb_nail: '982ac741e134554d92bf3e8762debc59058df535d3b212fe27ab3cc9077ab218c35deebeff30e3f6d9a51af6bcb7dbd618876f5908fed3d46ea343552f6f4fea',
    url: `CnVF9gimQsI`,
  },
  {
    thumb_nail: 'f95f7ac9b1939fae054c948926774588555acba25fab9fdad33a569ad64b389c1f5adbd0cc9e35ed8a2c1b2fcf01621fa239f0ed95a150522710122c3a8b891b',
    url: `UWi16klN6Ss`,
  },
  {
    thumb_nail: '1070b812c0fa5d091bf7046a959e18cab54ed8f9080ead29a3e7ad74696885ba39ddafb4e40bbf7feb5e2f7173270adb0d20515f8be31cdfdefaa03cecca14ad',
    url: `A8LWBYvtTaQ`,
  },
  {
    thumb_nail: 'f5448bc138f65f1c16ee0c6fb5b936e4c2df00fd256c48e535744182ea14e3f03a071ccb39667efa0e326a9d736d7c44271572fef2a7b7da1b0ceaceeaba49dd',
    url: `XsjwMwq0BMw`,
  },
];

export const place = [
  {
    image: '1aa38c0608469a6ac2e3cbc390b1481ad768e359290fc6a20e7dd3817d42168ad328ee60a05c8689109d3597ad920a03c42c5724a6a50836a8253eff8604b3a1',
    desc : 'Personal Locker',
  },
  {
    image: 'd5a957adecf310c791c8bfef078461f6c23dec7e0f12cfb682b344bb062966d9aeca964db12c1e4e745a68e4660391458e967d19709a296a70d7c15e9feeb702',
    desc : 'Inside The Studio',
  },
  {
    image: 'e22028ed909a8d646e612e4257887cd3c6040e8a83927acb68b115c0c805488f01b68bca0fd5b76fb27be3bfa9d9a5990f5d08fc6e4d01a7d9fd1978bfd02159',
    desc : 'Inside The Studio',
  },
  {
    image: '9767dad9531eea9f3ac38038088419fd3577b2a3bac6ef7b7a86ca30e7eb297ff3f4a8b4d9eb26e3138acc4199505a42575e8a2b4eab35aeef204405224838ef',
    desc : '1:1 PT Room',
  },
  {
    image: 'b3f8f7d6d46d1b0aa3086fbb00151baabc766889156c558c11450cf73b2e33384c67de939f86d2082434a0bec8b847f968fb75ff592781f8eac97f8034127a0e',
    desc : 'Private Shower Room',
  },
  {
    image: 'ed9cb0828e08574e84971dfcbfac8e03bb2f6b5d39080b37b4933f4a33d0ce0f3862b557cd750423cd15459ac8cc772e3c09339e5f5ce8154ad5dff6c66e6479',
    desc : 'Private Shower Room',
  },
];

export const appFunction = [
  {
    image: '290692d394e2d678b19108b82a0cbd3854e8b3a23e44bd77ff42a02f4f983e79c82ea5b19af840a8d9a41603ab14207ad1252593da71b281b6a513b5484f3483',
    title1: '언제 어디서나',
    title2: '쉽고 빠르게 수업 예약',
    desc1: '바쁘다는 이유로 시간이 남으면',
    desc2: '운동하거나,',
    desc3: '빼먹지는 않으셨나요?',
    desc4: '솔레노어 앱에서는',
    desc5: '원하는 시간에 PT를 예약하고',
    desc6: '일정을 변경할 수 있습니다.'
  },
  {
    image: '8c9a6634950ec1851f13ca2b4de99d519daaf282c8cbadc6372852d8188e5c2ffe0ffc281a05a895d81444e4c1427539e4425668bd4cd6e6a63aa978d18cc4d3',
    title1: '바디 데이터 트레킹',
    title2: '체계적인 건강 관리',
    desc1: '솔레노어 앱으로',
    desc2: '체계적인 바디데이터를 관리하여',
    desc3: '눈에 보이는 변화를 경험하세요.',
    desc4: '인스트럭터의 맞춤형 솔루션으로',
    desc5: '목표에 더 빠르고 확실하게',
    desc6: '도달할 수 있습니다.'
  },
  {
    image: 'f7cbaace802f08a30ee347264c052085908a85b1bcdeba57ce395f688441404cb385f6b2160160f91ae71935b89db632dd805a443af344ecef4e51ed3a6468ec',
    title1: '식단 기록과 실시간 피드백',
    title2: '나만의 건강한 습관',
    desc1: '솔레노어 앱은',
    desc2: '섭취한 칼로리를 자동 계산해',
    desc3: '목표에 맞춘 식단을 지원하며',
    desc4: '식단 등록시',
    desc5: '인스트럭터 피드백도',
    desc6: '제공받을 수 있습니다.'
  },
  {
    image: '46f18880e3876ec9c4274f9268ca30d54517ab38dddcdab02b527f61e5161060275fee8cd5beeeb67a288727c92a960ad157be55271e9c40212fb49c4cfb0a58',
    title1: '개인 번호 없이',
    title2: '소통할 수 있는 채팅 기능',
    desc1: '프라이버시를 지킬 수 있는',
    desc2: '채팅 기능을 이용하여',
    desc3: '인스트럭터와 소통하세요.',
    desc4: '전문적인 조언과 피드백으로',
    desc5: '목표에 한층 더 가까이',
    desc6: '다가갈 수 있습니다.'
  },
];