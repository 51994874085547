import React from "react";
import * as SCM from "../../style/StyleComponentMenu";
import { ScrollAnimationContainer } from "../util/ScrollAnimationContainer";

interface Props {
  mobileSize : boolean;
};

const SubSlogan: React.FC<Props> = ({mobileSize}) => {

  return (
    <>
      <SCM.SubSloganContainer>
        <div className="flex w-[100%] sm:block">
          <ScrollAnimationContainer
            direction={`${mobileSize ? "B" : "R"}`}
            duration="1.5s"
            delay="0"
            threshold={0.5}
            onlyOnce
          >
            <div className="main-text">
            <div>
              <h5>더 이상 운동이 아니다.</h5>
              <h5>
                이것은&nbsp;
                <span className="text-pointGold">
                  건강이자 삶
                </span>
                이다.
              </h5>
            </div>
          </div>
          </ScrollAnimationContainer>
          <ScrollAnimationContainer
            direction={`${mobileSize ? "T" : "L"}`}
            duration="1.5s"
            delay="0"
            threshold={0.5}
            onlyOnce
          >
            <div className="main-text">
              <div>
                <h5>
                  남들과 다른 당신에게 맞는,
                </h5>
                <h5>
                  당신의&nbsp;
                  <span className="text-pointGold">
                    건강과 삶의 완벽한 균형
                  </span>
                </h5>
              </div>
            </div>
          </ScrollAnimationContainer>
        </div>
      </SCM.SubSloganContainer>
    </>
  )
};

export default SubSlogan;