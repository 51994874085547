import styled from "styled-components";
import theme from "./theme";

export const IntroInstructorContainer = styled.div`
  position: relative;
  overflow: hidden;
  z-index: -1;

  & .intructor-title-box {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;

    & .eng {
      position: relative;
      width: 0;
      overflow: hidden;
      text-align: center;
      animation: typeEng 1s steps(21) forwards;
      animation-delay: 4s;

      @keyframes typeEng {
        0% {
          width: 0;
        }
        100% {
          width: 400px;
        }
      }

      @media ${theme.screen.sm} {
        animation: typeEng 1s steps(21) forwards;
        animation-delay: 4s;

        @keyframes typeEng {
          0% {
            width: 0;
          }
          100% {
            width: 236px;
          }
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 4px;
        height: 90%;
        background-color: white;
        opacity: 0;
        animation: blinkCursor 0.8s steps(3) infinite;
        animation-delay: 4s;

        @media ${theme.screen.sm} {
          width: 2px;
          transform: translateY(9px);
        }

        @keyframes blinkCursor {
          0%, 75% {
            opacity: 1;
          }
          76%, 100% {
            opacity: 0;
          }
        }
      }

      & span {
        white-space: nowrap;
      }

      & .eng-text {
        font-size: 54px;
        font-weight: 800;

        @media ${theme.screen.sm} {
          font-size: 32px;
        }
      }
  
      & .eng-phonetic {
        font-size: 48px;
        font-weight: 500;

        @media ${theme.screen.sm} {
          font-size: 28px;
        }
      }
    }

    & .kor {
      position: absolute;
      margin-top: 10px;
      top: 100%;
      height: 0;
      font-size: 32px;
      font-weight: 300;
      text-align: center;
      overflow: hidden;
      animation: typeKor 0.8s forwards;
      animation-delay: 5.5s;

      @media screen {
       font-size: 18px;
      }

      @keyframes typeKor {
        0% {
          height: 0;
        }
        100% {
          height: 45px;
        }
      }

      & span {
        white-space: nowrap;
      }
    }
  }

  & .album-container {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 100%;
    height: 100vh;
    transition: all 2.5s;
    transition-timing-function: cubic-bezier(.75,0,.25,1);
    overflow: hidden;

    @media ${theme.screen.md} {
      padding-top: 350px;
    }

    @media ${theme.screen.sm} {
      padding-top: 150px;
    }
  
    & .center-image-list {
      width: 100%;
  
      & .center-image {
        width: 300px;
        height: 200px;
        margin: 20px 0;

        @media ${theme.screen.md} {
          width: 150px;
          height: 100px;
        }

        @media ${theme.screen.sm} {
          width: 70px;
          height: 100px;
        }
  
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          /* object-position: 50% 20%; */
        }
      }
  
      & :nth-child(1) {
        margin: 0;
        transform: translateY(-4vh);
        animation: firstSlideUp 3s ease-in;
        animation-timing-function: cubic-bezier(.75,0,.25,1);
  
        @keyframes firstSlideUp {
          0% {
            transform: translateY(50vh);
          }
          100% {
            transform: translateY(-4vh);
          }
        }
      }
  
      & :nth-child(2) {
        transform: translateY(50vh);
        animation: secondSlideUp 1.25s forwards;
        animation-delay: 1.45s;
        animation-timing-function: cubic-bezier(.75,0,.25,1);
  
        @keyframes secondSlideUp {
          0% {
            transform: translateY(50vh);
          }
          100% {
            transform: translateY(-4vh);
          }
        }
      }
  
      & :nth-child(3) {
        transform: translateY(50vh);
        animation: mainSlideUp 1.25s forwards;
        animation-delay: 1.7s;
        animation-timing-function: cubic-bezier(.75,0,.25,1);
  
        @keyframes mainSlideUp {
          0% {
            transform: scale(1) translateY(50vh);
          }
          100% {
            transform: scale(1) translateY(-4vh);
          }
        }
      }
  
      & :nth-child(4) {
        transform: translateY(50vh);
        animation: fourthSlideUp 1.25s forwards;
        animation-delay: 1.95s;
        animation-timing-function: cubic-bezier(.75,0,.25,1);
  
        @keyframes fourthSlideUp {
          0% {
            transform: translateY(50vh);
          }
          100% {
            transform: translateY(-4vh);
          }
        }
      }
  
      & :nth-child(5) {
        transform: translateY(50vh);
        animation: fourthSlideUp 1.25s forwards;
        animation-delay: 2.15s;
        animation-timing-function: cubic-bezier(.75,0,.25,1);
  
        @keyframes fourthSlideUp {
          0% {
            transform: translateY(50vh);
          }
          100% {
            transform: translateY(-4vh);
          }
        }
      }
    }
  
    & .side-image-list {
      position: relative;
      width: 100%;
      
      & .side-image {
        position: relative;
        width: 300px;
        height: 200px;
        margin: 20px 0;
        /* transform: translateY(-110vh); */

        @media ${theme.screen.md} {
          width: 150px;
          height: 100px;
        }

        @media ${theme.screen.sm} {
          width: 70px;
          height: 100px;
        }
  
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          /* object-position: 50% 10%; */
        }
      }
  
      & :nth-child(5) {
        transform: translateY(-22.5vh);
        animation: firstSlideDown 3s ease-in;
        animation-timing-function: cubic-bezier(.75,0,.25,1);
  
        @keyframes firstSlideDown {
          0% {
            transform: translateY(-150vh);
          }
          100% {
            transform: translateY(-22.5vh);
          }
        }
      }
  
      & :nth-child(4) {
        transform: translateY(-150vh);
        animation: secondSlideDown 1.25s forwards;
        animation-delay: 1.45s;
        animation-timing-function: cubic-bezier(.75,0,.25,1);
  
        @keyframes secondSlideDown {
          0% {
            transform: translateY(-150vh);
          }
          100% {
            transform: translateY(-22.5vh);
          }
        }
      }
  
      & :nth-child(3) {
        transform: translateY(-150vh);
        animation: ThirdSlideDown 1.25s forwards;
        animation-delay: 1.7s;
        animation-timing-function: cubic-bezier(.75,0,.25,1);
  
        @keyframes ThirdSlideDown {
          0% {
            transform: translateY(-150vh);
          }
          100% {
            transform: translateY(-22.5vh);
          }
        }
      }
  
      & :nth-child(2) {
        transform: translateY(-150vh);
        animation: fourthSlideDown 1.25s forwards;
        animation-delay: 1.95s;
        animation-timing-function: cubic-bezier(.75,0,.25,1);
  
        @keyframes fourthSlideDown {
          0% {
            transform: translateY(-150vh);
          }
          100% {
            transform: translateY(-22.5vh);
          }
        }
      }
    }
  }
`;

export const ChiefManagerContainer = styled.div`
  width: 100%;
  height: 100vh;
  @media ${theme.screen.md} {
    width: 100%;
    height: auto;
    padding: 20vh 0 5vh;
    }
  & .container {
    margin: 0 auto;
    width: 80%;
    max-width: 1200px;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    @media ${theme.screen.md} {
      width: 100%;
      height: 100%;
    }
    & .text-box {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media ${theme.screen.md} {
        width: 90%;
        height: 95%;
        margin: 0 auto;
        padding-bottom: 50px;
        justify-content: flex-end;
      }
      & .title {
        font-size: 1.7em;
        color: ${theme.color.pointGold};
        line-height: 1.3em;
        margin: 30px 0 10px;
        @media ${theme.screen.md} {
          font-size: 1.5em;
        }
      }
      & .name {
        font-size: 3em;
      }
      & .rank {
        margin-top: 10px;
        font-size: 1.5em;
      }
      & .desc {
        line-height: 25px;
        font-weight: lighter;
        margin-block: 10px;
        @media ${theme.screen.md} {
          font-size: 16px;
          line-height: 25px;
        }
        & b {
          font-weight:  bold;
        }
      }
    }
    & .picture {
      width: 50%;
      min-width: 450px;
      object-fit: cover;
      @media ${theme.screen.md} {
        width: 430px;
        min-width: initial;
        position: absolute;
        bottom: 0;
        right: -100px;
        z-index: -3;
        filter: brightness(70%);
      }

    }
    & .slogan {
      width: 60%;
      position:absolute;
      bottom: 15%;
      left: 15%;
      z-index: -2;
      opacity: 80%;
      @media ${theme.screen.md} {
        transform: scale(1.5);
        left: -20px;
        bottom: 0;
      }
      & p {
        font-size: 3vw;
        font-weight: lighter;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        color: ${theme.color.OpacityWhite};
        @media ${theme.screen.md} {
          font-size: 4vw;
        }
      }
      & .slogan-img {
        width: 100%;
        height: auto;
      }
    }
  }

`;

export const InstructorLineupContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`;

export const IndividualContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  height: 80vh;
  background-color: #000;
  margin-top: 80px;

  @media ${theme.screen.md} {
    justify-content: left;
  }
  @media ${theme.screen.sm} {
    width: 95%;
    justify-content: center;
  }

  & .selector-container {
    position: relative;
    width: 50%;
    height: 100%;

    @media ${theme.screen.lg} {
      width: 60%;
    }
    @media ${theme.screen.md} {
      width: 80%;
    }
    @media ${theme.screen.sm} {
      width: 100%;
    }

    & .club-box {
      position: absolute;
      display: flex;
      align-items: center;
      padding-left: 2px;
      top: 0;
      width: 80%;
      height: 60px;
      border-top: 12px solid #000;
      background-color: #fff;
      clip-path: polygon(0% 0%, 100% 0%, 92% 100%, 0% 100%);
      z-index: 20;

      & .club-tab-item {
        width: 120px;
        text-align: center;
        line-height: 44px;
        outline: 2px solid #fff;
        clip-path: polygon(0 0, 92% 0, 100% 100%, 8% 100%);

        &:nth-child(1) {
          clip-path: polygon(0 0, 92% 0, 100% 100%, 0% 100%);
        }

        &:hover {
          cursor: pointer;
        }
      }

    }

    & .instructor-list-box {
      position: absolute;
      padding: 0 4%;
      bottom: 0;
      display: flex;
      transition: all 0.5s;
      z-index: 10;

      @media ${theme.screen.sm} {

      }

      & .instructor-list-item {
        position: relative;
        width: 120px;
        clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);

        @media ${theme.screen.sm} {
          width: initial;
        }

        &:before {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(to bottom, transparent, #000);
        }

        &:hover {
          cursor: pointer;
          filter: grayscale(0);
        }
      }

      & .list-animation-0 {
        opacity: 0;

        &:nth-child(odd) {
          animation: listSlideIn0O 0.4s forwards;
          animation-timing-function: ease-out;
          
          @keyframes listSlideIn0O {
            0% {
              opacity: 0;
              transform: translateY(50%);
            }
            100% {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
        &:nth-child(even) {
          animation: listSlideIn0E 0.4s forwards;
          animation-timing-function: ease-out;
          
          @keyframes listSlideIn0E {
            0% {
              opacity: 0;
              transform: translateY(-50%);
            }
            100% {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
      }
      & .list-animation-1 {
        opacity: 0;

        &:nth-child(even) {
          animation: listSlideIn1O 0.4s forwards;
          animation-timing-function: ease-out;
          
          @keyframes listSlideIn1O {
            0% {
              opacity: 0;
              transform: translateY(50%);
            }
            100% {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
        &:nth-child(odd) {
          animation: listSlideIn1E 0.4s forwards;
          animation-timing-function: ease-out;
          
          @keyframes listSlideIn1E {
            0% {
              opacity: 0;
              transform: translateY(-50%);
            }
            100% {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
      }
    }
  }

  & .description-container {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;

    @media ${theme.screen.sm} {
      justify-content: end;
    }

    & .instructor-wrapper {
      position: relative;
      /* display: flex;
      justify-content: space-between;
      align-items: center; */
      width: 80%;
      height: 100%;

      @media ${theme.screen.sm} {
        width: 90%;
      }

      & .text-box {
        position: relative;
        top: 10%;
        left: 20%;
        width: 80%;
        height: 100%;
        /* transform: translateX(-50%); */

        @media ${theme.screen.sm} {
          left: 0;
        }

        & .name-box {
          position: relative;
          margin-top: 20px;
          font-size: 92px;
          font-weight: 800;
          letter-spacing: 0.2em;
          color: transparent;
          -webkit-text-stroke: 1px #fff;
          white-space: nowrap;
          z-index: 1;

          @media ${theme.screen.sm} {
            /* font-size: 72px; */
          }
        }

        & .description-box {
          position: relative;
          margin-top: 10px;
          z-index: 20;

          @media ${theme.screen.sm} {
            z-index: 0;
            visibility: hidden;
          }

          & .position-box {
            font-size: 32px;
            font-weight: 300;
          }

          & .instagram_box {
            margin-top: 10px;
            display: flex;
            align-items: baseline;
            gap: 8px;

            & img {
              width: 16px;
              height: 16px;
            }

            & span {
              font-size: 18px;
              line-height: 18px;
            }
          }

          & .career-box, .awards-box {
            position: relative;
            font-size: 14px;
            line-height: 22px;
          }

          & .tag-name {
            position: relative;
            display: flex;
            gap: 20px;
            margin: 25px 0 15px;
            z-index: 20;

            & button {
              position: relative;
              border: 1px solid ${theme.color.subGray01};
              color: ${theme.color.subGray01};
              width: 70px;
              height: 35px;
              font-size: 16px;
              line-height: 35px;
              font-weight: 300;

              &.active {
                border-color: ${theme.color.mainWhite};
                color: ${theme.color.mainWhite};
                font-weight: 500;
              }
            }
          }
        }
      }

      & .image-box {
        position: absolute;
        display: flex;
        justify-content: end;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 2;

        &:before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(to bottom, transparent, transparent, #000);
        }

        @media ${theme.screen.sm} {
          /* z-index: 0; */
        }

        & img {
          padding-top: 80px;
        }
      }
    }

    & .appear {
      
      & .name-box {
        animation: nameAppear 1.5s forwards;

        @keyframes nameAppear {
          0% {
            transform: translateX(-100px);
            opacity: 0;
          }
          100% {
            transform: translateX(0);
            opacity: 1;
          }
        }
      }

      & .description-box {
        opacity: 0;
        animation: descriptionAppear 1s forwards;
        animation-delay: 0.5s;

        @keyframes descriptionAppear {
          0% {
            transform: translateX(-50px);
            opacity: 0;
          }
          100% {
            transform: translateX(0);
            opacity: 1;
          }
        }
      }

      & .image-box {
        animation: imageAppear 1.5s forwards;

        @keyframes imageAppear {
          0% {
            transform: translateX(100px);
            opacity: 0;
          }
          100% {
            transform: translateX(0);
            opacity: 1;
          }
        }
      }
    }

    & .disappear {
      
      & .name-box {
        animation: nameDisappear 0.5s forwards;

        @keyframes nameDisappear {
          0% {
            transform: translateX(0);
            opacity: 1;
          }
          100% {
            transform: translateX(-100px);
            opacity: 0;
          }
        }
      }

      & .description-box {
        animation: descriptionDisappear 0.5s forwards;

        @keyframes descriptionDisappear {
          0% {
            transform: translateX(0);
            opacity: 1;
          }
          100% {
            transform: translateX(-100px);
            opacity: 0;
          }
        }
      }

      & .image-box {
        animation: imageDisappear 0.5s forwards;

        @keyframes imageDisappear {
          0% {
            transform: translateX(0);
            opacity: 1;
          }
          100% {
            transform: translateX(100px);
            opacity: 0;
          }
        }
      }
    }
  }
`;