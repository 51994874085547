import React from 'react'
import MembershipRank from './MembershipRank'
import PtJourney from '../brand/PtJourney'

function Membership() {
  return (
    <div>
      <MembershipRank />
      <PtJourney />
    </div>
  )
}

export default Membership