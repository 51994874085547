import React from "react";
import * as SCM from "../../style/StyleComponentMenu";
import { backend_url, screen_width_data } from "../../module/DevInformation";
import Plx from "react-plx";
import mouse_icon from "../../assets/icon_images/mouse.svg";
import arrow_down_two_icon from "../../assets/icon_images/arrow_down_two.svg";
import { useEffect } from "react";
import { useState } from "react";

const MainParallax: React.FC = () => {
  const [mobileSize, setMobileSize] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("resize", handleReSize);
    handleReSize();

    return () => {
      window.removeEventListener("resize", handleReSize);
    }
  }, []);

  const handleReSize = () => {
    if (screen_width_data.sm >= window.innerWidth) {
      setMobileSize(true);
    } else {
      setMobileSize(false);
    }
  };

  // useEffect(() => {
  //   if (mobileSize) {
  //     mainVideoParallaxData[0].properties.push(mobileVideoTranslateY);
  //   } else {
  //     mainVideoParallaxData[0].properties.push(videoHeight);
  //     mainVideoParallaxData[0].properties.push(videoScaleX);
  //     mainVideoParallaxData[0].properties.push(videoScaleY);
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [mobileSize]);

  // const mobileVideoTranslateY = {
  //   startValue: 1,
  //   endValue: 0,
  //   property: "opacity",
  // }
  // const videoHeight = {
  //   startValue: 100,
  //   endValue: 0,
  //   unit: "vh",
  //   property: "height",
  // };
  // const videoScaleX = {
  //   startValue: 1,
  //   endValue: 2,
  //   property: "scaleX",
  // };
  // const videoScaleY = {
  //   startValue: 1,
  //   endValue: 2,
  //   property: "scaleY",
  // };

  // const mainVideoParallaxData: any = [
  //   {
  //     start: "50vh",
  //     duration: "110vh",
  //     properties: [
  //       mobileVideoTranslateY
  //     ],
  //   },
  // ];

  return (
    <>
      <SCM.ParallaxContainer
        // ref={parallaxRef}
      >
        <Plx
          className="active-parallax"
          parallaxData={[
            {
              start: "50vh",
              duration: "100vh",
              properties: [
                {
                  startValue: 0,
                  endValue: -100,
                  unit: "vh",
                  property: "translateY"
                }
              ]
            }
          ]}
        >
          <video muted autoPlay playsInline loop>
            <source
              src={`${backend_url}/public/image_folder/homepage_images/cd88d5ee9eb79e8ff2ab4d79b9681de001e23f0f7910345a0849dd5ebd9f935fba5176f3b87689ce228ae1ee2e93541b6c1315a2a7c90de0bc607753bb1c716c.mp4`}
              type="video/mp4"
            />
          </video>
        </Plx>
        <Plx
          className="main-slogan"
          parallaxData={[
            {
              start: "0vh",
              duration: "50vh",
              properties: [
                {
                  startValue: 100,
                  endValue: 0,
                  unit: "vh",
                  property: "translateY",
                }
              ]
            },
            {
              start: "50vh",
              duration: "50vh",
              properties: [
                {
                  startValue: 1,
                  endValue: 0,
                  property: "opacity",
                },
                {
                  startValue: 1,
                  endValue: 2,
                  property: "scale"
                }
              ]
            }
          ]}
        >
          <div>
            <div className="last-slogan eng">
              <div className="font-CormorantGaramond">
                <h1>Perfect Balance</h1>
                <h1>Between Health And Life</h1>
              </div>
            </div>
            <div className="last-slogan kor">
              <div>
                  <div className="font-CormorantGaramond">
                    <h3>[건강과 삶의 완벽한 균형_솔레노어]</h3>
                  </div>
              </div>
            </div>
          </div>
        </Plx>
        {mobileSize || (
          <Plx
            className="mouse-icon"
            parallaxData={[
              {
                start: "0vh",
                duration: "120vh",
                properties: [
                  {
                    startValue: 1,
                    endValue: 0,
                    property: "opacity",
                  },
                  {
                    startValue: 0,
                    endValue: 5,
                    unit: "%",
                    property: "translateY"
                  }
                ]
              }
            ]}
          >
            <div>
              <div>
                <img src={mouse_icon} alt="" width={40} />
              </div>
              <div className="flex justify-center mt-2 animate-bounce">
                <img src={arrow_down_two_icon} alt="" width={26} />
              </div>
            </div>
          </Plx>
        )}
      </SCM.ParallaxContainer>
    </>
  )
};

export default MainParallax;