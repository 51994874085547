const isProduction = process.env.NODE_ENV === 'production';

export const backend_url: string = isProduction
    ? 'https://www.soleilnoir.kr:6060'
    : 'http://localhost:6060';

export const screen_width_data = {
  sm: 613,
  md: 860,
  lg: 1280,
};