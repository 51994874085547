import React from "react";
// import Identity from "./Identity";
import { useRef } from "react";
import Gallery from "./Gallery";
import Intro from "../layout/Intro";
import CoreValueTwo from "./CoreValueTwo";
import SubSlogan from "./SubSlogan";
import { useState } from "react";
import { useEffect } from "react";
import Instructor from "../instructor/Instructor";
import Place from "./Place";
import Application from "../application/Application";
import Membership from "../membership/Membership";
import SubBrand from "../sub_brand/SubBrand";
import AppPoint from "../application/AppPoint";
import FranchiseNavi from "./FranchiseNavi";
import HeaderBrand from "./HeaderBrand";

const Brand: React.FC = () => {
  const [mobileSize, setMobileSize] = useState<boolean>(false);
  const chapterRef = useRef<any>([]);

  const handleChapter = (chapter_index: number) => {
    if (chapterRef.current) {
      chapterRef.current[chapter_index].scrollIntoView({ behavior: 'smooth'});
    }
  };


  useEffect(() => {
    const handleResize = () => {
      setMobileSize(window.innerWidth <= 613);
    };
    const debounce = (func: { (): void; apply?: any; }, delay: number | undefined) => {
      let timer: string | number | NodeJS.Timeout | undefined;
      return (...args: any) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    };
  
    handleResize();
  
    const debouncedResize = debounce(handleResize, 200);
    window.addEventListener("resize", debouncedResize);
  
    return () => {
      window.removeEventListener("resize", debouncedResize);
    };
  }, []);
  
  return (
    <div className="overflow-x-hidden">
      <HeaderBrand handleChapter={handleChapter} mobileSize={mobileSize} />
      <div
        ref={(el) => {chapterRef.current[0] = el;}}
      >
        <Intro />
      </div>
      <SubSlogan mobileSize={mobileSize} />
      <CoreValueTwo />
      <div
        ref={(el) => {chapterRef.current[1] = el;}}
      >
        <Instructor />
      </div>
      <div
        ref={(el) => {chapterRef.current[2] = el;}}
      >
        <Place />
      </div>
      <div
        ref={(el) => {chapterRef.current[3] = el;}}
      >
        <Membership />
      </div>
      <Application />
      <div
        ref={(el) => {chapterRef.current[4] = el;}}
      >
        <SubBrand />
      </div>
      <AppPoint />
      <Gallery />
      <FranchiseNavi />
    </div>
  )
};

export default Brand;