import styled from "styled-components";
import theme from "./theme";
import { backend_url } from "../module/DevInformation";

export const MainPageContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  & .line {
    width: 100%;
    height: 1px;
    background-color: ${theme.color.mainWhite};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: -1;
    @media ${theme.screen.sm} {
      width: 1px;
      height: 100%;
    }
  }
  & .main-container {
    margin: 0 auto;
    width: 100%;
    display: flex;
    position: relative;
    @media ${theme.screen.sm} {
      flex-direction: column;
      height: 100vh;
    }
    & .section {
      width: 50%;
      height:100vh;
      display: grid;
      align-content: center;
      @media ${theme.screen.sm} {
        width: 100%;
        height: 50%;
      }
      & .circle {
        margin: 0 auto;
        width: 35vw;
        height: 35vw;
        border-radius: 50%;
        box-shadow: 0px 0px 50px ${theme.color.mainWhite};
        transition: 0.3s;
        background-color: ${theme.color.realBlack};
        @media ${theme.screen.sm} {
          width: 75vw;
          height: 75vw;
        }
        & h2 {
          font-size: 2vw;
          text-align: center;
          line-height: 35vw;
          @media ${theme.screen.sm} {
            font-size: 8vw;
          }
        }
      }
      & .brand-circle, .franchise-circle {
        @media ${theme.screen.sm} {
            width: 65vw;
            height: 65vw;
        }
      }
      & .bottom-text {
        text-align:center;
        font-size: 2vw;
        margin-top:30px;
      }
      :hover {
        transition: 0.3s;
        &.circle:not(.center-circle) {
          transform: scale(1.3);
        }
      }
    }

    & .brand-section {
      & .brand-circle {
        box-shadow: -15px 0px 50px ${theme.color.SunColor};
        & h2 {
          color:${theme.color.mainWhite};
          @media ${theme.screen.sm} {
            line-height: 65vw;
          }
        }
      }
      & .bottom-text {
        color:${theme.color.SunColor};
      }
    }
    & .franchise-section {
      & .franchise-circle {
        box-shadow: 15px 0px 50px ${theme.color.MoonColor};
        
        & h2 {
          color:${theme.color.mainWhite};
          @media ${theme.screen.sm} {
            line-height: 75vw;
          }
        }
      }
      & .bottom-text {
        color:${theme.color.MoonColor};
        @media ${theme.screen.sm} {
            display: none;
          }
      }
    }
    & .center-section {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      & .center-circle {
        padding-top: 5vh;
        background: url(${backend_url}/public/image_folder/homepage_images/59f87d102efd7482ec24ea83c3e3901c2e14289076bd9873cfeb50130cbb32e87d6955e00ba8624f72147f66baf5aa3990a18b33da8a1a6f64b2149f58a2f40a.jpg) center/cover;
        transition: 0.3s;
        @media ${theme.screen.sm} {
          padding: initial;
        }
        & .text-box {
          margin-top:10%;
          height: 80%;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          opacity: 100%;
          transition: 0.3s;
          @media ${theme.screen.sm} {
            justify-content: center;
            gap: 20px;
          }
          & p {
            font-weight: lighter;
            line-height: 22px;
            font-size: 1vw;
            @media ${theme.screen.sm} {
            font-size: 11px;
            }
            & span {
              font-weight: bold;
              color:${theme.color.pointGold}
            }
          }
          & p.mobile-none {
            @media ${theme.screen.sm} {
              display: none;
            }
          }
        }
        & .slogan {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 100%;
            height: 100%;
            opacity: 0%;
            transition: 0.3s;
          & img {
            width: 20vw;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 20%;
            @media ${theme.screen.sm} {
              width: 50vw;
            }
          }
          & h2 {
            font-weight: bold;
            font-size: 2vw;
            line-height: initial;
            height: 100%;
            display: grid;
            align-items: center;
            @media ${theme.screen.sm} {
              font-size: 5vw;
            }
          }
        }
      }
      /* hover */
      & .center-circle:hover {
        & .text-box {
          transition: 0.3s;
          opacity: 0%;
        }
        & .slogan {
          transition: 0.3s;
          opacity: 100%;
        }
      }
    }
  }
`;

export const SoleilnoirCircle = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    & .circle-logo-box {
      position: relative;
      width: 220px;
      height: 220px;
      border-radius: 50%;
      border: 2px solid #fff;
      background-color: #000;
      animation: showUp 2s;
      transform: translateX(10px);

      @media ${theme.screen.sm} {
        width: 120px;
        height: 120px;
        transform: translateX(0);
        border-width: 1px;
      }

      @keyframes showUp {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      & .image-box {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 150px;
        height: auto;
        transition: all 1s;

        @media ${theme.screen.sm} {
          width: 70px;
        }
      }
      
      &:hover .image-box {
        opacity: 0;
        transition: all 0.5s;
      }


      & .slogan {
        position: absolute;
        width: 100%;
        left: 100%;
        opacity: 0;
        left: 50%;
        top: 50%;
        text-align: center;
        line-height: 34px;
        font-size: 22px;
        transform: translate(-50%, -50%);
        transition: all 0.5s;

        @media ${theme.screen.sm} {
          line-height: 18px;
          font-size: 15px;
        }

      }

      &:hover .slogan {
        opacity: 1;
        transition: all 1s;
      }
    }
`;

export const SoleilnoirIntro = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* z-index: 99; */

  & .active-eclipse {

    &.sun-box {
      opacity: 0;
      animation: sunEclipse 1.4s;
      @keyframes sunEclipse {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
          transform: translateX(240px);
        }
      }

      @media ${theme.screen.sm} {
        @keyframes sunEclipse {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
            transform: translateX(120px);
          }
        } 
      }
    }

    &.moon-box {
      opacity: 0;
      animation: moonEclipse 1.4s;
      @keyframes moonEclipse {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
          transform: translateX(-10px);
        }
      }
    }

    &.earth-box {
      opacity: 0;
      animation: earthEclipse 1.4s;
      @keyframes earthEclipse {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
          transform: translateX(-240px);
        }
      }

      @media ${theme.screen.sm} {
        @keyframes earthEclipse {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
            transform: translateX(-120px);
          }
        }
      }
    }
  }

  & .circle-box {
    position: relative;
    
    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, transparent, black);
      z-index: 1;
    }

    & span, h5, h6 {
      position: absolute;
      left: 50%;
      top: 25%;
      transform: translate(-25%, 0);
      font-size: 24px;
      opacity: 0;
      z-index: 2;

      @media ${theme.screen.sm} {
        font-size: 16px;
      }
    }

    & h5 {
      transform: translateX(-40%);
      font-size: 26px;
      letter-spacing: 1.2px;

      @media ${theme.screen.sm} {
        font-size: 14px;
      }
    }

    & h6 {
      top: 75%;
      transform: translate(-30%, -70%);

      @media ${theme.screen.sm} {
        transform: translate(-15%, -70%);
      }
    }

    & span {
      top: 50%;
      transform: translate(-25%, -50%);
    }
  }

  & .active-text {

    & span, h5, h6 {
      opacity: 1;
      animation: showText 1s ease-out;
    }

    @keyframes showText {
      0% {
        opacity: 0;
        /* transform: translate(-25%, 30px); */
      }
      100% {
        opacity: 1;
        /* transform: translate(-25%, -50%); */
      }
    }
  }

  & .sun-box {
    transform: translateX(19px);
  }

  & .moon-box {
    animation: moonBox 1s ease;
    /* animation-delay: 0.2s; */

    @keyframes moonBox {
      0% {
        transform: translateX(-500px);
        opacity: 0;
      }
      100% {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  & .earth-box {
    animation: earthBox 1s ease;
    /* animation-delay: 0.4s; */
    transform: translateX(-19px);

    @keyframes earthBox {
      0% {
        transform: translateX(-520px);
        opacity: 0;
      }
      100% {
        transform: translateX(-19px);
        opacity: 1;
      }
    }
  }

  & svg {
    position: relative;
    width: 240px;
    height: 240px;
    border-radius: 50%;

    @media ${theme.screen.sm} {
      width: 140px;
      height: 140px;
    }

    & circle {
      width: 100%;
      height: 100%;
      stroke-width: 2px;
      fill: none;
      
      stroke-linecap: round;
      transform: translate(5px, 5px);
      stroke-dasharray: 660;
      stroke-dashoffset: 0;

      @media ${theme.screen.sm} {
        width: 50%;
        height: 50%;
        stroke-dasharray: 360;
      }
    }

    &.sun circle {
      stroke: ${theme.color.pointRed};
      animation: animateSun 0.7s ease-out;
    }
    &.moon circle {
      stroke: ${theme.color.pointGold};
      animation: animateMoon 1.05s ease-out;
    }
    &.earth circle {
      stroke: #f1f1f1;
      animation: animateEarth 1.4s ease-out;
    }

    @keyframes animateSun {
      0% {
        stroke-dashoffset: 660;
      }
      100% {
        stroke-dashoffset: 0;
      }
    }

    @keyframes animateMoon {
      0% {
        stroke-dashoffset: 660;
        opacity: 0;
      }
      33% {
        stroke-dashoffset: 660;
        opacity: 1;
      }
      100% {
        stroke-dashoffset: 0;
        opacity: 1;
      }
    }

    @keyframes animateEarth {
      0% {
        stroke-dashoffset: 660;
        opacity: 0;
      }
      50% {
        stroke-dashoffset: 660;
        opacity: 1;
      }
      100% {
        stroke-dashoffset: 0;
        opacity: 1;
      }
    }

    @media ${theme.screen.sm} {
      @keyframes animateSun {
        0% {
          stroke-dashoffset: 360;
        }
        100% {
          stroke-dashoffset: 0;
        }
      }
      @keyframes animateMoon {
        0% {
          stroke-dashoffset: 360;
          opacity: 0;
        }
        33% {
          stroke-dashoffset: 360;
          opacity: 1;
        }
        100% {
          stroke-dashoffset: 0;
          opacity: 1;
        }
      }
      @keyframes animateEarth {
        0% {
          stroke-dashoffset: 360;
          opacity: 0;
        }
        50% {
          stroke-dashoffset: 360;
          opacity: 1;
        }
        100% {
          stroke-dashoffset: 0;
          opacity: 1;
        }
      }
    }
  }

  & .horizontal-line {
    position: absolute;
    left: 0;
    height: 1px;
    background-color: #fff;
    z-index: 1;
    animation: horizontalRight 2.5s ease;
    /* animation-delay: 1.2s; */

    @keyframes horizontalRight {
      0% {
        width: 0%;
        /* opacity: 1; */
      }
      50% {
        width: 101%;
        /* opacity: 1; */
      }
      100% {
        width: 101%;
        opacity: 0;
      }
      /* 70% {
        width: 0%;
        right: 0;
      } */
    }
  }

  & .infinite-horizontal-line {
    position: absolute;
    left: 0;
    height: 1px;
    width: 102%;
    background-color: #fff;
    z-index: 1;
    animation: horizontal 1.2s ease;

    @media ${theme.screen.sm} {
      height: 0.5px;
    }

    @keyframes horizontal {
      0% {
        width: 0%;
        /* opacity: 1; */
      }
      100% {
        width: 102%;
        /* opacity: 1; */
      }
    }
  }
`;

export const HeaderContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  line-height: 50px;
  background-color: transparent;
  color: ${theme.color.mainWhite};
  z-index: 100;
  transition: all 0.5s;
  gap:10vw;
  backdrop-filter: blur(10px);

  & .logo-box {
    cursor: pointer;

    & img {
      width: auto;
      height: 45px;
    }
  }

  & .menu-box {
    display: flex;
    gap: 2vw;

    & .major-menu {
      padding: 0 20px;
      font-size: 18px;

      &:hover {
        color: ${theme.color.pointGold};
        cursor: pointer;
        transition: all 0.3s;
      }

      & .bottom-indicator {
        background-color: ${theme.color.pointGold};
        height: 3px;
        width: 0%;
        transform: translateY(-10px);
        transition: all 0.3s ease-in-out;
      }

      &:hover .bottom-indicator {
        width: 100%;
      }
    }

    & .current {
      color: ${theme.color.pointGold};
      /* font-weight: 600; */
    }

    & .franchise-menu {
      /* position: relative; */

      & span {
        position: absolute;
        top: 20px;
        right: 50px;
        width: 120px;
        text-align: center;
        height: 36px;
        line-height: 36px;
        background-color: ${theme.color.pointGold};
        border: 1px solid ${theme.color.pointGold};
        border-radius: 20px;
        font-weight: 500;
        transition: all 500ms;

        &:hover {
          cursor: pointer;
          background-color: transparent;
          color: ${theme.color.pointGold};
        }
      }
    }
  }
`;

export const HeaderMenuBox = styled.div<{$height: number}>`
  position: relative;

  & .brand-sub-box {
    position: absolute;
    width: 200px;
    height: ${props => props.$height}px;
    border-radius: 5px;
    opacity: 0.85;
    background-color: ${theme.color.mainWhite};
    color: ${theme.color.mainBlack};
    box-shadow: ${theme.shadow.default};
    overflow: hidden;

    & .brand-sub-menu {
      padding: 0 15px;
      border-radius: 5px;
      color: #acacac;
      font-size: 18px;
      font-weight: 500;

      &:hover {
        background-color: ${theme.color.pointGold};
        color: ${theme.color.mainWhite};
        box-shadow: ${theme.shadow.default};
      }
    }
  }

  & .active-1 {
    animation: slideShowUp1 0.3s ease-in;
  }
  & .active-2 {
    animation: slideShowUp2 0.3s ease-in;
  }

  @keyframes slideShowUp1 {
    from {
      opacity: 0;
      height: 0;
    }
    to {
      opacity: 0.85;
      height: 100px;
    }
  }
  @keyframes slideShowUp2 {
    from {
      opacity: 0;
      height: 0;
    }
    to {
      opacity: 0.85;
      height: 250px;
    }
  }
`

export const MobileHeaderContainer = styled.div`
  position: fixed;
  width: 100%;
  background-color: ${theme.color.mainWhite};
  color: ${theme.color.mainBlack};
  box-shadow: ${theme.shadow.matte};

  & .outer-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;

    @media ${theme.screen.sm} {
      height: 40px;
    }

    & .hamburger-menu-box {
      width: 60px;
      padding-left: 10px;
  
      & img {
        width: 36px;
        height: 36px;
        transition: all 0.3s;
        cursor: pointer;
      }
    }
  
    & .logo-box {
      cursor: pointer;

      & img {
        width: auto;
        height: 40px;

        @media ${theme.screen.sm} {
          height: 28px;
        }
      }
    }
  }

  & .inner-box {
    width: 100%;
    height: 0;
    transition: all 0.2s ease-in;
    overflow: hidden;

    & .inner-items {
      display: flex;
      justify-content: space-between;
      margin: 1px 0;
      padding: 15px;
      width: 100%;
      background-color: transparent;

      &:hover {
        cursor: pointer;
        font-weight: 700;
      }

      & span {
        font-size: 18px;
      }

      & img {
        width: 20px;
        height: 20px;
      }
    }

    & .sub-menu {
      width: 100%;
      height: 1px;
      background-color: ${theme.color.superLightGray};
    }

    & .sub-box {
      width: 100%;
      height: 1px;
      background-color: ${theme.color.superLightGray};
      transition: all 0.2s ease-in;
      overflow: hidden;

      & .brand-sub-menu {
        margin: 5px;
        padding: 12px;
        color: ${theme.color.pointGold};

        &:hover {
          cursor: pointer;
          font-weight: 600;
        }
      }
    }
  }
`;

export const MobileMenuModalContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadeIn 0.3s;

  @keyframes fadeIn {
    from {
      background-color: rgba(0, 0, 0, 0);
    }
    to {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`;

export const IndexPageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* z-index: 10; */

  & video {
    /* position: fixed; */
    width: 100%;
    height: auto;
    object-fit: cover;
    z-index: 0;

    @media ${theme.screen.sm} {
      margin-top: -20px;
    }
  }

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 1));

    @media (max-width: 1079px) {
      padding-top: 60px;
    }
  }

  @media (max-width: 1079px) {
    padding-top: 60px;
  }
`;

export const FooterContainer = styled.div`
  width:100%;
  padding-block:40px;
  background-color:${theme.color.mainBlack};
  & .container {
    width: 90%;
    max-width:1200px;
    margin:0 auto;
    display:flex;
    justify-content: space-between;
    @media ${theme.screen.sm} {
      flex-direction: column;
    }
    & .slogan-box {
      width:40%;
      @media ${theme.screen.im} {
        width: 30%;
      }
      @media ${theme.screen.sm} {
        width: 100%;
      }
      & .slogan {
        display:flex;
        gap:24px;
        margin-bottom:15px;
        @media ${theme.screen.sm} {
          gap: initial;
          justify-content: space-between;
        }
        & img {
          width: 222px;
          height: 132px;
          object-fit: cover;
          @media ${theme.screen.sm} {
            width: 140px;
            height: 83px;
          }
        }
        & .slogan-text {
          display:flex;
          flex-direction:column;
          justify-content: space-around;
          @media ${theme.screen.im} {
            display: none;
          }
          @media ${theme.screen.sm} {
            display: block;
          }
          & p {
            margin-bottom:10px;
            font-size:18px;
            font-weight:lighter;
            line-height:20px;
            @media ${theme.screen.sm} {
              font-size: 5vw;
            }
          }
        }
        & .sns-box {
          display:flex;
          @media ${theme.screen.im} {
            flex-direction: column;
            gap: 40px;
          }
          @media ${theme.screen.sm} {
            display: none;
          }
          & .sns {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
            width: 50px;
            height: 50px;
            background-color: ${theme.color.mainWhite};
            border-radius: 50%;
            cursor: pointer;
            & img {
              width: 30px;
              height: auto;
            }
          }
        }
      }
      & .Copyright {
        font-weight:lighter;
        color:${theme.color.subGray01};
        @media ${theme.screen.im} {
          font-size: 13px;
        }
        @media ${theme.screen.sm} {
          text-align: center;
        }
      }
    }
    & .about-box {
      width:45%;
      display: flex;
      flex-direction: column;
      @media ${theme.screen.im} {
        width: 55%;
      }
      @media ${theme.screen.sm} {
        margin-top: 10px;
        text-align: center;
        width: 100%;
      }
      & .title {
        font-size: 1.3em;
        font-weight: bold;
        @media ${theme.screen.sm} {
          display: none;
        }
      }
      & ul {
        padding-block: 20px;
        height:100%;
        display:flex;
        flex-direction:column;
        gap: 10px;
        & li {
          font-size: 13px;
          display:flex;
          font-weight:lighter;
          @media ${theme.screen.sm} {
            font-size: 12px;
            width: 100%;
          }
          & p:first-child {
            width:35%;
          }

        }
      }
    }
    & .menu-box {
      width: 10%;
      @media ${theme.screen.sm} {
        display: none;
      }
      & .title {
        font-size: 1.3em;
        font-weight: bold;
      }
      & p {
        padding-bottom: 10px;
        font-weight: lighter;
        width: 40%;
        position: relative;
        z-index: 9999;
      }
    }
  }
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 900 !important;
  overflow-y: hidden;
`;