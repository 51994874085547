import React from "react";
import * as SCF from "../../style/StyleComponentFranchise";
// import IntroFranchise from "./IntroFranchise";
import FranchisePrallax from "./FranchisePrallax";
import SubSlogan from "../brand/SubSlogan";
import CoreValueTwo from "../brand/CoreValueTwo";

interface Props {
  mobileSize: boolean;
  browserWidth: number;
}

const Introduction: React.FC<Props> = ({mobileSize, browserWidth}) => {
  return (
    <>
      <SCF.DefaultContainer>
        <FranchisePrallax /> 
        <SubSlogan mobileSize={mobileSize} />
        <CoreValueTwo />
      </SCF.DefaultContainer>
    </>
  )
};

export default Introduction;