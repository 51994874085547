import React from 'react'
import MobileApp from "./MobileApp";
import Appbanner from './Appbanner';
import AppFunction from './AppFunction';

function Application() {
  return (
    <div>
      <MobileApp />
      <Appbanner />
      <AppFunction />
    </div>
  )
}

export default Application