import React, { useRef, useEffect, useState } from "react";
import * as SCM from "../../style/StyleComponentMenu";
import { brand_gallery_image_data_1, brand_gallery_image_data_2 } from "../../module/Variable";
import { ScrollAnimationContainer } from "../util/ScrollAnimationContainer";

const Gallery: React.FC = () => {
  const [nextPercentage, setNextPercentage] = useState<number>(0);
  const [containerPositionTop, setContainerPositionTop] = useState<number>(0);
  const [scrollPositionY, setScrollPositionY] = useState<number>(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const trackRef1 = useRef<HTMLDivElement>(null);
  const trackRef2 = useRef<HTMLDivElement>(null);
  const imageRef = useRef<any>([]);
  const imageRef2 = useRef<any>([]);

  useEffect(() => {
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      setContainerPositionTop(window.scrollY + containerRect.top);
    }
    window.addEventListener("scroll", getScrollPositionY);
    return () => {
      window.removeEventListener("scroll", getScrollPositionY);
    };
  }, []);

  useEffect(() => {
    if (scrollPositionY > containerPositionTop) {
      setNextPercentage(Math.max(Math.min((scrollPositionY - containerPositionTop) * -0.25, 0), -100));
    }
  }, [containerPositionTop, scrollPositionY]);

  const getScrollPositionY = () => {
    setScrollPositionY(window.scrollY + (window.innerHeight * 0.3));
  };

  useEffect(() => {
    let currentPercentage = nextPercentage;
    let targetPercentage = nextPercentage;
  
    const lerp = (start: number, end: number, t: number) => {
      return start * (1 - t) + end * t;
    };
  
    const animate = () => {
      currentPercentage = lerp(currentPercentage, targetPercentage, 0.05);
      setNextPercentage(currentPercentage);
  
      const reversePercentage = -currentPercentage;
      if (trackRef1.current) {
        trackRef1.current.style.transform = `translate(${currentPercentage}%, 0%)`;
        brand_gallery_image_data_1.forEach((_, i) => {
          if (imageRef.current[i]) {
            imageRef.current[i].style.objectPosition = `${(currentPercentage) + 100}% 50%`;
          }
        });
      }
      if (trackRef2.current) {
        trackRef2.current.style.transform = `translate(${reversePercentage}%, 0%)`;
        brand_gallery_image_data_2.forEach((_, i) => {
          if (imageRef2.current[i]) {
            imageRef2.current[i].style.objectPosition = `${reversePercentage}% 0%`;
          }
        });
      }
  
      requestAnimationFrame(animate);
    };
  
    const handleWheel = (e: WheelEvent) => {
      const delta = e.deltaY;
  
      targetPercentage = Math.max(Math.min(targetPercentage - delta * 0.3, 0), -100);
    };
  
    const handleResize = () => {
      if (containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        setContainerPositionTop(window.scrollY + containerRect.top);
      }
    };
  
    animate();
  
    containerRef.current?.addEventListener("wheel", handleWheel);
    window.addEventListener("resize", handleResize);
  
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      containerRef.current?.removeEventListener("wheel", handleWheel);
      window.removeEventListener("resize", handleResize);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ScrollAnimationContainer
        direction="B"
        duration="1s"
        delay="0s"
        threshold={0.8}
        onlyOnce
      >
        <div className="flex justify-center items-center text-center leading-[80px] sm:leading-[32px] py-[2vh]">
          <div>
            <h1 className="text-[80px] sm:text-[32px] font-CormorantGaramond">
              A High-Level Fitness Lifestyle Paradigm 
            </h1>
            <h3 className="text-[32px] sm:text-[11px]">
              솔레노어는 새로운 피트니스 라이프스타일 패러다임을 제시합니다
            </h3>
          </div>
        </div>
      </ScrollAnimationContainer>
      <SCM.GalleryContainer ref={containerRef}>
        <div ref={trackRef1} className="image-track">
          {brand_gallery_image_data_1.map((src, idx) => (
            <img
              ref={(el) => { imageRef.current[idx] = el; }}
              key={idx}
              className="image"
              src={src}
              draggable={false}
              alt="soleilnoir"
            />
          ))}
        </div>
        <div ref={trackRef2} className="image-track-2">
          {brand_gallery_image_data_2.map((src, idx) => (
            <img
              ref={(el) => { imageRef2.current[idx] = el; }}
              key={idx}
              className="image"
              src={src}
              draggable={false}
              alt="soleilnoir"
            />
          ))}
        </div>
      </SCM.GalleryContainer>
    </>
  );
};

export default Gallery;
