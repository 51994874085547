import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Main from './components/layout/Main';
import Franchise from './components/franchise/Franchise';
import Application from './components/application/Application';
import Footer from './components/layout/Footer';
import Brand from './components/brand/Brand';
import Identity from './components/brand/Identity';
import Kitchen from './components/sub_brand/Kitchen';
import Apparel from './components/sub_brand/Apparel';
import Header from './components/layout/Header';
import ScrollToTopComponent from './components/util/ScrollToTopComponent';
import Club from './components/club/Club';
import Instructor from './components/instructor/Instructor';
// import Intro from './components/layout/Intro';
import MembershipTwo from './components/membership/MembershipTwo';
import useDetectScroll from '@smakss/react-scroll-direction';
import { useEffect } from 'react';
import { useState } from 'react';

function App() {
  const { pathname } = useLocation();
  const { scrollDir, scrollPosition } = useDetectScroll();
  const [showTopButton, setShowTopButton] = useState(false);

  useEffect(() => {
    if (scrollDir === "up") {
      setShowTopButton(true);
    } else {
      setShowTopButton(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollDir]);

  useEffect(() => {
    setShowTopButton(false);
  }, [pathname]);

  useEffect(() => {
    if (scrollPosition.top < 1000) {
      setShowTopButton(false);
    }
  }, [scrollPosition.top])

  return (
    <>
      <ScrollToTopComponent />
      {/* {(pathname !== "/" && pathname !== "/franchise") &&
        <Header />
      } */}
      <Routes>
        <Route path='/' element={<Main />} />
        {/* <Route path='/intro' element={<Intro />} /> */}
        <Route path='/franchise' element={<Franchise />} />

        <Route path='/brand' element={<Brand />} />
        <Route path='/identity' element={<Identity />} />
        <Route path='/membership' element={<MembershipTwo />} />
        <Route path='/application' element={<Application />} />
        <Route path='/kitchen' element={<Kitchen />} />
        <Route path='/apparel' element={<Apparel />} />
        <Route path='/club' element={<Club />} />
        <Route path='/instructor' element={<Instructor />} />
      </Routes>
      {(pathname !== "/" && pathname !== "/membership") &&
        <Footer />
      }
      <div
        className={`top-button ${showTopButton ? "show-top-button" : "hide-top-button"}`}
        onClick={() => {
          if (!showTopButton) return;
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
      >
        top
      </div>
    </>
  );
}

export default App;
