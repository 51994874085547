import styled from "styled-components";
import theme from "./theme";

export const Container = styled.div<{$duration: string,$delay: string,}>`
  width: 100%;
  overflow: hidden;
  opacity: 0;

  &.fade-in {
    animation: fadeIn ${props => props.$duration} forwards;
    animation-delay: ${props => props.$delay};
  }
  &.fade-in-slide-l {
    animation: fadeInSlideL ${props => props.$duration} forwards;
    animation-delay: ${props => props.$delay};
  }
  &.fade-in-slide-b {
    animation: fadeInSlideB ${props => props.$duration} forwards;
    animation-delay: ${props => props.$delay};
  }
  &.fade-in-slide-t {
    animation: fadeInSlideT ${props => props.$duration} forwards;
    animation-delay: ${props => props.$delay};
  }
  &.fade-in-slide-r {
    animation: fadeInSlideR ${props => props.$duration} forwards;
    animation-delay: ${props => props.$delay};
  }

  &.initial {
    opacity: 0;
    transform: translateX(0);
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeInSlideL {
    0% {
      opacity: 0;
      transform: translateX(-100px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes fadeInSlideB {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes fadeInSlideT {
    0% {
      opacity: 0;
      transform: translateY(-100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes fadeInSlideR {
    0% {
      opacity: 0;
      transform: translateX(100px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @media (max-width: 768px) {
    overflow: visible;
    width: auto;
  }
`;

export const CarouselContainer = styled.div<{$currentIndex: number}>`
  display: flex;
  width: ${theme.utility.iPhoneWidth};
  height: ${theme.utility.iPhoneHeight};
  transform: translateX(-${props => props.$currentIndex * 100}%);
  transition: all 0.8s ease;
  /* z-index: 22; */
  /* cursor: grab; */

  @media ${theme.screen.md} {
    width: ${theme.utility.iPhoneSmallWidth};
    height: ${theme.utility.iPhoneSmallHeight};
  }

  & .carousel-item {
    position: relative;
    flex: 0 0 100%;
    height: ${theme.utility.iPhoneHeight};
    border-radius: 40px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media ${theme.screen.md} {
      height: ${theme.utility.iPhoneSmallHeight};
    }
  }
`;

export const SliderContainer = styled.div<{$height: string, $currentIndex: number, $speed: number}>`
  position: relative;
  display: flex;
  width: 100%;
  transform: translateX(-${props => props.$currentIndex * 100}%);
  transition-property: all;
  transition-duration: ${props => props.$speed}s;

  & :before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    transition: all 1s;
  }

  &:hover :before {
      background-color: rgba(0, 0, 0, 0.2);
    }

  & .slider-item {
    position: relative;
    flex: 0 0 100%;
    width: 100%;
    height: ${props => props.$height};
  }
`;

export const SliderItem = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  & img {
    width: 100%;
    height: 100%;
  }

  & .membership-header-slider {
    position: absolute;
  }
`;

export const SliderSeparateItem = styled.div`
  position: absolute;
  top: 150px;
  left: 60px;
  opacity: 0;

  & .title-box {

    & h1 {
      font-size: 48px;
    }
  }

  & .content-box {
    margin-top: 20px;

    & p {
      font-size: 24px;
    }
  }

  &.active {
    animation: showUp 1s;
    opacity: 1;
  }

  @keyframes showUp {
    0% {
      opacity: 0;
      transform: translateY(60px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const YoutubeFlexContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 80px;

  @media ${theme.screen.sm} {
    padding: 0 20px;
    display: block;
    gap: 0;
  }

  & .youtube-box {
    padding: 20px;
    border: 1px solid ${theme.color.pointGold};

    @media ${theme.screen.sm} {
      justify-content: center;
      margin-top: 40px;
      padding: 20px 0;
      display: flex;
    }
    
    & .text-box {
      height: 60px;
      line-height: 60px;
      font-size: 24px;
      background-color: ${theme.color.pointGold};
      text-align: center;
      /* color: #000; */

      @media ${theme.screen.sm} {
        height: 40px;
        line-height: 40px;
        font-size: 18px;
      }
    }
  }
`;

export const YoutubeContainer = styled.div`
  width: 100%;
  height: 150vh;
  & .youtube-container {
    margin: 10vh auto;
    display: flex; justify-content: space-between; flex-wrap: wrap;
    max-width: 1200px; width: 80%;
    & .youtube-box {
      width: 32%;
      & img {width: 100%; margin-bottom:20px;}
    }
  }
  & .youtube--big {
    max-width: 1200px; width: 100%; height:50%; margin: 20vh auto 10vh;
    & div {width: 100%; height: 100%;}
    & iframe {width: 100%; height:100%;}

    }
`;
