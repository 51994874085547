import React from "react";
import * as SCF from "../../style/StyleComponentFranchise";
import { useState } from "react";

const Request: React.FC = () => {
  const [hasCenter, setHasCenter] = useState<number>(0);

  return (
    <>
      <SCF.RequestContainer>
        <div className="request-container">
          <img
            src={require("../../assets/logo_images/soleilnoir_logo_b.png")}
            alt="logo"
          />
          <p className="title">가맹 문의</p>
          <div className="content">
            <div className="input-box">
              <div className="label">
                성함
              </div>
              <div className="input">
                <input type="text" placeholder="성함을 입력해 주세요." />
              </div>
            </div>
            <div className="input-box">
              <div className="label">
                연락처
              </div>
              <div className="input">
                <input type="text" placeholder="연락처를 입력해 주세요." />
              </div>
            </div>
            <div className="input-box">
              <div className="label">
                가맹희망 지역
              </div>
              <div className="input">
                <input type="text" placeholder="거래희망지역을 입력해주세요." />
              </div>
            </div>
            <div className="input-box">
              <div className="label">
                센터보유 유무
              </div>
              <div className="select">
                <button
                  className={`${hasCenter === 1 ? "active" : "inactive"}`}
                  onClick={() => {
                    setHasCenter(1);
                  }}
                >
                  유
                </button>
                <button
                  className={`${hasCenter === 2 ? "active" : "inactive"}`}
                  onClick={() => {
                    setHasCenter(2);
                  }}
                >
                  무
                </button>
              </div>
            </div>
            <div className="input-box">
              <div className="label">
                문의 내용
              </div>
              <div className="input">
                <textarea rows={6}></textarea>
              </div>
            </div>
            <div className="privacy">
              <input type="checkbox" className="checkBox" />
              <span>개인정보수집 및 동의</span>
            </div>
          </div>
          <div className="submit-box">
            <button>
              문의하기
            </button>
          </div>
        </div>
      </SCF.RequestContainer>
    </>
  )
};

export default Request;