import React from "react";
import * as SCL from "../../style/StyleComponentLayout";
import instagram_icon from "../../assets/icon_images/instagram.svg";
import youtube_icon from "../../assets/icon_images/youtube.svg";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <SCL.FooterContainer>
        <div className="container">
            <div className="slogan-box">
              <div className="slogan">
                <img src={require("../../assets/logo_images/soleilnoir_logo_w.png")}alt="logo_white" />
                <div className="slogan-text">
                  <p className="font-CormorantGaramond">Perfect Balance Between<br/>
                  Health And life</p>
                  <p>건강과 삶의 완벽한 균형</p>
                  <div className="sns-box">
                    <div className="sns">
                      <a
                        href="https://www.instagram.com/soleilnoir_pt/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={instagram_icon} alt="instagram_icon" />
                      </a>
                    </div>
                    <div className="sns">
                      <a
                        href="https://www.youtube.com/@Soleilnoir_pt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={youtube_icon} alt="youtube_icon" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <p className="Copyright">Copyright All rights reserved © 2024 SoleilNoir</p>
            </div>
            <div className="about-box">
              <p className="title">About</p>
              <ul>
                <li>
                  <p className="name">상호</p>
                  <p>솔레노어 Soleilnoir</p>
                </li>
                <li>
                  <p className="name">대표자</p>
                  <p>김대웅</p>
                </li>
                <li>
                  <p className="name">사업자등록번호</p>
                  <p>507-85-23423</p>
                </li>
                <li>
                  <p className="name">주소</p>
                  <p>부산시 해운대구 마린시티2로2 403, 404호</p>
                </li>
                <li>
                  <p className="name">전화번호</p>
                  <p>051-992-1317</p>
                </li>
              </ul>
            </div>
            <div className="menu-box">
                <p className="title">Quick Links</p>
                <p onClick={() => navigate("/brand")}>Brand</p>
                <p onClick={() => navigate("/franchise")}>Franchise</p>
            </div>
        </div>
        
      </SCL.FooterContainer>
    </>
  )
};

export default Footer;