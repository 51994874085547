import React from "react";
import * as SCF from "../../style/StyleComponentFranchise";
import { backend_url } from "../../module/DevInformation";

const IT: React.FC = () => {

  return (
    <div>
      <SCF.ITContainer>
        <div className="container">
          <div className="rect"></div>
          <h2 className="title">
            솔레노어만의<br/>
            <b>IT 관리 시스템</b>
          </h2>
          <p className="subTitle">IT support</p>
          <div className="img-box">
            <img className='mobile' src={`${backend_url}/public/image_folder/homepage_images/brand/290692d394e2d678b19108b82a0cbd3854e8b3a23e44bd77ff42a02f4f983e79c82ea5b19af840a8d9a41603ab14207ad1252593da71b281b6a513b5484f3483.png`} alt='Mobile' />
            <img className='web' src={`${backend_url}/public/image_folder/homepage_images/franchise/8603dc51f1fb169518609e3361890cafbd1acff351af2e36e8cc6f501a2dd40319e17bec330c6fe6a44a59022eb2fbf3c8f4b40c568923b0f3dd86f9f671b767.png`} alt='web' />
            <img className='tablet' src={`${backend_url}/public/image_folder/homepage_images/franchise/cb9f0c7076d58bfb4da4a46f96304eb1284a0337f340148a326031d625044dbba6c6b65be4ae6c718e35e87a43751ba585a493f5e6b4c9da48cf7e10e501b373.png`} alt='tablet' />
          </div>
          <div className="app-box">
            <div className="app">
              <img className='mobile' src={`${backend_url}/public/image_folder/homepage_images/brand/290692d394e2d678b19108b82a0cbd3854e8b3a23e44bd77ff42a02f4f983e79c82ea5b19af840a8d9a41603ab14207ad1252593da71b281b6a513b5484f3483.png`} alt='Mobile' />
              <div className="app-title-box">
                <p className="app-title">SOLEILNOIR APP</p>
                <p className="app-subTitle">솔레노어 CRM 앱</p>
              </div>
              <div className="text-box">
                <ul>
                  <li>- PT/키친 인앱결제 시스템</li>
                  <li>- 인스트럭터 회원 1:1 채팅 시스템</li>
                  <li>- 식단 관리/피드백 시스템</li>
                  <li>- PT 예약 스케쥴링</li>
                  <li>- 바디데이터 관리</li>
                  <li>- 체중관리 시스템</li>
                  <li>- 워크아웃(수업 완료 체크) 시스템</li>
                  <li>- 지점 이용 현황 확인</li>
                  <li>- PT 피드백 시스템</li>
                  <li className="none">.</li>
                  <li className="none">.</li>
                </ul>
              </div>
            </div>
            <div className="app">
              <img className='web' src={`${backend_url}/public/image_folder/homepage_images/franchise/8603dc51f1fb169518609e3361890cafbd1acff351af2e36e8cc6f501a2dd40319e17bec330c6fe6a44a59022eb2fbf3c8f4b40c568923b0f3dd86f9f671b767.png`} alt='web' />
              <div className="app-title-box">
                <p className="app-title">SOLEILNOIR SWS</p>
                <p className="app-subTitle">솔레노어 관리자용 웹</p>
              </div>
              <div className="line"></div>
              <div className="text-box">
                <ul>
                  <li>- 수업 및 휴무일 스케쥴 관리</li>
                  <li>- 상담 관리</li>
                  <li>- 회원 관리</li>
                  <li>- 인스트럭터 인사 관리</li>
                  <li>- 지점 관리</li>
                  <li>- 매출/회계 관리</li>
                  <li>- 발주/재고 관리</li>
                  <li>- 일일 보고서 시스템</li>
                  <li>- 자동 급여 계산 시스템</li>
                  <li className="none">.</li>
                  <li className="none">.</li>
                </ul>
              </div>
            </div>
            <div className="app">
              <img className='tablet' src={`${backend_url}/public/image_folder/homepage_images/franchise/cb9f0c7076d58bfb4da4a46f96304eb1284a0337f340148a326031d625044dbba6c6b65be4ae6c718e35e87a43751ba585a493f5e6b4c9da48cf7e10e501b373.png`} alt='tablet' />
              <div className="app-title-box">
                <p className="app-title">SOLEILNOIR APP</p>
                <p className="app-subTitle">솔레노어 CRM 앱</p>
              </div>
              <div className="line"></div>
              <div className="text-box">
                <ul>
                  <li>- 자체 제작한 오더 시스템</li>
                  <li>- 전 지점 사용 가능</li>
                  <li>- 출입 체크 시스템</li>
                  <li>- 현재 입장 인원 AOD 시스템</li>
                  <li>- PT 및 지점 잔여 회차 안내</li>
                  <li>- 라카 및 지점 이용 관리</li>
                  <li>- PT 및 퍼블릭 구매 시스템</li>
                  <li>- 솔레노어 키친 구매 시스템</li>
                  <li>- 솔레노어 스튜디오 예약 시스템</li>
                  <li className="none">.</li>
                  <li className="none">.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="logo-text-box">
          <img
              src={require("../../assets/logo_images/soleilnoir_logo_w.png")}
              alt="logo"
          />
          <p>
            솔레노어는 자체 인하우스 개발 팀을 보유하여,<br/>
            고객과 지점의 요구에 신속하게 대응하며<br/>
            지속적인 혁신과 발전을 이루어내고 있습니다.
          </p>
        </div>
        <div className="rect"></div>
      </SCF.ITContainer>
    </div>
  )
};

export default IT;