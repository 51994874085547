const theme = {
  color: {
    realBlack: `#000000`,
    mainBlack: `#1D1D1D`,
    darkGray01: `#2C2C2C`,
    darkGray02: `#383838`,
    subGray01: `#7A7A7A`,
    subGray02: `#CECECE`,
    mainWhite: `#FFFFFF`,
    pointGold: `#CEA57E`,
    pointRed: `#CB2A49`,
    pointGreen: `#9CF93A`,
    subBrown: `#5A4939`,
    superLightGray: `#f0f0f0`,
    pointGoldRGB: `206, 165, 126`,
    darkGray02RGB: `56, 56, 56`,
    salad_green: `#39954a`,
    
    EarthColor: `#2f5877`,
    EarthGradientColor: `#233444`,
    MoonColor: `#D8A777`,
    MoonGradientColor: `#a99581`,
    SunColor: `#CB294A`,
    GradientMiddleColor:`#282d32`,

    JourneyGradient: `rgb(224,109,0)`,
    JourneyGradientMiddleColor: ` rgb(216,167,119)`,
    JourneyGradientEndColor: ` rgb(206,149,128)`,

    OpacityBlack: `rgba(0, 0, 0, 0.3)`,
    OpacityWhite: `rgba(255, 255, 255, 0.2)`,
    OpacityWhite07: `rgba(255, 255, 255, 0.7)`,
    opacityGold: `rgba(206, 165, 126, 0.4)`,

    PotintGoldGradientEndColor: `#7F6346`,

    RankGradientBrown2: `rgba(2, 2, 2, 0.6)`,

  },
  screen: {
    sm: `(max-width: 613px)`,
    md: `(max-width: 860px)`,
    im: `(max-width: 1080px)`, // Intermediate
    lg: `(max-width: 1280px)`,
  },
  shadow: {
    default: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    matte: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"
  },
  utility: {
    iPhoneWidth: "280px",
    iPhoneHeight: "600px",
    iPhoneSmallWidth: "196px",
    iPhoneSmallHeight: "420px",
    // iPhoneSmallWidth: "245px",
    // iPhoneSmallHeight: "525px",
    // 49, 105
  },
  inner_width: {
    sm: 613,
    md: 861,
    lg: 1281,
  },
  font: {
    pretendard: 'Pretendard',
    CormorantGaramond: 'CormorantGaramond',
  },
};

export default theme;