import React from "react";
import Process from "./Process";
import FranchiseCost from "./FranchiseCost";

const Cost: React.FC = () => {

  return (
    <>
      <FranchiseCost />
      <Process />
    </>
  )
};

export default Cost;