import React, { useEffect, useState } from 'react'
import * as SCM from "../../style/StyleComponentMenu";
import { backend_url, screen_width_data } from "../../module/DevInformation";
import Plx from "react-plx";
import mouse_icon from "../../assets/icon_images/mouse.svg";
import arrow_down_two_icon from "../../assets/icon_images/arrow_down_two.svg";

function FranchisePrallax() {
    const [mobileSize, setMobileSize] = useState<boolean>(false);

    useEffect(()=>{
        window.addEventListener("resize", handleReSize);
        handleReSize();

        return ()=> {
            window.removeEventListener("resize", handleReSize);
        }
    }, []);
  
    const handleReSize = () => {
      if (screen_width_data.sm >= window.innerWidth) {
        setMobileSize(true);
      } else {
        setMobileSize(false);
      }
    };
  
    useEffect(() => {
      if (mobileSize) {
        mainVideoParallaxData[0].properties.push(mobileVideoTranslateY);
      } else {
        mainVideoParallaxData[0].properties.push(videoHeight);
        mainVideoParallaxData[0].properties.push(videoScaleX);
        mainVideoParallaxData[0].properties.push(videoScaleY);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mobileSize]);
  
    const mobileVideoTranslateY = {
      startValue: 1,
      endValue: 0,
      property: "opacity",
    }
    const videoHeight = {
      startValue: 100,
      endValue: 0,
      unit: "vh",
      property: "height",
    };
    const videoScaleX = {
      startValue: 1,
      endValue: 2,
      property: "scaleX",
    };
    const videoScaleY = {
      startValue: 1,
      endValue: 2,
      property: "scaleY",
    };
  
    const mainVideoParallaxData: any = [
      {
        start: "50vh",
        duration: "110vh",
        properties: [
          mobileVideoTranslateY
        ],
      },
    ];
  
    return (
      <>
        <SCM.ParallaxContainer
          // ref={parallaxRef}
        >
          <Plx
            className="active-parallax"
            parallaxData={[
              {
                start: "50vh",
                duration: "100vh",
                properties: [
                  {
                    startValue: 0,
                    endValue: -100,
                    unit: "vh",
                    property: "translateY"
                  }
                ]
              }
            ]}
          >
            <video muted autoPlay playsInline loop className='absolute'>
              <source
                src={`${backend_url}/public/image_folder/homepage_images/franchise/d074ac4a8e37bef200f89e665c628c63ce3cc8487e1ff2909ec0809cf8e74e949d426d68c01a24fd33998ee393bdb7e8ff1206826201c40c275b49275cd49d3b.mp4`}
                type="video/mp4"
              />
            </video>
          </Plx>
          <Plx
            className="main-slogan absolute"
            parallaxData={[
              {
                start: "0vh",
                duration: "50vh",
                properties: [
                  {
                    startValue: 100,
                    endValue: 0,
                    unit: "vh",
                    property: "translateY",
                  }
                ]
              },
              {
                start: "50vh",
                duration: "50vh",
                properties: [
                  {
                    startValue: 1,
                    endValue: 0,
                    property: "opacity",
                  },
                  {
                    startValue: 1,
                    endValue: 2,
                    property: "scale"
                  }
                ]
              }
            ]}
          >
            <div>
              <div className="last-slogan eng">
                <div className="font-CormorantGaramond">
                  <h1>Perfect Balance</h1>
                  <h1>Between Health And Life</h1>
                </div>
              </div>
              <div className="last-slogan kor">
                <div>
                    <div className="font-CormorantGaramond">
                      <h3>[건강과 삶의 완벽한 균형_솔레노어]</h3>
                    </div>
                </div>
              </div>
            </div>
          </Plx>
          {mobileSize || (
            <Plx
              className="mouse-icon absolute"
              parallaxData={[
                {
                  start: "0vh",
                  duration: "120vh",
                  properties: [
                    {
                      startValue: 1,
                      endValue: 0,
                      property: "opacity",
                    },
                    {
                      startValue: 0,
                      endValue: 5,
                      unit: "%",
                      property: "translateY"
                    }
                  ]
                }
              ]}
            >
              <div>
                <div>
                  <img src={mouse_icon} alt="" width={40} />
                </div>
                <div className="flex justify-center mt-2 animate-bounce">
                  <img src={arrow_down_two_icon} alt="" width={26} />
                </div>
              </div>
            </Plx>
          )}
        </SCM.ParallaxContainer>
      </>
    )
}

export default FranchisePrallax