import React from 'react'
import * as SCF from "../../style/StyleComponentFranchise";

function franchiseCost() {
  return (
    <div>
      <SCF.franchiseCostContainer>
        <div className="circle">
          <img
            src={require("../../assets/logo_images/soleilnoir_logo_w.png")}
            alt="logo"
          />
        </div>
        <div className="background">
          <div className="container">
          <div className="cost">
              <p className='title'>
                신규 창업자를<br/>
                위한 완벽한 선택
              </p>
              <p className='subTitle'>인테리어, 운영 노하우와 IT 시스템까지</p>
              <p className='text-box'>New Paradigm</p>
              <p className='desc'>
                하드웨어와 소프트웨어의 완벽한 결합<br/>
                동반성장 창업 지원형 모델 솔레노어
              </p>
              <ul>
                <li className='list-flex'>
                  <p className='name'>가맹비</p>
                  <p className='price'>
                    1,100만원<br/>
                    <span className='gray-text margin-top'>최초 가맹점 오픈을 위한 정보 자료 및 지원</span>
                  </p>
                </li>
                <li className='list-flex'>
                  <p className='name'>교육비</p>
                  <p className='price'>
                    2,200만원<br/>
                    <span className='gray-text margin-top'>1차 : 솔레노어 브랜드 마케팅 교육</span><br/>
                    <span className='gray-text'>2차 : 솔레노어 지점 운영 교육</span><br/>
                    <span className='gray-text'>3차 : 솔레노어 IT 교육</span>
                  </p>
                </li>
                <li className='list-flex'>
                  <p className='name'>보증금</p>
                  <p className='price'>
                    500만원<span className='gray-text'> (부가세 없음)</span><br/>
                    <span className='red-box margin-none'>계약종료 시 반환</span>
                    <span className='gray-text margin-top'>가맹점 사업자의 귀책 사유 발생시 반환되지 않음</span><br/>
                  </p>
                </li>
                <li className='white-text-box'>
                  <p>예치 가맹금</p>
                  <p className='price'>3,800만원</p>
                </li>
                <li className='list-flex'>
                  <p className='name'>로열티</p>
                  <p className='price'>월 매출액의 5.5% <span className='gray-text margin-top' style={{display:'block'}}>외에도 영업 중 추가 비용이 발생하니 자세한 내용은 하단의 문의하기를 통해 받아보실 수 있습니다.</span></p>
                </li>
                <li style={{textAlign:'center'}}>
                  <p className='gray-text margin-top'>보증금을 제외한 모든 금액은 부가세를 포함한 금액 입니다.</p>
                  <p className='gray-text'>자세한 내용은 하단의 문의하기를 통해 받아보실 수 있습니다.</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </SCF.franchiseCostContainer>
    </div>
  )
}

export default franchiseCost