import React from "react";
import * as SCP from "../../style/StyleComponentParallax";
import Benefit from "./Benefit";
import Application from "../application/Application";
// import SubBrand from "../sub_brand/SubBrand";
import Footer from "../layout/Footer";
import { ScrollAnimationContainer } from "../util/ScrollAnimationContainer";
// import MembershipIntro from "./MembershipIntro";
import Point from "./Point";

const MembershipTwo: React.FC = () =>{

  return (
    <>
      <SCP.MembershipParallaxContainer>
        {/* <section>
          <MembershipIntro />
        </section> */}
        <section>
          <ScrollAnimationContainer
            direction=""
            duration="1s"
            delay="0.5s"
            threshold={0.5}
          >
            <Benefit />
          </ScrollAnimationContainer>
        </section> 
        <section>
          <ScrollAnimationContainer
            direction=""
            duration="1s"
            delay="0s"
            threshold={0.5}
          >
            <Application />
          </ScrollAnimationContainer>
        </section>
        {/* <section>
          <ScrollAnimationContainer
            direction=""
            duration="1s"
            delay="0s"
            threshold={0.5}
          >
            <SubBrand />
          </ScrollAnimationContainer>
        </section> */}
        <section>
          <ScrollAnimationContainer
            direction=""
            duration="1s"
            delay="0.5s"
            threshold={0.5}
            
          >
            <Point />
          </ScrollAnimationContainer>
        </section>
        <section
          style={{
            height: "263px"
          }}
        >
          <Footer />
        </section>
      </SCP.MembershipParallaxContainer>
    </>
  )
};

export default MembershipTwo;