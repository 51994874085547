import React from 'react'
import * as SCM from "../../style/StyleComponentMenu";
import { backend_url } from "../../module/DevInformation";

function AppPoint() {

  return(
    <div>
      <SCM.PointContainer>
        <div className="container">
          <img className='iphone' src={`${backend_url}/public/image_folder/homepage_images/brand/6e6f9871028f10d8f67a0470156e1c8959cd13e6fbe8c6671b18d26a95c1dc70a2d268fbaa9e282f0267ee2e08e782007f4e9a9795f090784073e621b5226c9a.png`} alt='ipone' />
          <img className='logo' src={`${backend_url}/public/image_folder/homepage_images/brand/1a46107a6bfd85b5bdc246e9a10d1890b986078ceeae2b188c38fa49776185b0264c325870df7ce38c0a9745233e00e40b5e95f7dc62edf49f9bcb91048a4132.png`} alt='logo' />
          <p className='title'>Soleilnoir APP Point</p>
          <div className="line"></div>
          <div className="text-box">
            <p className='sub-title'>솔레노어와 <b>함께할수록 커지는 혜택</b></p>
            <ul>
              <li>PT 프로그램</li>
              <li>퍼블릭 회원권</li>
              <li>키친</li>
              <li>디저트</li>
              <li>음료</li>
              <li>어페럴</li>
            </ul>
            <p className='desc'>결제시 포인트 1% 적립 및 사용 가능</p>
          </div>
        </div>
      </SCM.PointContainer>
    </div>
  )
}

export default AppPoint