import React from 'react'
import * as SCM from "../../style/StyleComponentMenu";
import { backend_url } from "../../module/DevInformation";

function MembershipRank() {
    return (
        <div>
            <SCM.MembershipContainer>
                <div className="membership-container">
                    <div>
                        <p className='title'>Soleilnoir Membership</p>
                        <p className='sub-title'>솔레노어 멤버십</p>
                    </div>
                    <div className="member-container">
                        <div className="member-box">
                            <p className='member-title'>퍼블릭 회원 혜택</p>
                            <p className='member-title-eng'>Public Member</p>
                            <ul>
                                <li className='gold'>
                                    <div className="img-box">
                                        <img src={`${backend_url}/public/image_folder/homepage_images/brand/60644f54bba08899ee0cb5891d7e5c28192bdf1419cdf455a1017fdbc017ba2d2f033c698e36f62f2f731d93b55eb6562ee1e1afee6b95b8bea46b0204a4979f.png`} alt='icon' />
                                    </div>
                                    <p>센터 이용 <span>회원 현황 제공</span></p>
                                </li>
                                <li>
                                    <div className="img-box">
                                        <img src={`${backend_url}/public/image_folder/homepage_images/brand/2f97551fb04b39305da724e7a5c6bb84c11ac255c3c2f181b2811134658cca89517d56b891a7352d4dfc6fcc6ec0458814a9924d862c870705ef36abf35ee911.png`} alt='icon' />
                                    </div>
                                    <p>선라이즈 클럽</p>
                                    <p>선셋 클럽</p>
                                </li>
                                <li className='gold'>
                                    <div className="img-box">
                                        <img src={`${backend_url}/public/image_folder/homepage_images/brand/62c568920a67537e17cb5e727234f4cd3f203c5d1d5615de875de635cf21b361f2298080d177f1a136df1d2572759455a34753db752f03907453c2896755c0aa.png`} alt='icon' />
                                    </div>
                                    <p>
                                        수건<br/>
                                        샤워 시설 <span>어매니티</span>
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="member-box member-box--2">
                            <p className='member-title'>PT 회원 혜택</p>
                            <p className='member-title-eng'>PT Member</p>
                            <ul>
                                <li className='gold'>
                                    <div className="img-box">
                                        <img src={`${backend_url}/public/image_folder/homepage_images/brand/60644f54bba08899ee0cb5891d7e5c28192bdf1419cdf455a1017fdbc017ba2d2f033c698e36f62f2f731d93b55eb6562ee1e1afee6b95b8bea46b0204a4979f.png`} alt='icon' />
                                    </div>
                                    <p>
                                        센터 이용 회원 현황 제공<br/>
                                        식단 피드백<br/>
                                        APP 서비스<br/>
                                    </p>
                                </li>
                                <li>
                                    <div className="img-box">
                                        <img src={`${backend_url}/public/image_folder/homepage_images/brand/2f97551fb04b39305da724e7a5c6bb84c11ac255c3c2f181b2811134658cca89517d56b891a7352d4dfc6fcc6ec0458814a9924d862c870705ef36abf35ee911.png`} alt='icon' />
                                    </div>
                                    <p>
                                        채성분 분석 체크 가능<br/>
                                        개인 운동권
                                    </p>
                                </li>
                                <li className='gold'>
                                    <div className="img-box">
                                        <img src={`${backend_url}/public/image_folder/homepage_images/brand/62c568920a67537e17cb5e727234f4cd3f203c5d1d5615de875de635cf21b361f2298080d177f1a136df1d2572759455a34753db752f03907453c2896755c0aa.png`} alt='icon' />
                                    </div>
                                    <p>
                                        주차 서비스<br/>
                                        운동복 및 수건<br/>
                                        개인 락커<br/>
                                        샤워 시설 어메니티
                                    </p>
                                </li>
                                <li>
                                    <div className="img-box">
                                        <img src={`${backend_url}/public/image_folder/homepage_images/brand/6b7f328de49a3d974ba412f45360a6012439de5a77b39cc711157348f01f24c9e4505a5c09367eb4fb702c67e53aa51e651052aa954686b07c9a19365af419dd.png`} alt='icon' />
                                    </div>
                                    <p>
                                        샤워시설 어메니티<br/>
                                        근막이완 배드<br/>
                                        자유 음료 이용권<br/>
                                        <span className='gray-text'>(*PT 프로그램별 상이)</span>
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="background-box">
                    <div className="rank-container">
                        <div className="moon rank">
                            <img src={`${backend_url}/public/image_folder/homepage_images/brand/6ab10af8ba889a65796d58fce59dc5f02ea78744255ccee70e555ff18932a876fa1f02bd81478224af8540e752cc5deb83d5d445c198d04065d6d05be8b4f4e7.png`} alt="icon" />
                            <p className="rank-title">The Moon</p>
                            <p className="rank-desc">
                                솔레노어의<br/>
                                골드 레벨 피지컬 및 재활 프로그램
                            </p>
                            <div className="box"></div>
                        </div>
                        <div className="sun rank">
                            <img src={`${backend_url}/public/image_folder/homepage_images/brand/4a1867e137617eae12bbd38ca661f6b79053f1feb6e4148956e66afb4d873e583b593023c05ac37ed972efcd23398fba226abd14dfc69685fc98531ec45ee86c.png`} alt="icon" />
                            <p className="rank-title">The Sun</p>
                            <p className="rank-desc">
                                솔레노어의<br/>
                                최고 수준인 블랙 레벨 피지컬 및 재활 프로그램
                            </p>
                            <div className="box sun-box"></div>
                        </div>
                        <div className="earth rank">
                            <img src={`${backend_url}/public/image_folder/homepage_images/brand/8c6e9d7dd9b24adf8b0a512b1616f865aaf604678ed44bedb4b6f7fcfff9116390491da0842e091bdfe5b89bf0682b1094e506d7f3c96fffea941dbe39fce6fc.png`} alt="icon" />
                            <p className="rank-title">The Earth</p>
                            <p className="rank-desc">
                                솔레노어의<br/>
                                블루 레벨 피지컬 및 재활 프로그램
                            </p>
                            <div className="box"></div>
                        </div>
                        <p className='text'>
                            높은 등급으로 갈 수록 
                            <span>보다 더 전문적인 인스트럭터</span>를
                            만나실 수 있습니다.
                        </p>
                    </div>

                </div>
            </SCM.MembershipContainer>
        </div>
      )
}

export default MembershipRank