import React from "react";
import * as SCP from "../../style/StyleComponentParallax";
import useDetectScroll from '@smakss/react-scroll-direction';
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

const CoreValueTwo: React.FC = () => {
  const { scrollPosition } = useDetectScroll();
  const containerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const [containerAbsoluteTop, setContainerAbsoluteTop] = useState<number>(0);
  const [animateDisabled, setAnimateDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (!containerRef.current) return;
    setContainerAbsoluteTop(containerRef.current?.getBoundingClientRect().top);
  }, []);

  useEffect(() => {
    if (scrollPosition.top > containerAbsoluteTop) {
      if (animateDisabled) return;
      if (!containerRef.current) return;
      containerRef.current.firstElementChild?.classList.add("animate-center");
      setAnimateDisabled(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollPosition.top]);

  return (
    <>
      <SCP.CoreValueParallaxContainer2
        ref={containerRef}
      >
        <div className="flex-wrapper">
          <div className="circle section-1">
            <div className="core-value-text-box">
              <div className="core-value-eng font-CormorantGaramond">
                <span>Making healthy </span>
                <span>habits for oneself</span>
              </div>
              <div className="core-value-kor">
                <span>나 자신만의 건강한 습관을 </span>
                <span>만드는 피트니스</span>
              </div>
            </div>
          </div>
          <div className="circle section-2">
            <div className="core-value-text-box">
              <div className="core-value-eng font-CormorantGaramond">
                <span>Advanced fitness </span>
                <span>for those with pain</span>
              </div>
              <div className="core-value-kor">
                <span>통증을 가진 분들을 위한 </span>
                <span>진화된 피트니스</span>
              </div>
            </div>
          </div>
          <div className="circle section-3">
            <div className="core-value-text-box">
              <div className="core-value-eng font-CormorantGaramond">
                <span>Fitness </span>
                <span>for all ages</span>
              </div>
              <div className="core-value-kor">
                <span>나이를 구분하지 않는 </span>
                <span>모두를 위한 피트니스</span>
              </div>
            </div>
          </div>
          <div className="circle section-4">
            <div className="core-value-text-box">
              <div className="core-value-eng font-CormorantGaramond">
                <span>Lifetime Fitness </span>
                <span>for oneself</span>
              </div>
              <div className="core-value-kor">
                <span>평생을 지속할 수 있는 </span>
                <span>나만의 피트니스</span>
              </div>
            </div>
          </div>
          {animateDisabled &&
            <div className="doughnut">
              <div>
                <h4 className="font-CormorantGaramond">Perfect Balance</h4>
                <h4 className="font-CormorantGaramond">Between Health and Life</h4>
                <h5>건강과 삶의 완벽한 균형</h5>
              </div>
            </div>
          }
        </div>
      </SCP.CoreValueParallaxContainer2>
    </>
  )
};

export default CoreValueTwo;