import React from "react";
import MainParallax from "../brand/MainParallax";

const Intro: React.FC = () => {

  return (
    <>
      <MainParallax />
    </>
  )
};

export default Intro;