import React from 'react'
import { FaRegCheckCircle } from "react-icons/fa";
import * as SCM from "../../style/StyleComponentMenu";
import { backend_url } from "../../module/DevInformation";

function instructorEffect() {
  return (
    <div>
        <SCM.InstructorEffect>
            <div>
                <div className="text-container">
                    <img src={`${backend_url}/public/image_folder/homepage_images/brand/3ac4e1f3d26af0db42c411bfa2baf149b35a35e256d726e09a36636ffbe977ade14e1dc8ac896dba43772427750751d2f045ebc2b9e555d6b554bd12a45c44c0.png`} alt="insructor" />
                    <div className="text-box">
                        <h2 className='title'>
                            양적인 트레이너 양성과<br />
                            회원을 훈련 시키는 단일적 패러다임에서 벗어나 <br />
                            <br />
                            고객을 <b>근기능적으로 이해 </b>시키고<br />
                            교육하는 <b>RFM Specialist</b>
                        </h2>
                        <div className="line"></div>
                        <div className="text-box--effect">
                            <ul>
                              <li><FaRegCheckCircle /> <b>통증이 수시로 찾아와 괴롭히시는 분</b></li>
                              <li><FaRegCheckCircle /> 여기저기 운동을 다녀봐도 <b>효과를 보지 못하신 분</b></li>
                              <li><FaRegCheckCircle /> <b>내 몸의 기능 상태를 정확하게 파악</b>하고 싶은 분</li>
                            </ul>
                        </div>
                        <h2 className='desc'>
                            <b>RFM</b> 은<br />
                            신체의 불균형과 비효율적인 움직임 패턴을 교정해<br />
                            통증을 완화하고 기능을 회복시켜,<br />
                            일상 생활에서 더 자연스럽고<br />
                            효율적인 움직임을 구현할 수 있도록 돕습니다.
                        </h2>
                    </div>
                </div>
            </div>
        </SCM.InstructorEffect>
    </div>
  )
}

export default instructorEffect