import React from 'react'
import * as SCM from "../../style/StyleComponentMenu";

function Appbanner() {
  return (
    <SCM.AppBannerContainer>
    <div>
        <div className="text-box center">
            <h2>
                솔레노어는 앱을 통해<br/>
                담당 인스트럭터와 편리하게 소통하고<br/>
                <b>‘변화하는 나의 건강한 라이프 스타일’</b>을 <br/>
                언제 어디서든 손쉽게 확인할 수 있습니다.
            </h2>
        </div>
    </div>
    </SCM.AppBannerContainer>
  )
}

export default Appbanner