import React from "react";
import * as SCM from "../../style/StyleComponentMenu";

const point_medal_list = [
  {
    money: 100,
    points: 1,
    inequality: "미만",
    color: "#d8a777",
  },
  {
    money: 100,
    points: 2,
    inequality: "이상",
    color: "#d2866c",
  },
  {
    money: 200,
    points: 3,
    inequality: "이상",
    color: "#cf6860",
  },
  {
    money: 400,
    points: 4,
    inequality: "이상",
    color: "#cc4955",
  },
  {
    money: 600,
    points: 6,
    inequality: "이상",
    color: "#cb2a4a",
  },
];

const Point: React.FC = () => {

  return (
    <>
      <SCM.PointContainer>
        <div className="vertical-flex-box">
          <div className="point-medal-box">
            {point_medal_list.map((tmp, idx) =>
              <div
                className="medal"
                key={idx}
              >
                <div className="flex-wrapper">
                  <div className={`upper-box`}>
                    <div className="sm:flex">
                      <h1>{tmp.money}만원</h1>
                      <h2>{tmp.inequality}</h2>
                    </div>
                  </div>
                  <div className="under-box">
                    <div className="sm:flex">
                      <h3>결제금액</h3>
                      <h4>{tmp.points}% 적립</h4>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="description-text-box">
            <h3>솔레노어 회원이라면 누구나,</h3>
            <h3>솔레노어 프로그램 & 서브 브랜드 결제 시 포인트 즉시 적립 및 사용가능 !</h3>
          </div>
          <div className="notice-box">
            <div className="title">
              유의사항
            </div>
            <div className="notice-list">
              <li>솔레노어 PT 프로그램, 퍼블릭 회원권, 디저트, 샐러드, 어페럴 등 모든 솔레노어 품목 결제에 해당됩니다.</li>
              <li>포인트는 카트 및 현급 결제 시 즉시 적립되며, 결제 취소 시 지급 포인트가 반환됩니다.</li>
              <li>적립된 포인트는 "솔레노어 앱 - 마이페이지"에서 확인 가능합니다.</li>
              <li>회원 탈퇴 시 포인트는 자동 소멸됩니다.</li>
              <li>카드/현금 결제와 포인트 결제를 동시에 사용 가능합니다.</li>
              <li>포인트 결제 시 적립 및 포인트 환불, 세금계산서 (및 명수증)발행 불가합니다.</li>
              <li>포인트 정책은 별도 고지없이 회사의 정책에 따라 변경될 수 있습니다.</li>
            </div>
          </div>
        </div>
      </SCM.PointContainer>
    </>
  )
};

export default Point;