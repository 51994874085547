import React from 'react'
import * as SCM from "../../style/StyleComponentMenu";
import { backend_url } from "../../module/DevInformation";
import google_play_icon from "../../assets/icon_images/google_play.svg";
import apple_icon from "../../assets/icon_images/apple.svg";
import { ScrollAnimationContainer } from "../util/ScrollAnimationContainer";
import { appFunction } from '../../module/Variable';

function AppFunction() {
    const mobileThreshold = window.innerWidth <= 768 ? 0.2 : 0.8;
    const mobileDuration = window.innerWidth <= 768 ? "0.3s" : "0.5s";
    const mobileDelay = window.innerWidth <= 768 ? "0s" : "0.1s";
    return (
        <div>
            <SCM.AppFunctionContainer>
                <div className='container--1'>
                    <img className='mobile' src={`${backend_url}/public/image_folder/homepage_images/brand/2e6263dbc36325cbfc72ac50924951bf521553b71beef9fd413bbfc3bc13f699251f0eb00045be71ddc9a4541b182cd16ce6a1498e37ef4d5b741c3a18e78598.png`} alt='Mobile' />
                    <div className="title-box">
                        <h2>
                            솔레노어 앱,<br/>
                            피트니스의 새로운 방향성
                        </h2>
                        <div className="download-box">
                            <a target="_blanc" href="https://soleilnoir.kr/soleilnoir_app">
                            <img src={google_play_icon} alt="google_play_icon" />
                            </a>
                            <a target="_blanc" href="https://apps.apple.com/kr/app/%EC%86%94%EB%A0%88%EB%85%B8%EC%96%B4/id6446340984">
                                <img src={apple_icon} alt="apple_icon" />
                            </a>
                        </div> {/* download-box */}
                    </div>
                    <div className="desc-box">
                        <p>
                            솔레노어 앱에서는 일정관리는 몰론,<br/>
                            인스트럭터와 당신의 바디 데이터를 공유하고<br/>
                            그에 맞는 식단 솔루션까지 제안합니다.<br/>
                            <br/>
                            지금 당신의 건강 상태를 분석하고,<br/>
                            건강한 라이프 스타일로 바꿔보세요.
                        </p>
                    </div>
                </div>
                <div className="line"></div>
                <div className="container--2">
                    {appFunction.map((value, index) =>
                    <ScrollAnimationContainer
                        direction="L"
                        duration={mobileDuration}
                        delay={mobileDelay}
                        threshold={mobileThreshold}
                        onlyOnce
                    >
                    <div className="img-box" key={index}>
                        <img className='img' src={`${backend_url}/public/image_folder/homepage_images/brand/${value.image}.png`} alt='Mobile' />
                        <div className="text-container">
                            <div className='title-box'>
                                <p className='title'>{value.title1}</p>
                                <p className='title'>{value.title2}</p>
                            </div>
                            <div>
                                <p className='desc'>{value.desc1}</p>
                                <span className='desc'>{value.desc2}</span>
                                <p className='desc br'>{value.desc3}</p>
                                <p className='desc'>{value.desc4}</p>
                                <p className='desc'>{value.desc5}</p>
                                <p className='desc'>{value.desc6}</p>
                            </div>
                        </div>
                    </div>
                    </ScrollAnimationContainer>
                    )}
                </div>
            </SCM.AppFunctionContainer>
        </div>
    )
}

export default AppFunction