import React from "react";
import * as SCM from "../../style/StyleComponentMenu";

const Club: React.FC = () => {
  
  return (
    <>
      <SCM.MenuContainer>
        <SCM.ClubComingSoon>
          COMING SOON
        </SCM.ClubComingSoon>
      </SCM.MenuContainer>
    </>
  )
};

export default Club;