import React from "react";
import * as SCF from "../../style/StyleComponentFranchise";
import { backend_url } from "../../module/DevInformation";

const Difference: React.FC = () => {

  return (
    <div>
      <SCF.DifferenceContainer>
        <div className="text-container">
          <img className="background" src={`${backend_url}/public/image_folder/homepage_images/franchise/5b2cc79b4698c5692512b412baff89ac01e2b6cdf00e25a26061cbd2803289c5b1cabc00a8ff046ec56a50a427c75a0177163f3d959ca71ec4945a237ef8131a.png`} alt="place" />
          <h2 className="title">솔레노어만의 <span>차별화된 전략</span></h2>
          <p className="subTitle">
              <b className="subTitle-b">“High-End + Rehab” Fitness</b><br/>
              하이엔드 부티크 피트니스
          </p>
          <ul>
            <li>
              <p className="list-title">
                <b className="list-title-b">01</b>
                Branding
              </p>
              <p className="list-subTitle">본사 브랜딩 및 지점 마케팅의 <span>핵심 차별화 요소</span></p>
              <p className="list-desc"><b className="list-desc-b">3명의 인스트럭터로 1년간 월 평균 매출 3,000만원 유지</b>하는 마케팅 A to Z</p>
            </li>
            <li>
              <p className="list-title">
                <b className="list-title-b">02</b>
                Instructor
              </p>
              <p className="list-subTitle">훈련이 아닌 지식을 겸비한<span>피트니스 재활 전문가</span></p>
              <p className="list-desc">물리 치료사 자격증 및 전문 지식을 겸비한 <b className="list-desc-b">전문 인스트럭터만을 보유</b>하여 차별화</p>
            </li>
            <li>
              <p className="list-title">
                <b className="list-title-b">03</b>
                IT support
              </p>
              <p className="list-subTitle">솔레노어 앱 내 해당 지점 <span>유료 고객 액세스 오픈</span></p>
              <p className="list-desc">국내 최대 IT 대기업 출신, CTO가 직접 리드하는 <b className="list-desc-b">자체 인하우스 개발 조직</b>이 만든 솔레노어만의 전용앱</p>
            </li>
            <li>
              <p className="list-title">
                <b className="list-title-b">04</b>
                Education
              </p>
              <p className="list-subTitle">오픈 전 가맹점 임직원 <span>기본 교육 필수 이행</span></p>
              <p className="list-desc">모든 가맹점을 체계적으로 관리하여 <b className="list-desc-b">일관된 브랜드 서비스를 제공</b></p>
            </li>
          </ul>
        </div>
      </SCF.DifferenceContainer>
    </div>
  )
};

export default Difference;