import React from "react";
import * as SCF from "../../style/StyleComponentFranchise";
import Difference from "./Difference";
import Model from "./Model";
import IT from "./IT";
import FranchiseInstructor from "./FranchiseInstructor";

interface Props {
  mobileSize: boolean;
  browserWidth: number;
}

const Strength: React.FC<Props> = ({mobileSize, browserWidth}) => {

  return (
    <>
      <SCF.DefaultContainer>
        <Difference />
        <Model />
        <FranchiseInstructor />
        <IT />
      </SCF.DefaultContainer>
    </>
  )
};

export default Strength;